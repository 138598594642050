import { rgba } from 'polished'
import styled from 'styled-components'

export const AnalyticsDropdownBox = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform-origin: calc(100% - 60px) 0px;
  transform: translateX(-50%) scale(0.8);
  max-width: 960px;

  display: flex;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  padding: 20px;
  width: max-content;
  background: ${({ theme }) => theme.dropDownBg};
  border-radius: 12px;
  -webkit-backdrop-filter: blur(150px);
  backdrop-filter: blur(150px);
  visibility: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    top: -17px;
    left: 0;
  }

  @media screen and (max-width: 1510px) {
    transform: translateX(0) scale(0.8);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    right: 0;
    transform: translateX(20%);
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: 10px;
    top: auto;
    left: 11px;
    right: 11px;
    transform: translate(0, 100%);

    width: calc(100% - 22px);
    max-width: 100%;
    padding: 20px;

    background: ${({ theme }) => theme.componentBg4};
    backdrop-filter: blur(30px);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px 20px;
  `}
`

export const AnalyticsDropdownContent = styled.div`
  display: flex;
  gap: 30px;
`

export const AnalyticsDropdownCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    gap: 5px;
  `}
`

export const AnalyticsDropdownTitle = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.dropdownTitle};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export const AnalyticsDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 10px;
  `}
`

export const AnalyticsDropdownListItem = styled.a`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 15px;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText1};

  & > svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.primaryText1};
    transition: color 0.3s;
  }

  &:hover > svg {
    color: ${({ theme }) => theme.secondaryText1};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 15px;
  `}
`

export const AnalyticsDropdownListItemCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const AnalyticsDropdownListItemTitle = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`

export const AnalyticsDropdownListItemText = styled.span`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

export const AnalyticsDropdownRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
`

export const AnalyticsDropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const AnalyticsDropdownImage = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 8px;
`

export const AnalyticsDropdownUpdate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  & > svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.primaryText1};
  }
`

export const AnalyticsDropdownUpdateLink = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-decoration: none;
`
