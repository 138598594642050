import React, { useMemo } from 'react'
import {
  CommMobileDropdownBox,
  CommMobileDropdownCol,
  CommMobileDropdownTitle,
  CommMobileDropdownList,
  CommMobileDropdownListItem,
  CommMobileDropdownListItemCol,
  CommMobileDropdownListItemTitle,
  CommMobileDropdownListItemText,
  ArrowRightStyledIcon,
  CommunityDropdownImage,
  CommunityDropdownItem,
  CommunityDropdownUpdateLink,
  CommunityDropdownUpdateLinkText,
} from './styles'
import { IconWrapper } from 'theme'
import { BriefCaseIcon } from 'icons/BriefCaseIcon'
import { ThumbUpIcon } from 'icons/ThumbUpIcon'
import Row from 'components/Row'
import { ArrowRight } from 'react-feather'
import updateOneImage from 'assets/images/updates/update-1.png'
import updateTwoImage from 'assets/images/updates/update-2.png'

export const CommunityMobileDropdown = () => {
  return (
    <CommMobileDropdownBox>
      <CommMobileDropdownCol>
        <CommMobileDropdownTitle>Community</CommMobileDropdownTitle>
        <CommMobileDropdownList>
          <CommMobileDropdownListItem to={{ pathname: 'https://docs.lif3.com/' }} target="_blank">
            <IconWrapper size="25px">
              <BriefCaseIcon />
            </IconWrapper>
            <CommMobileDropdownListItemCol>
              <CommMobileDropdownListItemTitle>Docs</CommMobileDropdownListItemTitle>
              <CommMobileDropdownListItemText>Read the Lif3 Docs.</CommMobileDropdownListItemText>
            </CommMobileDropdownListItemCol>
            <ArrowRightStyledIcon />
          </CommMobileDropdownListItem>
          <CommMobileDropdownListItem to={{ pathname: 'https://support.lif3.com/' }} target="_blank">
            <IconWrapper size="25px">
              <ThumbUpIcon />
            </IconWrapper>
            <CommMobileDropdownListItemCol>
              <CommMobileDropdownListItemTitle>Lif3 Support</CommMobileDropdownListItemTitle>
              <CommMobileDropdownListItemText>Get Web and App support.</CommMobileDropdownListItemText>
            </CommMobileDropdownListItemCol>
            <ArrowRightStyledIcon />
          </CommMobileDropdownListItem>
          <CommMobileDropdownListItem to={{ pathname: 'https://support.lif3.com/' }} target="_blank">
            <IconWrapper size="25px">
              <BriefCaseIcon />
            </IconWrapper>
            <CommMobileDropdownListItemCol>
              <CommMobileDropdownListItemTitle>Feature Request</CommMobileDropdownListItemTitle>
              <CommMobileDropdownListItemText>Let us know what you would like to see.</CommMobileDropdownListItemText>
            </CommMobileDropdownListItemCol>
            <ArrowRightStyledIcon />
          </CommMobileDropdownListItem>
          <CommMobileDropdownListItem to={{ pathname: 'https://support.lif3.com/' }} target="_blank">
            <IconWrapper size="25px">
              <ThumbUpIcon />
            </IconWrapper>
            <CommMobileDropdownListItemCol>
              <CommMobileDropdownListItemTitle>Bug Report</CommMobileDropdownListItemTitle>
              <CommMobileDropdownListItemText>Report any bugs you find.</CommMobileDropdownListItemText>
            </CommMobileDropdownListItemCol>
            <ArrowRightStyledIcon />
          </CommMobileDropdownListItem>
        </CommMobileDropdownList>

        <Row gap="20px" width="100%" align="center">
          <CommunityDropdownItem>
            <CommunityDropdownUpdateLink to={{ pathname: '/news' }}>
              <CommunityDropdownImage src={updateOneImage} alt="Lif3 Update" />
              <CommunityDropdownUpdateLinkText>
                Read Weekly Updates
                <ArrowRight size="16px" />
              </CommunityDropdownUpdateLinkText>
            </CommunityDropdownUpdateLink>
          </CommunityDropdownItem>
          <CommunityDropdownItem>
            <CommunityDropdownUpdateLink to={{ pathname: '/comics' }}>
              <CommunityDropdownImage src={updateTwoImage} alt="Lif3 Update" />
              <CommunityDropdownUpdateLinkText>
                View Weekly Comic
                <ArrowRight size="16px" />
              </CommunityDropdownUpdateLinkText>
            </CommunityDropdownUpdateLink>
          </CommunityDropdownItem>
        </Row>
      </CommMobileDropdownCol>
    </CommMobileDropdownBox>
  )
}
