import React, { useMemo, useState } from 'react'
import { Text } from 'rebass'
import { useMediaWith } from 'theme'
import { SwapType } from 'state/swap/reducer'
import { SwapTab, SwapTabsBox, SwapTabsWrapper, SwapMarketBox, WarningBox, MarketButton } from './styles'

import { useLocation, useHistory } from 'react-router'
import { useChainId } from 'hooks'
import { ChainId } from 'sdk'
import { swapTokensList } from 'constants/swapTokensList'

type Props = {
  isTradeSwap?: boolean
}

const MARKET_SWAP_DISABLED = [
  /*ChainId.ETHEREUM, ChainId.FANTOM, ChainId.POLYGON*/
]

export const SwapTabs: React.FC<Props> = ({ isTradeSwap }) => {
  const chainId = useChainId()
  const location = useLocation()
  const history = useHistory()
  const { upToSmall } = useMediaWith()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const handleAggregatorSelect = () => {
    searchParams.set('swapType', 'Aggregator')

    history.replace({ search: searchParams.toString() })
  }

  // const handleCrossChainSelect = () => {
  //   searchParams.set('swapType', 'CrossChain')

  //   history.replace({ search: searchParams.toString() })
  // }

  const onSelect = (type: SwapType) => {
    searchParams.set('swapType', type!)
    if (type === 'Aggregator') {
      const token = swapTokensList[chainId].find((el) => el.symbol === 'LIF3')
      if (token && token.address) {
        searchParams.set('outputCurrency', token.address)
      }
    }
    history.replace({ search: searchParams.toString() })
  }

  const swapType = (searchParams.get('swapType') as SwapType) ?? 'Market'

  if (chainId === ChainId.LIF3CHAIN_TESTNET) {
    return (
      <SwapMarketBox>
        <Text fontSize={upToSmall ? '18px' : '22px'} lineHeight="28px" fontWeight={700} paddingY={10}>
          Swap
        </Text>
      </SwapMarketBox>
    )
  }

  return (
    <>
      <SwapTabsBox tokenStyle={isTradeSwap}>
        <SwapTabsWrapper wrapperStyle={isTradeSwap}>
          <SwapTab isActive={swapType === 'Aggregator'} onClick={() => onSelect('Aggregator')}>
            Swap
          </SwapTab>
          <SwapTab isActive={swapType === 'Market'} onClick={() => onSelect('Market')}>
            Market
          </SwapTab>
          {/* {chainId !== ChainId.ETHEREUM && (
            <SwapTab isActive={tab === 'Market'} onClick={() => setTab('Market')}>
              Market
            </SwapTab>
          )}
          {chainId !== ChainId.ETHEREUM && (
            <SwapTab isActive={tab === 'Limit'} onClick={() => setTab('Limit')}>
              Limit
            </SwapTab>
          )}
          <SwapTab isActive={tab === 'Market'} onClick={() => setTab('Market')}>
            Market
          </SwapTab>
          <SwapTab isActive={tab === 'Limit'} onClick={() => setTab('Limit')}>
            Limit
          </SwapTab>
          <SwapTab isActive={swapType === 'CrossChain'} onClick={() => onSelect('CrossChain')}>
            Cross-Chain
          </SwapTab> */}
        </SwapTabsWrapper>
      </SwapTabsBox>
    </>
  )
}
