import { useMemo } from 'react'
import { useMedia } from 'react-use'
import { css } from 'styled-components'
import { breakpoints } from '../constants/breakpoints'
import { Theme } from '../types'
import { useThemeGeneral } from './useThemeGeneral'

const isDarkTheme = (theme: Theme) => theme === 'dark'

export const useMediaWith = () => {
  return {
    smDown: useMedia(`(max-width: ${breakpoints.smDown}px)`),
    mdDown: useMedia(`(max-width: ${breakpoints.mdDown}px)`),
    lgDown: useMedia(`(max-width: ${breakpoints.lgDown}px)`),
    xlDown: useMedia(`(max-width: ${breakpoints.xlDown}px)`),
  }
}

const mediaWidthTemplates: { [width in keyof typeof breakpoints]: typeof css } = Object.keys(breakpoints).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(breakpoints as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const makeGradients = (theme: Theme) => {
  const isDark = isDarkTheme(theme)

  return {
    sidebarMenuItemGradient: isDark
      ? 'linear-gradient(88.3deg,#252525,rgba(37,37,37,0) 100% ), #2f2f2f'
      : 'linear-gradient(88.3deg,#F1F1F1,#0000AE 100%),#2f2f2f',
  }
}

const makeShadows = (theme: Theme) => {
  const isDark = isDarkTheme(theme)

  return {
    shadow1: isDark
      ? '0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945), 0px 4px 6.5px rgba(0, 0, 0, 0.07)'
      : '0px 10.125px 17.4375px rgba(159, 151, 151, 0.0945), 0px 0.875px 2.3125px rgba(159, 151, 151, 0.0455)',
    sectionShadow1: isDark
      ? '0px 100px 80px rgba(0, 0, 0, 0.03), 0px 50.625px 34.875px rgba(0, 0, 0, 0.02025), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 4.375px 4.625px rgba(0, 0, 0, 0.00975)'
      : '0px 100px 80px rgba(159, 151, 151, 0.03), 0px 50.625px 34.875px rgba(159, 151, 151, 0.02025), 0px 20px 13px rgba(159, 151, 151, 0.015), 0px 4.375px 4.625px rgba(159, 151, 151, 0.00975)',
    sectionShadow2: isDark
      ? '0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945), 0px 4px 6.5px rgba(0, 0, 0, 0.07), 0px 0.875px 2.3125px rgba(0, 0, 0, 0.0455)'
      : '0px 38px 94px rgba(0, 0, 0, 0.09), 0px 19.2375px 40.9781px rgba(0, 0, 0, 0.06075), 0px 7.6px 15.275px rgba(0, 0, 0, 0.045), 0px 1.6625px 5.43437px rgba(0, 0, 0, 0.02925)',
  }
}

const makeColors = (theme: Theme) => {
  const isDark = isDarkTheme(theme)

  return {
    primary: isDark ? '#111111' : '#ffffff',
    transparent: isDark ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
    accent: '#0000AE',
    accentDark: '#3C886D',
    accentDisabled: 'rgba(127, 208, 179, 0.3)',
    accentVeryDark: '#2E6954',
    iconColor: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(17, 17, 17, 0.3)',
    border: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(17, 17, 17, 0.1)',
    borderActive: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(17, 17, 17, 0.1)',
    danger: '#E2726B',
    contrastText: isDark ? '#ffffff' : '#000000',
    disabledText: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
    disabled: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(17, 17, 17, 0.3)',
    textOverflowFadeBg: isDark
      ? 'linear-gradient(270deg, #111111 7.69%, rgba(17, 17, 17, 0) 134.52%)'
      : 'linear-gradient(270deg, #FFFFFF 7.69%, rgba(255, 255, 255, 0) 134.52%)',
    componentBg: isDark ? '#1E1E1E' : '#fff',
    componentContrastBg: isDark ? '#2F2F2F' : '#fff',
    componentContrastBg2: isDark ? '#2F2F2F' : 'rgba(0, 0, 0, 0.1)',
    sectionsBoxShadow: isDark
      ? '0px 20px 40px rgba(0, 0, 0, 0.14), 0px 10.125px 17.4375px rgba(0, 0, 0, 0.0945), 0px 4px 6.5px rgba(0, 0, 0, 0.07), 0px 0.875px 2.3125px rgba(0, 0, 0, 0.0455)'
      : '0px 100px 80px rgba(159, 151, 151, 0.03), 0px 50.625px 34.875px rgba(159, 151, 151, 0.02025), 0px 20px 13px rgba(159, 151, 151, 0.015), 0px 4.375px 4.625px rgba(159, 151, 151, 0.00975)',
    componentBgLight: isDark ? '#252525' : '#FFF',
    scrollbarTrackColor: isDark ? '#3C3C3C' : '#EBEBEB',
    scrollbarThumbColor: isDark ? '#828282' : '#BBBBBB',
    rowBg: isDark ? '#262626' : 'red',
    appBg: isDark ? '#111111' : '#F1F1F1',

    red1: '#E2726B',
    red2: '#F82D3A',
    red3: '#D60000',

    bg1: isDark ? '#1E1E1E' : '#FFFFFF',
    bg2: isDark ? '#2F2F2F' : '#FFFFFF',
    bg3: isDark ? '#262626' : '#FFFFFF',
    bg4: isDark ? '#3a506f' : '#CED0D9',
    bg5: isDark ? '#6C7284' : '#888D9B',
    inputBg: isDark ? '#262626' : '#FFFFFF',
    buttonBg1: isDark ? '#3B3B3B' : '#CED0D9',
    buttonBg1Hover: isDark ? '#262626' : '#888D9B',
    buttonBg2: isDark ? '#1E1E1E' : '#CED0D9',
    buttonBg2Hover: isDark ? '#1E1E1E' : '#CED0D9',

    // color text
    primaryText1: isDark ? '#FFFFFF' : '#000000',
    secondaryText1: isDark ? '#4E4E4E' : 'rgba(17, 17, 17, 0.3)',
    secondaryText2: isDark ? 'rgba(255, 255, 255, 0.6)' : 'rgba(17, 17, 17, 0.6)',
    secondaryText3: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(17, 17, 17, 0.3)',

    modalOverlayBg: isDark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.4)',
    selectBg: isDark ? '#363636' : '#fff',
    selectOptionHover: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(127, 208, 179, 0.15)',
    selectBoxShadow: isDark
      ? '-2px -5px 20px rgba(0, 0, 0, 0.25), 2px 5px 10px rgba(0, 0, 0, 0.25)'
      : '0px -0.88px 2.3125px rgba(159, 151, 151, 0.0455), 0px 4px 6.5px rgba(159, 151, 151, 0.07), 0px 10.125px 17.4375px rgba(159, 151, 151, 0.0945), 0px 20px 40px rgba(159, 151, 151, 0.14)',
  }
}

const makeZooTheme = (theme: Theme) => {
  const isDark = isDarkTheme(theme)

  return {
    colors: makeColors(theme),
    shadows: makeShadows(theme),
    gradients: makeGradients(theme),
    mediaWidth: mediaWidthTemplates,
    isDark,
  }
}

export const useTheme = () => {
  const [theme] = useThemeGeneral()
  const themeObject = useMemo(() => makeZooTheme(theme), [theme])

  return themeObject
}
