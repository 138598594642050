import React, { useContext, useCallback } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { ExternalLink, TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { getExplorerLink } from '../../utils'
import { isAddress } from '@ethersproject/address'
import { useChainId } from 'hooks'

const InputPanel = styled.div<{ error?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.componentBg2};
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.05);
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
  width: 100%;
  border: 1px solid ${({ error, theme }) => (error ? theme.red1 : 'rgba(255, 255, 255, 0)')};
`

const InputContainer = styled.div`
  flex: 1;
`

const Input = styled.input<{ error?: boolean }>`
  font-size: 18px;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: transparent;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : theme.primaryText1)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.secondaryText1};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export default function AddressInputPanel({
  id,
  value,
  onChange,
}: {
  id?: string
  // the typed string value
  value: string
  // triggers whenever the typed value changes
  onChange: (value: string) => void
}) {
  const theme = useContext(ThemeContext)

  const isError = value.length > 0 && !isAddress(value)

  const handleInput = useCallback(
    (event) => {
      const input = event.target.value
      const withoutSpaces = input.replace(/\s+/g, '')
      onChange(withoutSpaces)
    },
    [onChange]
  )

  const chainId = useChainId()

  // const error = Boolean(value.length > 0 && !loading && !address)

  return (
    <InputPanel error={isError}>
      <InputContainer>
        <AutoColumn gap="md">
          <RowBetween>
            <TYPE.black color={theme.secondaryText1} fontSize={13}>
              Recipient
            </TYPE.black>
            {!isError && value.length > 0 && (
              <ExternalLink href={getExplorerLink(value, 'address', chainId)} style={{ fontSize: '13px' }}>
                (View on explorer)
              </ExternalLink>
            )}
          </RowBetween>
          <Input
            className="recipient-address-input"
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Wallet Address"
            error={isError}
            pattern="^(0x[a-fA-F0-9]{40})$"
            onChange={handleInput}
            value={value}
          />
        </AutoColumn>
      </InputContainer>
    </InputPanel>
  )
}
