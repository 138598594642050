import styled from 'styled-components'

export const DesktopLayout = styled.div<{ isOrdersLayout?: boolean; isTokenLayout?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 290px 410px;
  grid-auto-rows: auto;
  grid-template-rows: ${({ isOrdersLayout }) => (isOrdersLayout ? '1fr auto auto' : '1fr 1fr')};
  /* grid-template-areas: ${({ isOrdersLayout }) =>
    isOrdersLayout
      ? `'chart chart trade'
      'chart chart orders'
      'chart chart info'`
      : `'chart chart trade'
    'chart chart info'`}; */

  grid-template-areas: ${({ isTokenLayout }) =>
    isTokenLayout
      ? `'chart chart trade'
      'token-info token-info trade'`
      : `'chart chart trade'
    'chart chart trade'`};
  gap: 10px;
  align-items: flex-start;
  place-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
      grid-template-columns: minmax(305px, 1fr) 260px 400px;
  `};
`

export const MobileMenuWrapper = styled.div`
  margin-bottom: 10px;
`

export const MobileLayout = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const MobileScreens = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`

export const MobileScreenWrapper = styled.div<{ active: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;

  ${({ active }) => (!active ? 'display: none;' : '')}
`

export const MobileBackdrop = styled.div`
  position: fixed;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  background: ${({ theme }) => theme.mobileBackdropBg};
  filter: ${({ theme }) => theme.mobileBackdropFilter};
`

export const MobileScreenBox = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme.componentBg1};
  overflow: hidden;
  height: 500px;
`

export const SectionWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background: ${({ theme }) => theme.componentBg1};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  position: relative;
  display: flex;
  flex-direction: column;
`
export const TablesBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: tables;

  height: calc(100vh - 120px);

  @media screen and (max-height: 1220px) {
    height: 100%;
  }
`

export const TablesCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`

export const TablesWrapper = styled(SectionWrapper)`
  flex-basis: 50%;
`

export const MergedWrapper = styled(SectionWrapper)`
  height: 100%;
  max-height: 100%;
`

export const ChartsWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  min-height: 0px;
  grid-area: chart;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const TradeBox = styled.div`
  grid-area: trade;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  overflow-y: auto;
`

export const TradeTokenBox = styled.div<{ isTokenLayout?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
  border-radius: 10px;
  background: ${({ theme, isTokenLayout }) => (isTokenLayout ? 'transparent' : theme.componentBg4)};
`

export const TokenInfoBox = styled.div`
  grid-area: token-info;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
`

export const InfoBox = styled.div`
  grid-area: info;
  position: relative;
  border-radius: 10px;
  background: ${({ theme }) => theme.componentBg1};
  backdrop-filter: blur(15px);
`
export const OrderInfoWrapper = styled.div`
  width: 100%;
  grid-area: orders;
`
