import { ChainId, SUPPORTED_NETWORKS } from 'sdk/constants'

/**
 * Prompt the user to add FTM as a network on Metamask, or switch to FTM if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId: ChainId) => {
  const provider = window.ethereum as any

  if (provider) {
    try {
      if (chainId === ChainId.ETHEREUM) {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: `0x${ChainId.ETHEREUM.toString(16)}`,
            },
          ],
        })
        return true
      } else {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [SUPPORTED_NETWORKS[chainId]],
        })
        return true
      }
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup network on metamask because window.ethereum is undefined")
    return false
  }
}
