import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useCurrencyToRouteParams, useSelectedCurrencies, useSwapActionHandlers } from 'state/swap/hooks'
import useSavedPair from 'hooks/useSavedPair'
import { CurrencyDirection } from 'enums/common'

import { PriceHelmet } from './PriceHelmet'

import { TradeLayouts } from './TradeLayouts'
import { useDeepCompareEffect } from 'react-use'
import { useChainId } from 'hooks'
import { Redirect } from 'react-router-dom'
import { ChainId } from 'sdk'
import { SUPPORTED_NETWORKS_IDS } from 'connectors'

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  flex-grow: 1;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-right: 10px;
    padding-left: 10px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `};
`

export const TradePage = () => {
  const chainId = useChainId()

  if (!SUPPORTED_NETWORKS_IDS.includes(chainId) || chainId === ChainId.AMOY_TESTNET) return <Redirect to="/" />

  return (
    <BodyWrapper>
      <PriceHelmet />
      <TradeLayouts />
    </BodyWrapper>
  )
}

export default TradePage
