import { useCallback, useEffect, useState } from 'react'
import { useV3NFTPositionManagerContract, useV3StakingRewardsContract } from 'hooks/useContract'
import { useActiveWeb3React } from 'shared'
import { useStakingIncentivesQuery } from '../queries/v3/useStakingIncentivesQuery'
import { waitAndFinalizeTransaction } from 'utils/waitAndFinalizeTransaction'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useChainId } from 'hooks'
import { useAddPopup } from 'state/application/hooks'
import { useStakingPositionByTokenIdQuery } from 'hooks/queries/v3/useStakingPositionByTokenIdQuery'
import { MappedIncentive, PositionRaw } from 'types/v3'
import { getMappedIncentive } from 'utils/v3/getMappedIncentive'

export const useStakingMethods = (tokenId: number, poolId: string) => {
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const chainId = useChainId()
  const addPopup = useAddPopup()

  const nftManagerPositionsContract = useV3NFTPositionManagerContract()
  const stakingRewardsContract = useV3StakingRewardsContract()
  const { data: positionByTokenIdData } = useStakingPositionByTokenIdQuery(tokenId)
  const { data: incentivesData } = useStakingIncentivesQuery('all')
  const { data: activeIncentivesData } = useStakingIncentivesQuery('active')
  const [isLoading, setIsLoading] = useState(false)
  const [position, setPosition] = useState<PositionRaw | null>(null)
  const [incentive, setIncentive] = useState<MappedIncentive>()

  useEffect(() => {
    if (positionByTokenIdData) {
      setPosition(positionByTokenIdData?.positions[0])
    }
  }, [positionByTokenIdData])

  useEffect(() => {
    if (incentivesData && position) {
      const isStaked = position?.staked

      if (isStaked) {
        // if staked pick that one was used for
        const usedIncentiveId = position?.incentiveId
        const currentIncentive = incentivesData?.incentives.find((incentive) => incentive.id === usedIncentiveId)

        setIncentive(getMappedIncentive(currentIncentive))

        return
      }
      const poolIncentive = activeIncentivesData?.incentives.find(
        (incentive) => incentive.pool === poolId.toLowerCase()
      )
      if (!poolIncentive) return

      setIncentive(getMappedIncentive(poolIncentive))
    }
  }, [incentivesData, position])

  const addFailedTxPopup = (e) => {
    addPopup(
      {
        notification: {
          success: false,
          text: e?.message,
        },
      },
      ''
    )
  }

  const approve = useCallback(
    async (next: () => void) => {
      if (!(nftManagerPositionsContract && stakingRewardsContract && incentive)) return

      try {
        setIsLoading(true)
        const tx = await nftManagerPositionsContract.approve(stakingRewardsContract.address, tokenId)

        addTransaction(tx, {
          summary: 'Approve',
        })

        await waitAndFinalizeTransaction(tx.hash, chainId)

        next()
      } catch (e) {
        console.warn(e)
        addFailedTxPopup(e)
      } finally {
        setIsLoading(false)
      }
    },
    [tokenId, incentive, stakingRewardsContract, nftManagerPositionsContract]
  )

  const transfer = useCallback(
    async (next: () => void) => {
      if (!(account && nftManagerPositionsContract && stakingRewardsContract && incentive)) return

      try {
        setIsLoading(true)
        const tx = await nftManagerPositionsContract['safeTransferFrom(address,address,uint256)'](
          account,
          stakingRewardsContract.address,
          tokenId
        ) // https://stackoverflow.com/questions/68289806/no-safetransferfrom-function-in-ethers-js-contract-instance

        addTransaction(tx, {
          summary: 'Transfer',
        })

        await waitAndFinalizeTransaction(tx.hash, chainId)

        next()
      } catch (e) {
        console.warn(e)
        addFailedTxPopup(e)
      } finally {
        setIsLoading(false)
      }
    },
    [tokenId, incentive, stakingRewardsContract, nftManagerPositionsContract, account]
  )

  const stake = useCallback(
    async (next: () => void) => {
      if (!(stakingRewardsContract && incentive)) return
      console.log(incentive)
      try {
        setIsLoading(true)
        const tx = await stakingRewardsContract.stakeToken(incentive.key, tokenId)

        addTransaction(tx, {
          summary: 'Stake',
        })

        await waitAndFinalizeTransaction(tx.hash, chainId)

        next()
      } catch (e) {
        console.warn(e)
        addFailedTxPopup(e)
      } finally {
        setIsLoading(false)
      }
    },
    [tokenId, incentive, stakingRewardsContract]
  )

  const unstake = useCallback(
    async (next: () => void) => {
      if (!(stakingRewardsContract && incentive)) return

      try {
        setIsLoading(true)
        const tx = await stakingRewardsContract.unstakeToken(incentive.key, tokenId)

        addTransaction(tx, {
          summary: 'Unstake',
        })

        await waitAndFinalizeTransaction(tx.hash, chainId)

        next()
      } catch (e) {
        console.warn(e)
        addFailedTxPopup(e)
      } finally {
        setIsLoading(false)
      }
    },
    [tokenId, incentive, stakingRewardsContract]
  )

  const claim = useCallback(
    async (next: () => void) => {
      if (!(stakingRewardsContract && incentive && account)) return
      try {
        setIsLoading(true)
        const userRewardInfo = await stakingRewardsContract?.getRewardInfo(incentive.key, tokenId)
        const reward = userRewardInfo?.reward.toString()

        const tx = await stakingRewardsContract.claimReward(incentive.key.rewardToken, account, reward)

        addTransaction(tx, {
          summary: 'Claim',
        })

        await waitAndFinalizeTransaction(tx.hash, chainId)

        next()
      } catch (e) {
        console.warn(e)
        addFailedTxPopup(e)
      } finally {
        setIsLoading(false)
      }
    },
    [incentive, account, stakingRewardsContract]
  )

  const withdraw = useCallback(
    async (next: () => void) => {
      if (!(stakingRewardsContract && account)) return

      try {
        setIsLoading(true)
        const tx = await stakingRewardsContract.withdrawToken(tokenId, account, [])

        addTransaction(tx, {
          summary: 'Withdraw',
        })

        await waitAndFinalizeTransaction(tx.hash, chainId)

        next()
      } catch (e) {
        console.warn(e)
        addFailedTxPopup(e)
      } finally {
        setIsLoading(false)
      }
    },
    [tokenId, account, stakingRewardsContract]
  )

  return {
    isLoading,
    approve,
    transfer,
    stake,
    unstake,
    claim,
    withdraw,
  }
}
