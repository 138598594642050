import { useChainId } from 'hooks'
import { useCGAssetPlatformsList } from './useCGAssetPlatformsList'
import { useQuery } from 'react-query'
import { useListedTokens } from 'hooks/Tokens'
import { SwapType } from 'state/swap/reducer'
import { useApi } from 'shared'
import { ChainId } from 'sdk'

export const useCGListedTokenPricesByNetwork = (swapType: SwapType) => {
  const api = useApi()
  const chainId = useChainId()
  const assetPlatforms = useCGAssetPlatformsList()
  const tokens = useListedTokens(swapType)
  const currentChainAssetPlatformId = assetPlatforms?.data?.find((asset) => asset.chain_identifier === chainId)?.id

  const platformId = chainId === ChainId.LIF3CHAIN_TESTNET ? 'fantom' : currentChainAssetPlatformId

  const data = useQuery(
    ['cg', 'use-listed-tokens-prices', chainId, swapType],
    async () => {
      const response = await api?.coingeckoApi.get<Record<string, { usd: number }>>(
        `/simple/token_price/${platformId}?contract_addresses=${Object.keys(tokens)}&vs_currencies=usd`
      )

      return response?.data
    },
    { enabled: Boolean(chainId) && Boolean(platformId) }
  )

  if (!assetPlatforms || !assetPlatforms.data) {
    return []
  }

  return data
}
