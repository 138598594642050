import { BigNumber } from '@ethersproject/bignumber'
import { useQuery } from 'react-query'
import { useMemo } from 'react'

import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { multicallFailSafe } from 'shared'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from 'constants/v3/addresses'
import { useChainId } from 'hooks'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { useStakingPositionsByOwnerQuery } from 'hooks/queries/v3/useStakingPositionsByOwnerQuery'

const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson

function useV3PositionsFromTokenIds(tokenIds) {
  const chainId = useChainId()
  const inputs = useMemo(
    () =>
      tokenIds
        ? tokenIds.map((tokenId) => {
            return [BigNumber.from(tokenId)]
          })
        : [],
    [tokenIds]
  )
  const positionsQuery = useQuery(
    ['manual-positions', [...tokenIds].sort()],
    async () => {
      const calls = tokenIds.map((tokenId) => {
        return {
          address: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
          name: 'positions',
          params: [BigNumber.from(tokenId)],
        }
      })

      const response = await multicallFailSafe(NFTPositionManagerABI, calls, PROVIDERS_BY_CHAIN[chainId], chainId)

      return response
    },
    { enabled: tokenIds.length > 0 }
  )

  const loading = positionsQuery.isLoading
  const error = positionsQuery.isError

  const results = positionsQuery.data ?? []

  let positions = null
  if (!loading && !error && tokenIds) {
    positions = results.map((result, i) => {
      const tokenId = tokenIds[i]

      const [
        nonce,
        operator,
        token0,
        token1,
        fee,
        tickLower,
        tickUpper,
        liquidity,
        feeGrowthInside0LastX128,
        feeGrowthInside1LastX128,
        tokensOwed0,
        tokensOwed1,
      ] = result

      return {
        tokenId,
        fee: fee,
        feeGrowthInside0LastX128: feeGrowthInside0LastX128,
        feeGrowthInside1LastX128: feeGrowthInside1LastX128,
        liquidity: liquidity,
        nonce: nonce,
        operator: operator,
        tickLower: tickLower,
        tickUpper: tickUpper,
        token0: token0,
        token1: token1,
        tokensOwed0: tokensOwed0,
        tokensOwed1: tokensOwed1,
      }
    })
  }

  return {
    loading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}

export function useV3PositionFromTokenId(tokenId) {
  const position = useV3PositionsFromTokenIds(tokenId ? [tokenId] : [])
  return {
    loading: position.loading,
    position: position.positions?.[0],
  }
}

export function useV3ManualPositions(account) {
  const chainId = useChainId()
  const positionManager = useV3NFTPositionManagerContract()

  const balanceQuery = useQuery(
    ['position-balance', account],
    async () => {
      const response = await positionManager?.balanceOf(account)

      return response
    },
    { enabled: Boolean(account) }
  )

  const balanceResult = balanceQuery.data
  const balanceLoading = balanceQuery.isLoading

  // const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(positionManager, 'balanceOf', [account ?? undefined])

  // we don't expect any account balance to ever exceed the bounds of max safe int
  const accountBalance = balanceResult?.toNumber()

  const tokenIdsArgs = useMemo(() => {
    if (accountBalance && account) {
      const tokenRequests = []
      for (let i = 0; i < accountBalance; i++) {
        tokenRequests.push([account, i])
      }
      return tokenRequests
    }
    return []
  }, [account, accountBalance])

  const tokenIdResultsQuery = useQuery(
    ['token-ids', account, [...tokenIdsArgs].sort()],
    async () => {
      const calls = Array.from(Array(accountBalance)).map((_, i) => {
        return {
          address: NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId],
          name: 'tokenOfOwnerByIndex',
          params: [account, i],
        }
      })

      const response = await multicallFailSafe(NFTPositionManagerABI, calls, PROVIDERS_BY_CHAIN[chainId], chainId)

      return response ?? []
    },
    { enabled: accountBalance !== undefined }
  )

  const tokenIdResults = tokenIdResultsQuery.data ?? []

  // const tokenIdResults = useSingleContractMultipleData(positionManager, 'tokenOfOwnerByIndex', tokenIdsArgs)
  const someTokenIdsLoading = tokenIdResultsQuery.isLoading

  const tokenIds = useMemo(() => {
    if (account) {
      return tokenIdResults.map((result) => BigNumber.from(result[0]))
    }

    return []
  }, [account, tokenIdResults, chainId])

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

  return {
    loading: someTokenIdsLoading || balanceLoading || positionsLoading,
    positions,
  }
}

export function useV3ManualPositionsViaSubgraph() {
  // function above don't show transfered nfts, take nft ids ftom subgraph instead

  const { isLoading, data } = useStakingPositionsByOwnerQuery()
  const tokenIds = data?.positions.map((item) => item.tokenId) ?? []

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

  return {
    loading: isLoading || positionsLoading,
    positions,
  }
}
