import { useMemo } from 'react'
import { ChainId } from 'sdk'
import { Currency, Token } from '@uniswap/sdk-core'
import { FeeAmount, Pool } from '@uniswap/v3-sdk'
import { useChainId } from 'hooks'
import { PoolState, usePools } from './usePools'
import { useAllCurrencyCombinations } from './useAllCurrencyCombinations'

/**
 * Returns all the existing pools that should be considered for swapping between an input currency and an output currency
 * @param currencyIn the input currency
 * @param currencyOut the output currency
 */
export function useV3SwapPools(
  currencyIn?: Currency,
  currencyOut?: Currency
): {
  pools: Pool[]
  loading: boolean
} {
  const chainId = useChainId()

  const allCurrencyCombinations = useAllCurrencyCombinations(currencyIn, currencyOut)

  const filterCurrencyCombinations = (allCurrencyCombinations, currencyIn, currencyOut) => {
    return allCurrencyCombinations.filter(
      ([tokenA, tokenB]) =>
        (tokenA.symbol === currencyIn.wrapped.symbol && tokenB.symbol === currencyOut.wrapped.symbol) ||
        (tokenA.symbol === currencyOut.wrapped.symbol && tokenB.symbol === currencyIn.wrapped.symbol)
    )
  }

  const filteredCombinationsWithFees: [Token, Token, FeeAmount][] = useMemo(() => {
    const filteredCombinations = filterCurrencyCombinations(allCurrencyCombinations, currencyIn, currencyOut)

    return filteredCombinations.reduce((list, [tokenA, tokenB]) => {
      return chainId === ChainId.ETHEREUM
        ? list.concat([
            [tokenA, tokenB, FeeAmount.LOW],
            [tokenA, tokenB, FeeAmount.MEDIUM],
            [tokenA, tokenB, FeeAmount.HIGH],
          ])
        : list.concat([
            [tokenA, tokenB, FeeAmount.LOWEST],
            [tokenA, tokenB, FeeAmount.LOW],
            [tokenA, tokenB, FeeAmount.MEDIUM],
            [tokenA, tokenB, FeeAmount.HIGH],
          ])
    }, [])
  }, [allCurrencyCombinations, chainId, currencyIn, currencyOut])

  const pools = usePools(filteredCombinationsWithFees)

  return useMemo(() => {
    return {
      pools: pools
        .filter((tuple): tuple is [PoolState.EXISTS, Pool] => {
          return tuple[0] === PoolState.EXISTS && tuple[1] !== null
        })
        .map(([, pool]) => pool),
      loading: pools.some(([state]) => state === PoolState.LOADING),
    }
  }, [pools])
}
