import { ChainId, JSBI, Percent, Token, WNATIVE } from 'sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import MetamaskIcon from 'assets/images/metamask.png'
import CoinBaseIcon from 'assets/images/coinbaseWalletIcon.svg'
import WalletConnectIcon from 'assets/images/walletConnectIcon.svg'
import Lif3Icon from 'assets/images/logos/lif-logo.svg'

import { injected, walletconnect } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const DEFAULT_TOKENS = {
  [ChainId.FANTOM]: {
    USDC: new Token({
      address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    WBTC: new Token({
      address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      decimals: 8,
      symbol: 'WBTC',
      name: 'Wrapped Bitcoin',
    }),
    DAI: new Token({
      address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
      decimals: 18,
      symbol: 'DAI',
      name: 'Dai Stablecoin',
      logo: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    }),
    TOMB: new Token({
      address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
      name: 'TOMB',
      symbol: 'TOMB',
      decimals: 18,
    }),
    TBOND: new Token({
      address: '0x24248CD1747348bDC971a5395f4b3cd7feE94ea0',
      name: 'TBOND',
      symbol: 'TBOND',
      decimals: 18,
    }),
    FUSDT: new Token({
      address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      decimals: 6,
      name: 'FUSDT',
      symbol: 'FUSDT',
      logo: 'https://assets.lif3.com/swap/tokens/FUSDT.svg',
    }),
    L3USD: new Token({
      address: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
      decimals: 18,
      name: 'L3USD',
      symbol: 'L3USD',
      logo: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    }),
    LIF3: new Token({
      address: '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
      decimals: 18,
      name: 'LIF3',
      symbol: 'LIF3',
      logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    }),
    LSHARE: new Token({
      address: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
      decimals: 18,
      name: 'LSHARE',
      symbol: 'LSHARE',
      logo: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    }),
  },
  [ChainId.BSC]: {
    USDC: new Token({
      address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    DAI: new Token({
      address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      decimals: 18,
      symbol: 'DAI',
      name: 'Dai Stablecoin',
      logo: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    }),
    WBNB: new Token({
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      decimals: 18,
      symbol: 'WBNB',
      name: 'WBNB',
      logo: 'https://assets.lif3.com/swap/tokens/WBNB.svg',
    }),
  },
  [ChainId.POLYGON]: {
    USDC: new Token({
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    DAI: new Token({
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      name: 'DAI',
      symbol: 'DAI',
      decimals: 18,
      logo: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    }),

    USDT: new Token({
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logo: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    }),
    WMATIC: new Token({
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18,
      symbol: 'WMATIC',
      name: 'Wrapped Matic',
      logo: 'https://assets.lif3.com/swap/tokens/WMATIC.svg',
    }),
    LSHARE: new Token({
      address: '0x5eAB32Fe1D104cE0C5436feDC3433B646096e47C',
      decimals: 18,
      name: 'LSHARE',
      symbol: 'LSHARE',
      logo: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    }),
    LIF3: new Token({
      address: '0x110B25D2B21eE73eB401F3aE7833f7072912A0Bf',
      decimals: 18,
      name: 'LIF3',
      symbol: 'LIF3',
      logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    }),
  },
  [ChainId.AMOY_TESTNET]: {
    USDC: new Token({
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    DAI: new Token({
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      name: 'DAI',
      symbol: 'DAI',
      decimals: 18,
      logo: 'https://assets.lif3.com/swap/tokens/DAI.svg',
    }),
    USDT: new Token({
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logo: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    }),
    WMATIC: new Token({
      address: '0x40FE2F4EeaD3d472E5c064b8e970e5a595A44558',
      decimals: 18,
      symbol: 'WMATIC',
      name: 'Wrapped Matic',
      logo: 'https://assets.lif3.com/swap/tokens/WMATIC.svg',
    }),
    LSHARE: new Token({
      address: '0x9880aDdD2b2acA4a8fa02274dAd1679B38F0A303',
      decimals: 18,
      name: 'LSHARE',
      symbol: 'LSHARE',
      logo: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    }),
    LIF3: new Token({
      address: '0x293475783df8fc51258b1004aFF1Cb1886BbDa6e',
      decimals: 18,
      name: 'LIF3',
      symbol: 'LIF3',
      logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    }),
  },
  [ChainId.ETHEREUM]: {
    USDC: new Token({
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    WETH: new Token({
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      decimals: 18,
      symbol: 'WETH',
      name: 'Wrapped Ethereum',
      logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    }),
  },
  [ChainId.OPTIMISM]: {
    USDC: new Token({
      address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    OP: new Token({
      address: '0x4200000000000000000000000000000000000042',
      decimals: 18,
      symbol: 'OP',
      name: 'Optimism',
      logo: 'https://assets.lif3.com/swap/tokens/OP.svg',
    }),
    WETH: new Token({
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
      symbol: 'WETH',
      name: 'Wrapped Ethereum',
      logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    }),
  },
  [ChainId.ARBITRUM]: {
    USDC: new Token({
      address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),
    ARB: new Token({
      address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
      decimals: 18,
      symbol: 'ARB',
      name: 'Arbitrum',
      logo: 'https://assets.lif3.com/swap/tokens/ARB.svg',
    }),
    WETH: new Token({
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      decimals: 18,
      symbol: 'WETH',
      name: 'Wrapped Ethereum',
      logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    }),
  },
  [ChainId.BASE]: {
    USDC: new Token({
      address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      decimals: 6,
      symbol: 'USDC',
      name: 'USD Base Coin',
      logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    }),

    WETH: new Token({
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
      symbol: 'WETH',
      name: 'Wrapped Ethereum',
      logo: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    }),
  },
  [ChainId.LIF3CHAIN_TESTNET]: {
    WLIF3: new Token({
      address: '0x6883d461FE9FB40D3Ff3393fDE5147A6617b3E30',
      decimals: 18,
      symbol: 'WLIF3',
      name: 'Wrapped Lif3',
      logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    }),
    USDT: new Token({
      address: '0x621A936084BBb73F947C827a92C93Ef6A3612c3A',
      decimals: 6,
      symbol: 'USDT',
      name: 'USDT',
      logo: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    }),
    LSHARE: new Token({
      address: '0x6aAaa67227D8D29D26115a433130D882A876507d',
      decimals: 18,
      symbol: 'LSHARE',
      name: 'LSHARE',
      logo: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    }),
    L3USD: new Token({
      address: '0xe97EF94FF6df13d769f1F1ac23C2419eFF501ad8',
      decimals: 18,
      symbol: 'L3USD',
      name: 'L3USD',
      logo: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    }),
  },
} as const

export const BASES_TO_CHECK_TRADES_AGAINST = {
  [ChainId.FANTOM]: [
    new Token({
      address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
      decimals: 18,
      name: 'Tomb',
      symbol: 'TOMB',
      logo: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
    }),
    new Token({
      address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      decimals: 18,
      name: 'Wrapped Fantom',
      symbol: 'WFTM',
      logo: 'https://assets.lif3.com/swap/tokens/WFTM.svg',
    }),
    new Token({
      address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      decimals: 6,
      name: 'USD Coin',
      symbol: 'USDC',
      logo: 'https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public',
    }),
  ],
  [ChainId.BSC]: [DEFAULT_TOKENS[ChainId.BSC].USDC, DEFAULT_TOKENS[ChainId.BSC].WBNB],
  [ChainId.POLYGON]: [DEFAULT_TOKENS[ChainId.POLYGON].USDC, DEFAULT_TOKENS[ChainId.POLYGON].WMATIC],
  [ChainId.ETHEREUM]: [DEFAULT_TOKENS[ChainId.ETHEREUM].USDC, DEFAULT_TOKENS[ChainId.ETHEREUM].WETH],
  [ChainId.OPTIMISM]: [DEFAULT_TOKENS[ChainId.OPTIMISM].USDC, DEFAULT_TOKENS[ChainId.OPTIMISM].WETH],
  [ChainId.ARBITRUM]: [DEFAULT_TOKENS[ChainId.ARBITRUM].USDC, DEFAULT_TOKENS[ChainId.ARBITRUM].WETH],
  [ChainId.BASE]: [DEFAULT_TOKENS[ChainId.BASE].USDC, DEFAULT_TOKENS[ChainId.BASE].WETH],
  [ChainId.LIF3CHAIN_TESTNET]: [
    DEFAULT_TOKENS[ChainId.LIF3CHAIN_TESTNET].USDT,
    DEFAULT_TOKENS[ChainId.LIF3CHAIN_TESTNET].WLIF3,
  ],
}

export const USDC = {
  [ChainId.FANTOM]: new Token({
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
  }),
  [ChainId.BSC]: new Token({
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    decimals: 18,
    symbol: 'USDC',
    name: 'USD Coin',
  }),
  [ChainId.POLYGON]: new Token({
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
  }),
  [ChainId.ETHEREUM]: new Token({
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
  }),
  [ChainId.OPTIMISM]: new Token({
    address: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
  }),
  [ChainId.ARBITRUM]: new Token({
    address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
  }),
  [ChainId.BASE]: new Token({
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Base Coin',
  }),
}

// Default Ethereum chain tokens
export const FANTOM = {
  USDC: new Token({
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    decimals: 6,
    symbol: 'USDC',
    name: 'USD Coin',
    logo: 'https://assets.lif3.com/swap/tokens/USDC.svg',
  }),
  WBTC: new Token({
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    decimals: 8,
    symbol: 'WBTC',
    name: 'Wrapped Bitcoin',
  }),
  DAI: new Token({
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    decimals: 18,
    symbol: 'DAI',
    name: 'Dai Stablecoin',
    logo: 'https://assets.lif3.com/swap/tokens/DAI.svg',
  }),
  TOMB: new Token({
    address: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    name: 'TOMB',
    symbol: 'TOMB',
    decimals: 18,
  }),
  TBOND: new Token({
    address: '0x24248CD1747348bDC971a5395f4b3cd7feE94ea0',
    name: 'TBOND',
    symbol: 'TBOND',
    decimals: 18,
  }),
  FUSDT: new Token({
    address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    decimals: 6,
    name: 'FUSDT',
    symbol: 'FUSDT',
    logo: 'https://assets.lif3.com/swap/tokens/FUSDT.svg',
  }),
  L3USD: new Token({
    address: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
    decimals: 18,
    name: 'L3USD',
    symbol: 'L3USD',
    logo: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
  }),
  LIF3: new Token({
    address: '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
    decimals: 18,
    name: 'LIF3',
    symbol: 'LIF3',
    logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  }),
}

// used to construct the list of all pairs we consider by default in the frontend
export const getBasesToTrackLiquidityFor = (chainId: number): Array<Token> => [
  WNATIVE[chainId],
  DEFAULT_TOKENS[chainId].DAI,
  DEFAULT_TOKENS[chainId].USDC,
  DEFAULT_TOKENS[chainId].WBTC ?? null,
]

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  icon: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

const isLif3WalletUserAgent = () => /Lif3Wallet/i.test(navigator.userAgent)

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  ...(isLif3WalletUserAgent() && {
    LIF3: {
      connector: injected,
      name: 'Lif3 Wallet',
      icon: Lif3Icon,
      description: 'Easy-to-use browser extension.',
      href: null,
      color: '#3C5D86',
    },
  }),
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    icon: MetamaskIcon,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    icon: WalletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    icon: CoinBaseIcon,
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true,
  },
}

export const NetworkContextName = 'network'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

const NATIVE_CURRENCY_DECIMALS = 18

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH = {
  [ChainId.FANTOM]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)),
  [ChainId.BSC]: JSBI.multiply(
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(NATIVE_CURRENCY_DECIMALS - 2)),
    JSBI.BigInt(6)
  ), // .06 BNB
  [ChainId.POLYGON]: JSBI.multiply(
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(NATIVE_CURRENCY_DECIMALS - 2)),
    JSBI.BigInt(6)
  ), // .06 MATIC
  [ChainId.ETHEREUM]: JSBI.multiply(
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(NATIVE_CURRENCY_DECIMALS - 3)),
    JSBI.BigInt(12)
  ), // .012 ETH
  [ChainId.OPTIMISM]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)),
  [ChainId.ARBITRUM]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)),
  [ChainId.BASE]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)),
  [ChainId.LIF3CHAIN_TESTNET]: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)),
} // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]
