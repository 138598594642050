import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { currentMainNetwork } from 'sdk'
import store from 'state'
import { addPopup } from 'state/application/actions'
import { finalizeTransaction } from 'state/transactions/actions'

export const waitAndFinalizeTransaction = async (hash: string, chainId: number) => {
  const transaction = store.getState().transactions[chainId][hash]

  const CurrentReadOnlyProvider: StaticJsonRpcProvider = PROVIDERS_BY_CHAIN[chainId ?? currentMainNetwork]
  const receipt = await (CurrentReadOnlyProvider ?? PROVIDERS_BY_CHAIN[currentMainNetwork]).waitForTransaction(hash)
  if (receipt) {
    store.dispatch(
      finalizeTransaction({
        chainId: chainId ?? currentMainNetwork,
        hash,
        receipt: {
          blockHash: receipt.blockHash,
          blockNumber: receipt.blockNumber,
          contractAddress: receipt.contractAddress,
          from: receipt.from,
          status: receipt.status,
          to: receipt.to,
          transactionHash: receipt.transactionHash,
          transactionIndex: receipt.transactionIndex,
        },
      })
    )

    store.dispatch(
      addPopup({
        content: {
          txn: {
            hash,
            success: receipt.status === 1,
            summary: transaction?.summary,
          },
        },
        key: hash,
      })
    )
  }

  return receipt
}
