import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { DiscordIcon } from 'icons/DiscordIcon'
import { TwitterIcon } from 'icons/TwitterIcon'
import { MediumIcon } from 'icons/MediumIcon'
import { TelegramIcon } from 'icons/TelegramIcon'
import { YoutubeIcon } from 'icons/YoutubeIcon'
import dayjs from 'dayjs'
import { Instagram } from 'react-feather'
import {
  Wrapper,
  WrapperInner,
  FooterLinksWrapper,
  FooterLinksColumn,
  FooterLinksLabel,
  FooterLink,
  FooterSocialsWrapper,
  FooterSocialItem,
  FooterText,
  FooterTextItem,
  CopyrightRow,
  FooterCopyrightText,
  FooterAppsRow,
  FooterLinksItem,
  FooterLinksItemLogo,
} from './styles'
import { useTranslation } from 'react-i18next'
import AppStoreLogo from 'assets/images/landing/appstore-logo.svg'
import PlayMarketLogo from 'assets/images/landing/googleplay-logo.svg'
import { PromoLinksItemLogo } from 'pages/Landing/LandingPromo/styles'
import { listedTokensList } from 'constants/aggregatorTokensList'
import { useChainId } from 'hooks'

const isLif3WalletUserAgent = () => {
  return /Lif3Wallet/i.test(navigator.userAgent)
}

export const Footer: React.FC = () => {
  const { t } = useTranslation('footer')
  const chainId = useChainId()

  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  const buyLIF3SearchParams = new URLSearchParams({
    ...Object.fromEntries(chainIdOnlySearchParams),
  })
  buyLIF3SearchParams.set(
    'outputCurrency',
    listedTokensList[chainId].find((token) => token.symbol === 'LIF3')?.address ?? ''
  )

  // const crossChainSwapSearchParams = new URLSearchParams({
  //   ...Object.fromEntries(chainIdOnlySearchParams),
  //   swapType: 'CrossChain',
  // })

  return (
    <Wrapper>
      <WrapperInner>
        <FooterLinksWrapper>
          <FooterLinksColumn>
            <FooterLinksLabel>Buy</FooterLinksLabel>
            <FooterLink as={Link} to={{ pathname: '/swap', search: buyLIF3SearchParams.toString() }}>
              Buy $LIF3 on Lif3 Swap
            </FooterLink>
            <FooterLink
              as={Link}
              to={{ pathname: 'https://www.bitmart.com/trade/en-US?symbol=LIF3_USDT&layout=basic' }}
              target="_blank"
            >
              Buy $LIF3 on Bitmart
            </FooterLink>
            <FooterLink
              as={Link}
              to={{ pathname: 'https://trading.bitfinex.com/t/LIFIII:USD?demo=true' }}
              target="_blank"
            >
              Buy $LIF3 on Bitfinex
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: 'https://www.mexc.com/exchange/LIF3_USDT' }} target="_blank">
              Buy Lif3 on MEXC
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: '/buy-crypto', search: chainIdOnlySearchParams.toString() }}>
              Buy Crypto with Card
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: '/how-to-buy', search: chainIdOnlySearchParams.toString() }}>
              How to Buy $LIF3
            </FooterLink>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksLabel>Trade</FooterLinksLabel>
            <FooterLink as={Link} to={{ pathname: '/swap', search: chainIdOnlySearchParams.toString() }}>
              Swap
            </FooterLink>
            {/* <FooterLink as={Link} to={{ pathname: '/swap', search: crossChainSwapSearchParams.toString() }}>
              Cross-Chain Swap
            </FooterLink> */}
            <FooterLink as={Link} to={{ pathname: '/tokens', search: chainIdOnlySearchParams.toString() }}>
              View All Tokens
            </FooterLink>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksLabel>Community</FooterLinksLabel>
            <FooterLink as={Link} to={{ pathname: 'https://docs.lif3.com/' }} target="_blank">
              Docs
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: 'https://support.lif3.com/' }} target="_blank">
              Lif3 Support
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: '/media' }}>
              Media
            </FooterLink>
            <FooterLink
              as={Link}
              to={{ pathname: 'https://support.lif3.com/hc/en-us/community/topics/5998714637455-Feature-Requests' }}
              target="_blank"
            >
              Feature Request
            </FooterLink>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksLabel>{t('common:lif3Ecosystem')}</FooterLinksLabel>
            <FooterLink as={Link} to={{ pathname: '/wallet', search: chainIdOnlySearchParams.toString() }}>
              Lif3 Wallet
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: '/chain', search: chainIdOnlySearchParams.toString() }}>
              Lif3 Chain
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: '/l3usd', search: chainIdOnlySearchParams.toString() }}>
              L3USD
            </FooterLink>
            <FooterLink as={Link} to={{ pathname: '/events', search: chainIdOnlySearchParams.toString() }}>
              Lif3 Events
            </FooterLink>
          </FooterLinksColumn>
        </FooterLinksWrapper>
        <FooterSocialsWrapper>
          <FooterSocialItem target="_blank" href="https://twitter.com/Official_LIF3">
            <TwitterIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://discord.gg/lif3">
            <DiscordIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://t.me/Lif3_Official">
            <TelegramIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://www.instagram.com/lif3official/">
            <Instagram />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://official-lif3.medium.com/">
            <MediumIcon />
          </FooterSocialItem>
          <FooterSocialItem target="_blank" href="https://www.youtube.com/@Official_LIF3">
            <YoutubeIcon />
          </FooterSocialItem>
        </FooterSocialsWrapper>
        <FooterText>
          <FooterTextItem>{t('description')}</FooterTextItem>
          {!isLif3WalletUserAgent() && (
            <FooterAppsRow>
              <FooterLinksItem href="https://apps.apple.com/app/lif3/id6444389674" target="_blank" rel="noreferrer">
                <FooterLinksItemLogo src={AppStoreLogo} alt="App Store" />
              </FooterLinksItem>
              <FooterLinksItem
                href="https://play.google.com/store/apps/details?id=com.lif3.wallet"
                target="_blank"
                rel="noreferrer"
              >
                <PromoLinksItemLogo src={PlayMarketLogo} alt="App Store" />
              </FooterLinksItem>
            </FooterAppsRow>
          )}
        </FooterText>

        <CopyrightRow>
          <FooterCopyrightText>© {dayjs().format('YYYY')} Lif3 Labs Ltd</FooterCopyrightText>
          {/* <FooterAuditWrapper href="https://tombchain.com/" target="_blank">
            <FooterAuditText>Powered by</FooterAuditText>
            <Box width="103px" height="26px" display="flex" alignItems="center" justifyContent="center">
              <AuditProviderLogo />
            </Box>
          </FooterAuditWrapper> */}
        </CopyrightRow>
      </WrapperInner>
    </Wrapper>
  )
}
