import { useQuery } from 'react-query'
import { ERC20MulticallCompatibleAbi, multicall, transformValueWithDecimals, useActiveWeb3React } from 'shared'
import Big from 'big.js'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { useChainId } from 'hooks'

export const useGetWalletTokensBalancesQuery = (tokens: Array<any>) => {
  const { account } = useActiveWeb3React()
  const chainId = useChainId()

  return useQuery<Record<string, Big>>(
    ['wallet/balances-of-tokens', account, chainId],
    async () => {
      const balancesCalls = tokens.map((token) => {
        return {
          address: token.address,
          name: 'balanceOf',
          params: [account],
        }
      })
      const balancesResult = await multicall<
        Array<{
          balance: Big
        }>
      >(ERC20MulticallCompatibleAbi, balancesCalls, PROVIDERS_BY_CHAIN[chainId] as any, chainId)
      return balancesResult.reduce((acc, balanceData, index) => {
        const token = tokens[index]
        let balance = Big(0)
        try {
          const balanceResponse = balanceData.balance

          balance = transformValueWithDecimals(Big(balanceResponse.toString()), token.decimals)
        } finally {
          return {
            ...acc,
            [token.address]: balance,
          }
        }
      }, {})
    },
    {
      enabled: Boolean(tokens.length && account),
      refetchInterval: 5 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}
