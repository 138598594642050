import React from 'react'

export const DebankIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67633 7.7C9.67633 9.52254 8.17671 11 6.32683 11H0V8.8H6.32683C6.94346 8.8 7.44333 8.30751 7.44333 7.7C7.44333 7.09249 6.94346 6.6 6.32683 6.6H4.09383V4.4H6.32683C6.94346 4.4 7.44333 3.90751 7.44333 3.3C7.44333 2.69249 6.94346 2.2 6.32683 2.2H0V0H6.32683C8.17671 0 9.67633 1.47746 9.67633 3.3C9.67633 4.14519 9.35383 4.91617 8.82345 5.5C9.35383 6.08383 9.67633 6.85481 9.67633 7.7Z"
        fill="currentColor"
      />
      <path
        opacity="0.12"
        d="M0 8.8H5.4341C4.27982 10.1359 2.44208 11 0.372167 11C0.247232 11 0.123144 10.9969 0 10.9906V8.8ZM6.57244 6.6H4.466V4.4H6.57244C6.65543 4.75542 6.699 5.12329 6.699 5.5C6.699 5.87671 6.65543 6.24458 6.57244 6.6ZM5.4341 2.2H0V0.00935763C0.123144 0.00314796 0.247232 0 0.372167 0C2.44208 0 4.27982 0.864109 5.4341 2.2Z"
        fill="black"
      />
      <path
        d="M0 0C3.08313 0 5.5825 2.46243 5.5825 5.5C5.5825 8.53757 3.08313 11 0 11V8.8C1.84988 8.8 3.3495 7.32254 3.3495 5.5C3.3495 3.67746 1.84988 2.2 0 2.2V0Z"
        fill="currentColor"
      />
    </svg>
  )
}
