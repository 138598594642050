import { useStakingIncentivesQuery } from 'hooks/queries/v3/useStakingIncentivesQuery'
import { IncentiveRaw, PositionRaw } from 'types/v3'

export type UseIncentiveByPositionInputProps = {
  position?: PositionRaw
  poolId?: string
}

export type UseIncentiveByPositionOutputProps = {
  incentive?: IncentiveRaw
}

export const useIncentiveByPosition = ({
  position,
  poolId,
}: UseIncentiveByPositionInputProps): UseIncentiveByPositionOutputProps => {
  const { data: incentivesData } = useStakingIncentivesQuery('active')
  const incentivesByPoolId =
    incentivesData?.incentives.filter((incentive) => incentive.pool === poolId?.toLowerCase()) ?? []

  if (incentivesByPoolId && position) {
    const isStaked = position?.staked

    if (isStaked) {
      // if staked pick that one was used for
      const usedIncentiveId = position?.incentiveId
      const currentIncentive = incentivesByPoolId.find((incentive) => incentive.id === usedIncentiveId)

      return {
        incentive: currentIncentive,
      }
    }

    // if not pick the longest one
    return {
      incentive: incentivesByPoolId[0],
    }
  }

  return {}
}
