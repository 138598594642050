import { Currency, NATIVE_CURRENCY, WNATIVE } from 'sdk'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as DropdownIcon } from 'assets/images/dropdown.svg'
import CurrencyLogo from 'components/CurrencyLogo'
import { useChainId } from 'hooks'

const Aligner = styled.span`
  display: flex;
  align-items: center;
`

const StyledDropDown = styled(DropdownIcon)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;
  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText1};
`

type Props = {
  currency: Currency | undefined
  disableCurrencySelect: boolean
}

export const PairSelectCurrency: React.FC<Props> = (props) => {
  const chainId = useChainId()
  const symbol =
    props.currency?.symbol === WNATIVE[chainId].symbol ? NATIVE_CURRENCY[chainId].symbol : props.currency?.symbol

  return (
    <Aligner>
      {props.currency ? <CurrencyLogo currency={props.currency} size={'24px'} /> : null}

      <StyledTokenName active={Boolean(symbol)}>
        {(symbol && symbol.length > 20
          ? symbol.slice(0, 4) + '...' + symbol.slice(symbol.length - 5, symbol.length)
          : symbol) || 'Select a token'}
      </StyledTokenName>

      {!props.disableCurrencySelect && <StyledDropDown selected={Boolean(props.currency)} />}
    </Aligner>
  )
}
