import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right-icon.svg'
import { ReactComponent as SwapIcon } from 'assets/svg/swap-icon.svg'
import { ReactComponent as SwapIconMobile } from 'assets/svg/swap-icon-mobile.svg'
import { ReactComponent as Lif3Icon } from 'assets/svg/lif3-icon.svg'
import { ReactComponent as TokensIcon } from 'assets/svg/tokens-icon.svg'

export const CommMobileDropdownBox = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  transform-origin: 50px 0px;
  transform: translateX(0) scale(0.8);
  max-width: 400px;

  display: flex;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  padding: 30px;
  width: max-content;
  border-radius: 12px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;
  visibility: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    top: -17px;
    left: 0;
  }

  // &::after {
  //   content: '';
  //   display: block;
  //   width: 24px;
  //   height: 24px;
  //   border-radius: 5px;
  //   transform: rotate(45deg);
  //   position: absolute;
  //   top: -9px;
  //   left: 50px;
  //   z-index: -1;
  //   background: ${({ theme }) => theme.dropDownBg};
  //   -webkit-backdrop-filter: blur(150px);
  //   backdrop-filter: blur(150px);
  // }

  @media screen and (max-width: 1510px) {
    transform: translateX(0) scale(0.8);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    right: 0;
    transform: translateX(20%);
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: 10px;
    top: auto;
    left: 11px;
    right: 11px;
    transform: translate(0, 100%);

    width: calc(100% - 22px);
    max-width: 100%;
    padding: 20px;

    background: ${({ theme }) => theme.componentBg4};
    backdrop-filter: blur(30px);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px 20px;
  `}
`

export const CommMobileDropdownCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`

export const CommMobileDropdownTitle = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #97a3b7;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export const CommMobileDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    gap: 10px;
  `}
`

export const ArrowRightStyledIcon = styled(ArrowRight)`
  width: 6px;
  height: 10px;
  opacity: 0;
  margin-left: auto;
  transition: opacity 0.3s, color 0.3s;
`

export const CommMobileDropdownListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 16px;
  text-decoration: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.primaryText1};

  &:hover {
    background: #ffffff0d;
  }

  & > svg {
    flex: 0 0 auto;
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover > svg {
    color: ${({ theme }) => theme.secondaryText1};
  }

  &:hover > ${ArrowRightStyledIcon} {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 15px;
  `}
`

export const CommMobileDropdownListItemCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const CommMobileDropdownListItemTitle = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`

export const CommMobileDropdownListItemText = styled.span`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

export const CommunityDropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
`

export const CommunityDropdownImage = styled.img`
  display: block;
  max-width: 224px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`

export const CommunityDropdownUpdate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  & > svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.primaryText1};
  }
`

export const CommunityDropdownUpdateLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-decoration: none;
`

export const CommunityDropdownUpdateLinkText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 224px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-decoration: none;
`
