import { Box } from 'rebass'
import styled from 'styled-components'

export const FaqBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 926px;
  padding: 95px 20px 100px;
  gap: 45px;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 45px;
  `};
`

export const FaqsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 926px;
  padding: 95px 0 100px;
  gap: 45px;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 45px;
  `};
`

export const FaqHeader = styled.div`
  display: flex;
  flex-direction: column;
`
export const FaqWrap = styled(Box)`
  border-radius: 20px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
  `};
`

export const FaqHeaderBox = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 45px;
  gap: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  background: ${({ theme }) => theme.componentBg2};
  color: ${({ theme }) => theme.primaryText1};

  border-radius: 20px 20px 0 0;
  box-shadow: 0px 20px 63px rgba(0, 0, 0, 0.04), 0px 7.7037px 20.0667px rgba(0, 0, 0, 0.03),
    0px 1.62963px 5.13333px rgba(0, 0, 0, 0.0314074);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
    flex-direction: column;
  `};
`

export const FaqHeading = styled.span`
  display: flex;
  padding: 10px 24px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.primaryText1};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  border: none;
`

export const FaqMain = styled.div`
  display: flex;
  gap: 30px;
  background: ${({ theme }) => theme.componentBg1};
  border-radius: 0 0 20px 20px;
`

export const FaqToken = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 10px;
  border-radius: 30px;
  height: 100%;
  background: ${({ theme }) => theme.componentBg2};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
  `};
`

export const FaqTokenLogo = styled.img`
  max-width: 80px;
  max-height: 80px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 60px;
    max-height: 60px;
  `};
`

export const FaqTokenText = styled.span`
  margin-top: auto;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryText1};
`

export const FaqList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
`

export const FaqItem = styled.li<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.primaryText1};
  border-bottom: 1px solid ${({ theme }) => theme.border2};

  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 18px;
  `};
`

export const FaqQuestion = styled.h3`
  margin: 0;
  padding: 25px 40px;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px 10px;
  `};
`

export const FaqAnswerBox = styled.div`
  padding: 30px;
  margin: 0 32px 25px;
  border-radius: 20px;
  background: ${({ theme }) => theme.componentBg2};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 15px;
    margin: 0 10px 15px;
  `};

  & > * {
    padding-left: 10px;
    padding-right: 10px;
  }
`
