import { DiscordIcon } from 'icons/DiscordIcon'
import { TwitterIcon } from 'icons/TwitterIcon'
import { MediumIcon } from 'icons/MediumIcon'
import { TelegramIcon } from 'icons/TelegramIcon'
import { YoutubeIcon } from 'icons/YoutubeIcon'
import { InstagramIcon } from 'icons/InstagramIcon'
import {
  CommunityDropdownBox,
  CommunityDropdownCol,
  CommunityDropdownContent,
  CommunityDropdownDoc,
  CommunityDropdownDocCol,
  CommunityDropdownDocText,
  CommunityDropdownDocTitle,
  CommunityDropdownDocsList,
  CommunityDropdownImage,
  CommunityDropdownItem,
  CommunityDropdownSocial,
  CommunityDropdownSocialList,
  CommunityDropdownTitle,
  CommunityDropdownUpdateLink,
  ArrowRightUpStyledIcon,
  ArrowRightStyledIcon,
  CommunityDropdownUpdateLinkText,
} from './styles'
import { ArrowRight } from 'react-feather'
import { BriefCaseIcon } from 'icons/BriefCaseIcon'
import { ThumbUpIcon } from 'icons/ThumbUpIcon'
import updateOneImage from 'assets/images/updates/update-1.png'
import updateTwoImage from 'assets/images/updates/update-2.png'

export const CommunityDropdown = ({ toggleIcon }: { toggleIcon: (state: boolean) => void }) => {
  return (
    <CommunityDropdownBox onMouseEnter={() => toggleIcon(true)} onMouseLeave={() => toggleIcon(false)}>
      <CommunityDropdownContent>
        <CommunityDropdownCol>
          <CommunityDropdownTitle>Social</CommunityDropdownTitle>

          <CommunityDropdownSocialList>
            <CommunityDropdownSocial to={{ pathname: 'https://twitter.com/Official_LIF3' }} target="_blank">
              <TwitterIcon />
              Twitter
              <ArrowRightUpStyledIcon />
            </CommunityDropdownSocial>
            <CommunityDropdownSocial to={{ pathname: 'https://t.me/Lif3_Official' }} target="_blank">
              <TelegramIcon />
              Telegram
              <ArrowRightUpStyledIcon />
            </CommunityDropdownSocial>
            <CommunityDropdownSocial to={{ pathname: 'https://www.instagram.com/lif3official/' }} target="_blank">
              <InstagramIcon />
              Instagram
              <ArrowRightUpStyledIcon />
            </CommunityDropdownSocial>
            <CommunityDropdownSocial to={{ pathname: 'https://official-lif3.medium.com/' }} target="_blank">
              <MediumIcon />
              Medium
              <ArrowRightUpStyledIcon />
            </CommunityDropdownSocial>
            <CommunityDropdownSocial to={{ pathname: 'https://discord.gg/lif3' }} target="_blank">
              <DiscordIcon />
              Discord
              <ArrowRightUpStyledIcon />
            </CommunityDropdownSocial>
            <CommunityDropdownSocial to={{ pathname: 'https://www.youtube.com/@Official_LIF3' }} target="_blank">
              <YoutubeIcon />
              Youtube
              <ArrowRightUpStyledIcon />
            </CommunityDropdownSocial>
          </CommunityDropdownSocialList>
        </CommunityDropdownCol>
        <CommunityDropdownCol>
          <CommunityDropdownTitle>Docs & Support</CommunityDropdownTitle>

          <CommunityDropdownDocsList>
            <CommunityDropdownDoc to={{ pathname: 'https://docs.lif3.com/' }} target="_blank">
              <BriefCaseIcon />
              <CommunityDropdownDocCol>
                <CommunityDropdownDocTitle>Docs</CommunityDropdownDocTitle>
                <CommunityDropdownDocText>Read the Lif3 Docs.</CommunityDropdownDocText>
              </CommunityDropdownDocCol>
              <ArrowRightStyledIcon />
            </CommunityDropdownDoc>
            <CommunityDropdownDoc to={{ pathname: 'https://support.lif3.com/' }} target="_blank">
              <ThumbUpIcon />
              <CommunityDropdownDocCol>
                <CommunityDropdownDocTitle>Lif3 Support</CommunityDropdownDocTitle>
                <CommunityDropdownDocText>Get Web and App support.</CommunityDropdownDocText>
              </CommunityDropdownDocCol>
              <ArrowRightStyledIcon />
            </CommunityDropdownDoc>
            <CommunityDropdownDoc
              to={{ pathname: 'https://support.lif3.com/hc/en-us/community/topics/5998714637455-Feature-Requests' }}
              target="_blank"
            >
              <BriefCaseIcon />
              <CommunityDropdownDocCol>
                <CommunityDropdownDocTitle>Feature Request</CommunityDropdownDocTitle>
                <CommunityDropdownDocText>Let us know what you would like to see.</CommunityDropdownDocText>
              </CommunityDropdownDocCol>
              <ArrowRightStyledIcon />
            </CommunityDropdownDoc>
            <CommunityDropdownDoc
              to={{ pathname: 'https://support.lif3.com/hc/en-us/community/topics/6259382527759-Bug-Report' }}
              target="_blank"
            >
              <ThumbUpIcon />
              <CommunityDropdownDocCol>
                <CommunityDropdownDocTitle>Bug Report</CommunityDropdownDocTitle>
                <CommunityDropdownDocText>Report any bugs you find.</CommunityDropdownDocText>
              </CommunityDropdownDocCol>
              <ArrowRightStyledIcon />
            </CommunityDropdownDoc>
          </CommunityDropdownDocsList>
        </CommunityDropdownCol>
        <CommunityDropdownCol>
          <CommunityDropdownTitle>Updates</CommunityDropdownTitle>

          <CommunityDropdownCol>
            <CommunityDropdownItem>
              <CommunityDropdownUpdateLink to={{ pathname: '/news' }}>
                <CommunityDropdownImage src={updateOneImage} alt="Lif3 Update" />
                <CommunityDropdownUpdateLinkText>
                  Read Weekly Updates
                  <ArrowRight size="16px" />
                </CommunityDropdownUpdateLinkText>
              </CommunityDropdownUpdateLink>
            </CommunityDropdownItem>
            <CommunityDropdownItem>
              <CommunityDropdownUpdateLink to={{ pathname: '/comics' }}>
                <CommunityDropdownImage src={updateTwoImage} alt="Lif3 Update" />
                <CommunityDropdownUpdateLinkText>
                  View Weekly Comic
                  <ArrowRight size="16px" />
                </CommunityDropdownUpdateLinkText>
              </CommunityDropdownUpdateLink>
            </CommunityDropdownItem>
          </CommunityDropdownCol>
        </CommunityDropdownCol>
      </CommunityDropdownContent>
    </CommunityDropdownBox>
  )
}
