const PARAMETERS = Object.freeze({
  UNISWAP_V2_PAIR_ADDRESS: 'uniswapV2PairAddress',
})

const PAGES = Object.freeze({
  HOME: '/terrace',
  MARKETS: `/terrace/markets`,
  LENDING_POOL: `/terrace/pool/:${PARAMETERS.UNISWAP_V2_PAIR_ADDRESS}`,
  ACCOUNT: `/terrace/account`,
  //BOUNTIES: `/terrace/bounties`,
  // CREATE_NEW_PAIR: '/lend/create-new-pair',
})

export { PARAMETERS, PAGES }
