import { BigNumber } from 'ethers'
import { IncentiveRaw, MappedIncentive } from 'types/v3'

export const getMappedIncentive = (incentive?: IncentiveRaw): MappedIncentive | undefined => {
  if (!incentive) {
    return
  }

  return {
    id: incentive?.id ?? '',
    reward: BigNumber.from(incentive?.reward),
    ended: incentive?.ended ?? false,
    totalStaked: incentive?.totalStaked ?? '',
    key: {
      rewardToken: incentive?.rewardToken ?? '',
      pool: incentive?.pool ?? '',
      startTime: Number(incentive?.startTime),
      endTime: Number(incentive?.endTime),
      refundee: incentive?.refundee ?? '',
    },
  }
}
