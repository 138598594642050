export { ThoughtfulMonkeySvg } from './svg/ThoughtfulMonkeySvg'

export { TokenBadge } from './ui/TokenBadge'
export * from './ui/Box'

export { InputError } from './ui/InputError'

export * from './constants/localStorage'

export { useLocalStorage } from './hooks/useLocalStorage'
export { useActiveWeb3React } from './hooks/useActiveWeb3React'
export { useApi } from './hooks/useApi'
export { useContract } from './hooks/useContract'
export { useTokenContract } from './hooks/useTokenContract'
export { useIntersectionObserver } from './hooks/useIntersectionObserver'
export { useThemeGeneral } from './hooks/useThemeGeneral'
export { useLayoutContainer } from './hooks/useLayoutContainer'
export { usePrevious } from './hooks/usePrevious'
export { useOnClickOutside } from './hooks/useOnClickOutside'

export { getContract } from './utils/getContract'
export { isMetaMaskInstalled } from './utils/isMetaMaskInstalled'
export { transformValueWithDecimals } from './utils/transformValueWithDecimals'
export { shortenAddress } from './utils/shortenAddress'
export { setupNetwork } from './utils/setupNetwork'
export { addTokenIntoWallet } from './utils/addTokenIntoWallet'
export { getSortedTokens } from './utils/getSortedTokens'
export { getExchangeSubgraphUrl } from './utils/getExchangeSubgraphUrl'
export { getExchangeSubgraphUrlWebsocket } from './utils/getExchangeSubgraphUrlWebsocket'
export * from './utils/getExchangeUrl'
export { getExchangeLogo } from './utils/getExchangeLogo'

export { wrappedCurrency, wrappedCurrencyAmount } from './utils/wrappedCurrency'
export { shortenTokenSymbol } from './utils/shortenTokenSymbol'
export * from './utils/web3'
export * from './utils/multicall'

export { ERC20MulticallCompatibleAbi } from './abi/ERC20MulticallCompatibleAbi'
export { ERC20PairContractAbi } from './abi/ERC20PairContractAbi'
export { ERC20TokenContractAbi } from './abi/ERC20TokenContractAbi'
export { SushiOracleAbi } from './abi/SushiOracleAbi'
export { MarketplaceAbi } from './abi/MarketplaceAbi'
export { ERC721CommunityAbi } from './abi/ERC721CommunityAbi'
export { ERC721OfficialAbi } from './abi/ERC721OfficialAbi'
export { NftCollectionsAbi } from './abi/NftCollectionsAbi'
export { TipsAbi } from './abi/TipsAbi'
export { PairAbi } from './abi/pairAbi'
export { UniswapV2PairAbi } from './abi/UniswapV2PairAbi'
export { FactoryAbi } from './abi/FactoryAbi'

export * from './api'

export * from './types'

export * from './icons'

export * from './context'

export { default as eyesEmoji } from './assets/images/eyes-emoji.png'
export { default as whaleEmoji } from './assets/images/whale-emoji.png'
export { default as bearEmoji } from './assets/images/bear-emoji.png'
export { default as bullEmoji } from './assets/images/bull-emoji.png'
