import styled from 'styled-components'
import { Box } from 'rebass'
import { ButtonEmpty } from 'components/Button'

export const SwapTabsBox = styled(Box)<{ tokenStyle?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.fixedPanelBg};
`

export const SwapMarketBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-radius: 10px;
  background: transparent;
`

export const SwapTabsWrapper = styled(Box)<{ wrapperStyle?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background: ${({ theme }) => theme.componentBg2};
`

export const SwapTab = styled.button<{ isActive: boolean }>`
  padding: 5px 10px;
  border-radius: 10px;
  flex-grow: 1;
  width: 100%;

  font-size: 18px;
  line-height: 23px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 300)};
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : '#859BB1')};
  background: ${({ theme, isActive }) => (isActive ? theme.white : 'transparent')};
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

export const WarningBox = styled(Box)<{ wrapperStyle?: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background: #ffffff26;
  font-family: 'IBM Plex Sans';
`

export const MarketButton = styled(ButtonEmpty)<{ wrapperStyle?: boolean }>`
  width: fit-content;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  background: #ffffff30;
  padding: 6px 30px;
`
