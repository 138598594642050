import { useApi } from 'shared'
import { useActiveWeb3React } from 'hooks'
import { useQuery } from 'react-query'
import { useCurrentChainDebankid } from '../../TokenList/hooks/useCurrentChainDebankId'

export const useGetComplexProtocolListQuery = () => {
  const { account } = useActiveWeb3React()
  const api = useApi()
  const currentChainDebankId = useCurrentChainDebankid()

  return useQuery(
    ['portfolio-complex-protocol-list', account, currentChainDebankId],
    async () => {
      const result = await api?.deBankApi.get<Array<{ portfolio_item_list: Array<any>; id: string }>>(
        '/user/complex_protocol_list',
        {
          params: {
            id: account,
            chain_id: currentChainDebankId,
          },
        }
      )

      const complexProtocolList = result?.data ?? []

      return complexProtocolList.reduce<{
        lp: Array<any>
        farming: Array<any>
        staked: Array<any>
      }>(
        (acc, protocol) => {
          protocol.portfolio_item_list.forEach((portfolioItem) => {
            switch (portfolioItem.name) {
              case 'Farming': {
                if (portfolioItem.detail.supply_token_list && portfolioItem.detail.reward_token_list) {
                  acc.farming.push({
                    stats: portfolioItem.stats,
                    supplyList: portfolioItem.detail.supply_token_list,
                    rewardList: portfolioItem.detail.reward_token_list,
                  })
                }

                break
              }

              case 'Liquidity Pool': {
                if (portfolioItem.detail.supply_token_list) {
                  acc.lp.push({
                    stats: portfolioItem.stats,
                    supplyList: portfolioItem.detail.supply_token_list,
                  })
                }

                break
              }
            }
          })

          return acc
        },
        {
          lp: [],
          farming: [],
          staked: [],
        }
      )
    },
    {
      refetchOnMount: false,
      enabled: Boolean(account),
      refetchInterval: 45000,
      refetchOnWindowFocus: false,
    }
  )
}
