import useTheme from 'hooks/useTheme'
import React from 'react'

export const QuestionIcon = ({ fill = 'none' }: { fill?: string }) => {
  const theme = useTheme()

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 15.5C12.1422 15.5 15.5 12.1422 15.5 8C15.5 3.85783 12.1422 0.5 8 0.5C3.85783 0.5 0.5 3.85783 0.5 8C0.5 12.1422 3.85783 15.5 8 15.5Z"
        stroke={'rgba(255, 255, 255, 0.5)'}
      />
      <path
        d="M8 12.1667V9.66669"
        stroke={theme.primaryText1}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeDasharray="0.01 4.5"
      />
      <path
        d="M5.9165 6.05556C5.9165 4.82823 6.84925 3.83331 7.99984 3.83331C9.15042 3.83331 10.0832 4.82831 10.0832 6.05556C10.0832 7.99998 7.99984 7.99998 7.99984 9.75915"
        stroke={theme.primaryText1}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}
