import React from 'react'
import useTheme from 'hooks/useTheme'
import ReactModal from 'react-modal'

export default function Modal(props: ReactModal.Props & any) {
  const theme = useTheme()

  return (
    <ReactModal
      closeTimeoutMS={150}
      style={{
        overlay: {
          zIndex: 99999,
          overflow: 'hidden',
          background: props.overlayBackground ?? theme.modalOverlay,
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        },
        content: {
          background: props.background ?? theme.modalBG,
          boxShadow: theme.modalBoxShadow,
          maxWidth: props.maxWidth ?? '453px',
          minHeight: props.minHeight ?? 0,
          maxHeight: '80vh',
          display: 'flex',
          borderRadius: props.borderRadius ?? '20px',
          margin: 'auto',
          border: 'none',
          padding: 0,
          inset: 'unset',
          transform: 'translateY(-50%)',
        },
      }}
      {...props}
    >
      {props.children}
    </ReactModal>
  )
}
