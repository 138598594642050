import styled from 'styled-components'

export const InputError = styled.span`
  display: block;
  font-size: 14px;

  &:before {
    display: inline;
    content: '⚠';
    margin-right: 8px;
  }
`
