import React, { useRef, RefObject, useCallback, useState, useMemo } from 'react'
import Column from 'components/Column'
import { PaddedColumn, Separator, SearchInput } from './styleds'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { TYPE, ExternalLinkIcon, TrashIcon, ButtonText, ExternalLink } from 'theme'
import { useToken } from 'hooks/Tokens'
import styled from 'styled-components'
import { useUserAddedTokens, useRemoveUserAddedToken } from 'state/user/hooks'
import { Token } from 'sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import { getExplorerLink, isAddress } from 'utils'
import Card from 'components/Card'
import ImportRow from './ImportRow'
import useTheme from '../../hooks/useTheme'

import { CurrencyModalView } from './CurrencySearchModal'
import { ArrowBackIcon } from 'icons/ArrowBackIcon'
import { Box } from 'shared'
import { Text } from 'rebass'
import { useChainId } from 'hooks'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 1px solid ${({ theme }) => theme.primary2};
  padding: 20px;
  margin-top: auto;
`

export default function ManageTokens({
  setModalView,
  setImportToken,
  searchTokenQuery,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  searchTokenQuery: string
}) {
  const [searchQuery, setSearchQuery] = useState<string>(searchTokenQuery)
  const theme = useTheme()

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
  }, [])

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)

  // all tokens for local lisr
  const userAddedTokens: Token[] = useUserAddedTokens()
  const removeToken = useRemoveUserAddedToken()

  const handleRemoveAll = useCallback(() => {
    if (userAddedTokens) {
      userAddedTokens.map((token) => {
        return removeToken(token.address)
      })
    }
  }, [removeToken, userAddedTokens])

  const chainId = useChainId()

  const tokenList = useMemo(() => {
    return userAddedTokens.map((token) => (
      <RowBetween key={token.address} width="100%">
        <RowFixed>
          <CurrencyLogo currency={token} size={'16px'} />
          <ExternalLink href={getExplorerLink(token.address, 'address', chainId)}>
            <TYPE.main ml={'10px'} fontWeight={500}>
              {token.symbol}
            </TYPE.main>
          </ExternalLink>
        </RowFixed>
        <RowFixed>
          <TrashIcon onClick={() => removeToken(token.address)} />
          <ExternalLinkIcon href={getExplorerLink(token.address, 'address', chainId)} />
        </RowFixed>
      </RowBetween>
    ))
  }, [userAddedTokens, removeToken])

  return (
    <Wrapper>
      <Column style={{ width: '100%', flex: '1 1' }}>
        <PaddedColumn gap="14px">
          <Row>
            <Box display="flex" alignItems="center">
              <Box
                mr="14px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ cursor: 'pointer' }}
                onClick={() => setModalView(CurrencyModalView.search)}
              >
                <ArrowBackIcon color={theme.secondaryText1} />
              </Box>
              <Text fontSize="18px" fontWeight={500} color={theme.primaryText1}>
                Manage tokens
              </Text>
            </Box>
          </Row>
          <Row>
            <SearchInput
              type="text"
              placeholder={'0x0000'}
              value={searchQuery}
              autoComplete="off"
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
            />
          </Row>
          {searchQuery !== '' && !isAddressSearch && <TYPE.error error={true}>Enter valid token address</TYPE.error>}
          {searchToken && (
            <Card backgroundColor={theme.componentBg2} padding="10px 0">
              <ImportRow
                token={searchToken}
                showImportView={() => setModalView(CurrencyModalView.importToken)}
                setImportToken={setImportToken}
                style={{ height: 'fit-content' }}
              />
            </Card>
          )}
        </PaddedColumn>
        <Separator />
        <PaddedColumn gap="lg">
          <RowBetween>
            <TYPE.main fontWeight={500}>
              {userAddedTokens?.length} Custom {userAddedTokens.length === 1 ? 'Token' : 'Tokens'}
            </TYPE.main>
            {userAddedTokens.length > 0 && (
              <ButtonText onClick={handleRemoveAll}>
                <TYPE.blue>Clear all</TYPE.blue>
              </ButtonText>
            )}
          </RowBetween>
          {tokenList}
        </PaddedColumn>
      </Column>
      <Footer>
        <TYPE.darkGray fontSize={14}>Custom tokens are stored locally in your browser</TYPE.darkGray>
      </Footer>
    </Wrapper>
  )
}
