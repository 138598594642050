import React, { useRef, useState } from 'react'
import {
  AnalyticsDropdownBox,
  AnalyticsDropdownCol,
  AnalyticsDropdownListItem,
  AnalyticsDropdownListItemCol,
  AnalyticsDropdownListItemText,
  AnalyticsDropdownListItemTitle,
  AnalyticsDropdownList,
  AnalyticsDropdownTitle,
  AnalyticsDropdownImage,
} from './styles'
import LshareIcon from 'assets/images/logos/lshare.png'
import L3usdIcon from 'assets/images/logos/l3usd.png'
import { AnalyticsIcon } from 'icons/AnalyticsIcon'
import { SwapIcon } from 'icons/SwapIcon'
import { ReactComponent as Lif3TokenIcon } from 'assets/images/logos/lif-logo.svg'

export const AnalyticsDropdown = () => {
  return (
    <AnalyticsDropdownBox>
      <AnalyticsDropdownCol>
        <AnalyticsDropdownTitle>Stats</AnalyticsDropdownTitle>

        <AnalyticsDropdownList>
          <AnalyticsDropdownListItem href="https://trade-stats.lif3.com/" target="_blank" rel="noreferrer">
            <AnalyticsIcon />
            <AnalyticsDropdownListItemCol>
              <AnalyticsDropdownListItemTitle>Lif3 Trade Stats</AnalyticsDropdownListItemTitle>
              <AnalyticsDropdownListItemText>Trading and LLP statistics.</AnalyticsDropdownListItemText>
            </AnalyticsDropdownListItemCol>
          </AnalyticsDropdownListItem>
          <AnalyticsDropdownListItem href="https://info.lif3.com/" target="_blank" rel="noreferrer">
            <SwapIcon />
            <AnalyticsDropdownListItemCol>
              <AnalyticsDropdownListItemTitle>Lif3 Swap Info</AnalyticsDropdownListItemTitle>
              <AnalyticsDropdownListItemText>Swap info page.</AnalyticsDropdownListItemText>
            </AnalyticsDropdownListItemCol>
          </AnalyticsDropdownListItem>
          <AnalyticsDropdownListItem href="https://www.coingecko.com/en/coins/lif3" target="_blank" rel="noreferrer">
            <Lif3TokenIcon />
            <AnalyticsDropdownListItemCol>
              <AnalyticsDropdownListItemTitle>LIF3 CoinGecko</AnalyticsDropdownListItemTitle>
              <AnalyticsDropdownListItemText>View Lif3 on CoinGecko.</AnalyticsDropdownListItemText>
            </AnalyticsDropdownListItemCol>
          </AnalyticsDropdownListItem>
          <AnalyticsDropdownListItem
            href="https://www.coingecko.com/en/coins/lif3-lshare"
            target="_blank"
            rel="noreferrer"
          >
            <AnalyticsDropdownImage src={LshareIcon} />
            <AnalyticsDropdownListItemCol>
              <AnalyticsDropdownListItemTitle>LSHARE CoinGecko</AnalyticsDropdownListItemTitle>
              <AnalyticsDropdownListItemText>View LSHARE on CoinGecko.</AnalyticsDropdownListItemText>
            </AnalyticsDropdownListItemCol>
          </AnalyticsDropdownListItem>
          <AnalyticsDropdownListItem href="https://www.coingecko.com/en/coins/l3usd/" target="_blank" rel="noreferrer">
            <AnalyticsDropdownImage src={L3usdIcon} />
            <AnalyticsDropdownListItemCol>
              <AnalyticsDropdownListItemTitle>L3USD CoinGecko</AnalyticsDropdownListItemTitle>
              <AnalyticsDropdownListItemText>View L3USD on CoinGecko.</AnalyticsDropdownListItemText>
            </AnalyticsDropdownListItemCol>
          </AnalyticsDropdownListItem>
        </AnalyticsDropdownList>
      </AnalyticsDropdownCol>
    </AnalyticsDropdownBox>
  )
}
