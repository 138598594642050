import React from 'react'

export const TelegramIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 -1 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.06288 11.3861L6.76513 15.5741C7.19113 15.5741 7.37563 15.3911 7.59688 15.1713L9.59413 13.2626L13.7326 16.2933C14.4916 16.7163 15.0264 16.4936 15.2311 15.5951L17.9476 2.86605L17.9484 2.8653C18.1891 1.7433 17.5426 1.30455 16.8031 1.5798L0.835633 7.69305C-0.254117 8.11605 -0.237617 8.72355 0.650383 8.9988L4.73263 10.2686L14.2149 4.3353C14.6611 4.0398 15.0669 4.2033 14.7331 4.4988L7.06288 11.3861Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  )
}
