import { getAddress } from '@ethersproject/address'
import Big from 'big.js'
import { useChainId } from 'hooks'
import { useGetDexTokensQuery } from 'pages/Portfolio/PortfolioWallet/hooks/useGetDexTokensQuery'
import { NATIVE_CURRENCY } from 'sdk'
import { useGetDebankBalancesQuery } from '../queries/useGetDebankBalancesQuery'
import { useGetWalletTokensBalancesQuery } from '../queries/useGetWalletTokensBalancesQuery'
import { useCurrentChainDebankid } from '../useCurrentChainDebankId'

export const useWalletTokens = () => {
  const chainId = useChainId()
  const getDexTokensQuery = useGetDexTokensQuery()
  const dexTokens = getDexTokensQuery.data ? getDexTokensQuery.data.tokens : []
  const getWalletTokenBalancesQuery = useGetWalletTokensBalancesQuery(dexTokens)
  const currentChainDebankId = useCurrentChainDebankid()
  const debankBalances = useGetDebankBalancesQuery()
  const ethPriceUsd = getDexTokensQuery.data ? Big(getDexTokensQuery.data.ethPrice) : Big(0)
  const nativeTokenSymbol: string = NATIVE_CURRENCY[chainId].symbol

  const transformedDebankBalances = Array.isArray(debankBalances.data)
    ? debankBalances.data.map((balance) => {
        const isNativeCurrency = balance.id === currentChainDebankId
        const usdBalance = Big(balance.price).mul(balance.amount)
        return {
          usdPrice: Big(balance.price),
          userUsdBalance: usdBalance,
          userBalance: Big(balance.amount),
          symbol: balance.symbol,
          address: isNativeCurrency ? nativeTokenSymbol : getAddress(balance.id),
          logo: balance.logo_url,
          isInternal: false,
        }
      })
    : []
  const walletTokens = dexTokens
    .map((token) => {
      const tokenPriceInfo = token.derivedETH

      const tokenUsdPrice = ethPriceUsd && tokenPriceInfo ? ethPriceUsd.mul(tokenPriceInfo) : Big(0)

      const tokenBalanceInfo = getWalletTokenBalancesQuery.data
        ? getWalletTokenBalancesQuery.data[token.address]
        : Big(0)

      const userUsdBalance = tokenUsdPrice && tokenBalanceInfo ? tokenUsdPrice.mul(tokenBalanceInfo) : Big(0)

      return {
        usdPrice: tokenUsdPrice,
        userUsdBalance: userUsdBalance,
        userBalance: tokenBalanceInfo,
        symbol: token.symbol,
        address: token.address,
        logo: token.logo,
        isInternal: true,
      }
    })
    .filter((info) => info.userBalance.gt(0))

  const allBalances = [...transformedDebankBalances, ...walletTokens]
  const uniqueBalances = [...new Map(allBalances.map((item) => [item.address, item])).values()]
  return {
    data: uniqueBalances,
    isLoading: getDexTokensQuery.isLoading || getWalletTokenBalancesQuery.isLoading,
  }
}
