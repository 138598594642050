import { Currency } from '@uniswap/sdk-core'
import { Text } from 'rebass'
import styled from 'styled-components'

const StyledToggle = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;

  border-radius: 5px;
  background: ${({ theme }) => theme.componentBg5};
  cursor: pointer;

  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryText1};

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    flex-shrink: 0;
    white-space: nowrap;
    color: #859bb1;

    font-size: 13px;
    font-weight: 300;
  }

  & > div:first-child {
    background: ${({ active, theme }) => (active ? theme.white : 'transprarent')};
    color: ${({ active, theme }) => (active ? theme.text1 : 'transprarent')};
    font-weight: ${({ active }) => (active ? 700 : 300)};
  }

  & > div:last-child {
    background: ${({ active, theme }) => (!active ? theme.white : 'transprarent')};
    color: ${({ active, theme }) => (!active ? theme.text1 : 'transprarent')};
    font-weight: ${({ active }) => (!active ? 700 : 300)};
  }
`

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency
export default function RateToggle({
  currencyA,
  currencyB,
  handleRateToggle,
}: {
  currencyA: Currency
  currencyB: Currency
  handleRateToggle: () => void
}) {
  const tokenA = currencyA?.wrapped
  const tokenB = currencyB?.wrapped

  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  return tokenA && tokenB ? (
    <>
      <StyledToggle active={isSorted} onClick={handleRateToggle}>
        <Text>{currencyA.symbol}</Text>
        <Text>{currencyB.symbol}</Text>
      </StyledToggle>
    </>
  ) : null
}
