import React, { useEffect, useMemo, useState } from 'react'
import { useIsExpertMode } from 'state/user/hooks'
import { ClassicLayout } from './ClassicLayout'
import { ProLayout } from './ProLayout'
import { useSwapState } from 'state/swap/hooks'
import { setSelectedSwapType } from 'state/swap/actions'
import { useDispatch } from 'react-redux'
import { SwapType } from 'state/swap/reducer'
import { useMount, useSearchParam } from 'react-use'
import { useLocation, useHistory } from 'react-router'
import qs from 'qs'

export const TradeLayouts: React.FC = () => {
  // const { selectedSwapType } = useSwapState()
  // const isExpertMode = useIsExpertMode()
  const isExpertMode = false
  const dispatch = useDispatch()
  // const location = useLocation()
  // const history = useHistory()
  // const tabSearchParam = useSearchParam('swapType')

  // const setSelectedType = (type: SwapType) => {
  //   dispatch(setSelectedSwapType({ type }))
  // }

  // const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  // useEffect(() => {
  //   if (selectedSwapType === 'Aggregator' && !tabSearchParam) {
  //     searchParams.delete('swapType')
  //     history.replace({ search: searchParams.toString() })

  //     return
  //   }

  //   if (tabSearchParam && !selectedSwapType) {
  //     setSelectedType(tabSearchParam as SwapType)
  //   } else {
  //     searchParams.set('swapType', selectedSwapType as string)
  //     history.replace({ search: searchParams.toString() })
  //   }
  // }, [selectedSwapType])

  return isExpertMode ? <ProLayout /> : <ClassicLayout />
}
