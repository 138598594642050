import { V3_SUBGRAPH_URL } from 'constants/v3/urls'
import request, { gql } from 'graphql-request'
import { useChainId } from 'hooks'
import { useQuery } from 'react-query'
import { PoolByIdResponse } from 'types/v3'

const query = (poolId: string) => gql`
query {
    pools(where: {id: "${poolId}"}) {
      collectedFeesToken0
      collectedFeesToken1
      collectedFeesUSD
      createdAtBlockNumber
      createdAtTimestamp
      feeGrowthGlobal0X128
      liquidity
      liquidityProviderCount
      observationIndex
      sqrtPrice
      tick
      token0Price
      token1Price
      totalValueLockedETH
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      txCount
      untrackedVolumeUSD
      volumeToken0
      volumeToken1
      volumeUSD
      feeGrowthGlobal1X128
      feeTier
      feesUSD
      id
      ticks {
        collectedFeesToken0
        collectedFeesToken1
        collectedFeesUSD
        createdAtBlockNumber
        createdAtTimestamp
        feeGrowthOutside0X128
        feeGrowthOutside1X128
        feesUSD
        id
        liquidityGross
        liquidityNet
        liquidityProviderCount
        poolAddress
        price0
        price1
        tickIdx
        untrackedVolumeUSD
        volumeToken0
        volumeToken1
        volumeUSD
      }
      token0 {
        feesUSD
        derivedETH
        decimals
        id
        name
        poolCount
        symbol
        totalSupply
        totalValueLocked
        totalValueLockedUSD
        totalValueLockedUSDUntracked
        txCount
        untrackedVolumeUSD
        volume
        volumeUSD
      }
      token1 {
        decimals
        derivedETH
        feesUSD
        id
        poolCount
        name
        symbol
        totalSupply
        totalValueLockedUSD
        totalValueLocked
        txCount
        totalValueLockedUSDUntracked
        untrackedVolumeUSD
        volume
        volumeUSD
      }
    }
  }
`

export const usePoolById = (poolId: string) => {
  const chainId = useChainId()

  return useQuery(
    ['v3', 'pool-by-id', poolId],
    async () => {
      const res = await request<{ pools: PoolByIdResponse[] }>(V3_SUBGRAPH_URL[chainId], query(poolId))
      if (res.pools.length > 0) {
        return res.pools[0]
      } else {
        return
      }
    },
    {
      enabled: poolId.length > 0,
    }
  )
}
