import { Currency, CurrencyAmount, JSBI, NATIVE_CURRENCY, TokenAmount } from 'sdk'
import { MIN_ETH } from '../constants'
import { wrappedCurrency } from './wrappedCurrency'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function halfAmountSpend(chainId: number, currencyAmount?: CurrencyAmount | null): TokenAmount | undefined {
  if (!currencyAmount) return undefined

  const dividedAmount = JSBI.divide(currencyAmount.raw, JSBI.BigInt(2))

  const token = currencyAmount ? wrappedCurrency(currencyAmount.currency, chainId) : undefined

  if (currencyAmount.currency === NATIVE_CURRENCY[chainId] && token) {
    if (JSBI.greaterThan(dividedAmount, MIN_ETH[chainId])) {
      return new TokenAmount(token, JSBI.subtract(dividedAmount, MIN_ETH[chainId]))
    } else {
      return new TokenAmount(token, JSBI.BigInt(0))
    }
  }

  return token ? new TokenAmount(token, dividedAmount) : undefined
}
