import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Box } from 'rebass'
import {
  FaqAnswerBox,
  FaqBox,
  FaqHeader,
  FaqHeaderBox,
  FaqHeading,
  FaqItem,
  FaqList,
  FaqMain,
  FaqQuestion,
  FaqWrap,
} from './styles'
import { ReactComponent as FaqIcon } from '../../assets/images/faq-icon.svg'

const ToggleItem = ({ question, children }: { question: string; children: JSX.Element | JSX.Element[] }) => {
  const [toggleThisElement, setToggleThisElement] = useState(false)
  return (
    <FaqItem isOpen={toggleThisElement}>
      <FaqQuestion onClick={() => setToggleThisElement((prev) => !prev)}>{question}</FaqQuestion>
      {toggleThisElement && <FaqAnswerBox>{children}</FaqAnswerBox>}
    </FaqItem>
  )
}

export default function Faq() {
  return (
    <FaqBox>
      <Helmet>
        <title>FAQ | LIF3</title>
      </Helmet>

      <FaqWrap maxWidth="926px" width="100%">
        <FaqHeader>
          <FaqHeaderBox>
            <FaqHeading>
              <FaqIcon width="25px" height="25px" />
            </FaqHeading>{' '}
            Frequently Asked Questions
          </FaqHeaderBox>
        </FaqHeader>
        <FaqMain>
          <FaqList>
            <ToggleItem question={'What do I need to deposit tokens?'}>
              <>
                Download and install
                <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                  MetaMask
                </a>
                in your Chrome, Firefox or Brave browser.
              </>
            </ToggleItem>
            <ToggleItem question={'How can I get stablecoins into Fantom?'}>
              <>
                Option 1: You can bring your stablecoins to Fantom through
                <a
                  href="https://support.felix.com/hc/en-us/articles/5053228865295-Trade-Fantom-native-Stablecoins-on-Felix-now-"
                  target="_blank"
                  rel="noreferrer"
                >
                  Felix Exchange
                </a>
                <br />
                <br />
                Option 2: You can bridge your stablecoins using
                <a href="https://multichain.org/" target="_blank" rel="noreferrer">
                  Multichain
                </a>
                from other chains.
                <br />
                <br />
                Option 3: You can bridge your Crypto assets to Fantom using
                <a href="https://multichain.org/" target="_blank" rel="noreferrer">
                  Multichain
                </a>
                and then head to
                <a href="https://lif3.com/swap/" target="_blank" rel="noreferrer">
                  Lif3 Swap
                </a>
                and trade them for stablecoins.
                <br />
                <br />
                Option 4: You can use{' '}
                <a href="https://curve.exchange/" target="_blank" rel="noreferrer">
                  Curve Exchange
                </a>
                to trade between different stablecoins to get $USDC or $FUSDT.
              </>
            </ToggleItem>
            <ToggleItem question={'How can I earn?'}>
              <>
                This website makes the process of staking and earning on DeFi much easier than going in by yourself in
                the traditional manner.
                <br />
                <br />
                Once you have your stablecoins in your metamask wallet on the Fantom network just head to the front page
                of
                <a href="/" target="_blank" rel="noreferrer">
                  Lif3.com
                </a>{' '}
                and follow the steps on the section - Stake your stablecoins and earn rewards.
                <br />
                <br />
                Step 1: This step will require you to select which stablecoin you want to deposit.
                <br />
                <br />
                Step 2: This step will ask you to confirm a transaction to approve the stablecoin to be used and then
                you can select the amount you want to deposit. Once you inserted the amount you want to deposit to earn,
                press Deposit and confirm another transaction on your wallet.
                <br />
                <br />
                Step 3: Now its necessary that you press Click to Start Earning and confirm the final transaction on
                your wallet.
                <br />
                <br />
                That is all you have to do!
                <br />
                <br />
                After following the steps you will end up in the Earn tab of the website where you can see your yield
                earned thanks to your stablecoins farming.
              </>
            </ToggleItem>
            <ToggleItem question={'What can I do with my yield earned?'}>
              <>
                You can head to{' '}
                <a href="https://lif3.com/swap/" target="_blank" rel="noreferrer">
                  Lif3 Swap
                </a>{' '}
                and trade it for other tokens or more stablecoins.
                <br />
                <br />
                Those stables can then be deposited again on this website and you can compound your farming experience.
              </>
            </ToggleItem>
            <ToggleItem question={'What happens to my tokens after I deposit it?'}>
              <>
                In step 2, Your tokens get deposited into the following curve pools.
                <br />
                <br />
                USDC and FUSDT get deposited to{' '}
                <a href="https://ftm.curve.fi/factory/115" target="_blank" rel="noreferrer">
                  Curve Pool 115
                </a>
                <br />
                <br />
                Once the tokens are deposited, you receive a receipt for your deposit.
                <br />
                <br />
                In Step 3, your receipt is deposited into the Tomb.com Cemetery to earn LSHARE rewards.
              </>
            </ToggleItem>
            <ToggleItem question={'Where does the yield come from?'}>
              <>
                Buy depositing tokens in these pools, you are providing liquidity for users who want to swap between
                L3USD, USDC and FUSDT.
                <br />
                <br />
                For providing this liquidity, the protocol rewards you on top of earning swap fees.
              </>
            </ToggleItem>
            <ToggleItem question={'Where can I go to see more staking/earning opportunities?'}>
              <>
                You can visit
                <a href="https://lif3.com/garden" target="_blank" rel="noreferrer">
                  Lif3.com
                </a>
              </>
            </ToggleItem>
            <ToggleItem question={'What blockchain is L3USD and Tomb.com built on?'}>
              <>
                L3USD and Tomb.com is built on the
                <a href="https://fantom.foundation/" target="_blank" rel="noreferrer">
                  Fantom Opera
                </a>
                blockchain.
              </>
            </ToggleItem>
            <ToggleItem question={'Where can I learn about more yield strategies?'}>
              <>
                You can explore the following links to learn about how you can use your yield or ask questions about the
                Lif3 protocol:
                <br />
                <br />
                <a href="https://lif3.com/" target="_blank" rel="noreferrer">
                  Lif3.com
                </a>
                <br />
                <a href="https://twitter.com/Official_LIF3" target="_blank" rel="noreferrer">
                  Lif3 Twitter
                </a>
                <br />
                <a href="https://discord.gg/lif3" target="_blank" rel="noreferrer">
                  Discord
                </a>
                <br />
                <a href="https://t.me/Lif3_Official" target="_blank" rel="noreferrer">
                  Telegram
                </a>
                <br />
                <a href="https://www.instagram.com/lif3official/" target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </>
            </ToggleItem>
          </FaqList>
        </FaqMain>
      </FaqWrap>
    </FaqBox>
  )
}
