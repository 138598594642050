import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useChainId } from '../../hooks'
import Web3Status from '../Web3Status'
import { Box } from 'shared'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Logo } from 'icons/Logo'
import { SwapIcon } from 'icons/SwapIcon'
import { BridgeIcon } from 'icons/BridgeIcon'
import { LendIcon } from 'icons/LendIcon'
import { useTokensInfoQuery } from 'hooks/queries/useTokensInfoQuery'
import { ChainId } from 'sdk/constants'
import { FaucetIcon } from 'icons/Faucet'
import {
  HeaderFrame,
  Title,
  LogoDesktop,
  HeaderLinks,
  StLink,
  StLinkIcon,
  StLinkText,
  StyledDropBtn,
  StyledDropIcon,
  HeaderSide,
  CarouselWrapperDesktop,
  LogoMobile,
  HeaderMobileLink,
  HeaderMobileRow,
  GlobalSettignsBtn,
  CommunityWrapper,
  EarnWrapper,
  BuyWrapper,
  EcosWrapper,
} from './styles'
import { ChainToggle } from 'components/ChainToggle'
import { TokensCarousel } from './components/TokensCarousel'
import { useListedTokens } from 'hooks/Tokens'
import {
  CHAIND_IDS_WITHOUT_SUITE,
  sliderTokens,
  CHAIN_IDS_WITHOUT_EARN,
  SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES,
} from './constants'
import { isNetworkSupported } from 'utils'
import { useMediaWith } from 'theme'
import { NATIVE_CURRENCY, Token, WNATIVE } from 'sdk'
import { useTranslation } from 'react-i18next'
import { GlobalSettingsIcon } from 'icons/GlobalSettingsIcon'
import { GlobalSettingsPanel } from 'components/GlobalSettingsPanel'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'
import { ConvertIcon } from 'icons/ConvertIcon'
import { CommunityDropdown } from './components/CommunityDropdown'
import { TradeDropdown } from './components/TradeDropdown'
import { EarnDropdown } from './components/EarnDropdown'
import { BuyDropdown } from './components/BuyDropdown'
import { CommunityIcon } from 'icons/CommunityIcon'
import { ReactComponent as EarnIcon } from 'assets/svg/earn-icon.svg'
import { ReactComponent as Lif3LogoShortSmallIcon } from 'assets/svg/lif3-logo-short-small.svg'
import { EcosystemDropdown } from './components/EcosystemDropdown'

export const Header: React.FC = () => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const { upToMedium } = useMediaWith()
  const [isMoreMenuOpen, toggleMoreMenu] = useState(false)
  const [isGlobalSettingsOpen, toggleGlobalSettings] = useState(false)
  const [isLiquidityMenuOpen, toggleLiquidityMenu] = useState(false)
  const [isEarnMenuOpen, toggleEarnMenu] = useState(false)
  const [isBuyMenuOpen, toggleBuyMenu] = useState(false)
  const [isTradeMenuOpen, toggleTradeMenu] = useState(false)
  const [isLendMenuOpen, toggleLendMenu] = useState(false)
  const [isStatsMenuOpen, toggleStatsMenu] = useState(false)
  const [isOtherMenuOpen, toggleOtherMenu] = useState(false)
  const [isCommMenuOpen, toggleCommMenu] = useState(false)
  const [isEcosMenuOpen, toggleEcosMenu] = useState(false)
  const chainId = useChainId()
  const { t } = useTranslation('navbar')
  const searchParams = new URLSearchParams(location.search)
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  const setSelectedChainId = (chainId: ChainId) => {
    if (!isNetworkSupported(chainId)) {
      return
    }

    if (pathname !== '/') {
      searchParams.set('chainId', String(chainId))

      history.push({
        search: searchParams.toString(),
      })
    }
  }

  useEffect(() => {
    setSelectedChainId(chainId)
  }, [chainId])

  const menuRef = useRef<HTMLDivElement | null>(null)
  const otherRef = useRef<HTMLDivElement | null>(null)
  const liqRef = useRef<HTMLDivElement | null>(null)
  const tradeRef = useRef<HTMLDivElement | null>(null)
  const lendRef = useRef<HTMLDivElement | null>(null)
  const statsRef = useRef<HTMLDivElement | null>(null)
  const globalSettingsRef = useRef<HTMLDivElement | null>(null)
  // const commRef = useRef<HTMLDivElement | null>(null)

  //show prices in header
  const nativeToken = WNATIVE[chainId]
  const nativeCurrency = NATIVE_CURRENCY[chainId]
  const tokensToShow = ['LIF3', 'LSHARE', nativeCurrency?.symbol]
  const tokensInfoQuery = useTokensInfoQuery()
  const pricesCG = useCGTokenPrices({ tokenSymbols: tokensToShow, iniciator: 'header' })
  const listedTokens = useListedTokens('Market')

  const sliderTokensInfo = useMemo(() => {
    if (!tokensInfoQuery.data) {
      return
    }

    const listedTokensValues =
      chainId === ChainId.ETHEREUM || chainId === ChainId.ARBITRUM || chainId === ChainId.OPTIMISM
        ? [
            {
              address: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
              chainId: ChainId.BSC,
              name: 'LSHARE',
              symbol: 'LSHARE',
              decimals: 18,
              logo: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
            },
            {
              address: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
              chainId: ChainId.BSC,
              name: 'LIF3',
              symbol: 'LIF3',
              decimals: 18,
              logo: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
            },
            {
              address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
              chainId: ChainId.BSC,
              name: 'Ethereum',
              symbol: 'ETH',
              decimals: 18,
              logo: 'https://assets.lif3.com/swap/tokens/ETH.svg',
            },
          ]
        : Object.values(listedTokens)

    const indexMap = {}
    for (let i = 0; i < tokensToShow.length; i++) {
      indexMap[tokensToShow[i]] = i
    }

    const sliderTokens = listedTokensValues
      .reduce<
        Array<
          {
            address: string
            totalLiquidityUSD: number
            txCount: number
            USDPrice: number
            totalTradeVolumeUSD: number
            CSPrice: number
          } & Token
        >
      >((acc, token) => {
        const isNativeCurrency = token?.symbol === nativeToken?.symbol

        if (tokensToShow.includes(isNativeCurrency ? nativeCurrency?.symbol : token?.symbol)) {
          acc.push({
            ...(tokensInfoQuery?.data ? tokensInfoQuery?.data[token?.address] : []),
            ...token,
            equals: function (other: Token): boolean {
              throw new Error('Function not implemented.')
            },
            sortsBefore: function (other: Token): boolean {
              throw new Error('Function not implemented.')
            },
            logoURI: undefined,
            getSymbol: function (): string | undefined {
              throw new Error('Function not implemented.')
            },
            getName: function (): string | undefined {
              throw new Error('Function not implemented.')
            },
            logo: isNativeCurrency ? `https://assets.lif3.com/swap/tokens/${nativeCurrency?.symbol}.svg` : token?.logo,
            symbol: isNativeCurrency ? nativeCurrency?.symbol : token?.symbol,
            name: isNativeCurrency ? nativeCurrency?.name : token?.name,
            decimals: isNativeCurrency ? nativeCurrency?.decimals : token?.decimals,
            CSPrice: pricesCG.data?.[isNativeCurrency ? nativeCurrency?.symbol : token?.symbol]?.usd,
          })
        }

        return acc
      }, [])
      .sort((a, b) => indexMap[a?.symbol ?? ''] - indexMap[b?.symbol ?? ''])

    return sliderTokens
  }, [listedTokens, sliderTokens, tokensInfoQuery.data, pricesCG.data, chainId])

  useOnClickOutside(menuRef, isMoreMenuOpen ? () => toggleMoreMenu(!isMoreMenuOpen) : undefined)
  useOnClickOutside(liqRef, isLiquidityMenuOpen ? () => toggleLiquidityMenu(!isLiquidityMenuOpen) : undefined)
  useOnClickOutside(tradeRef, isTradeMenuOpen ? () => toggleTradeMenu(!isTradeMenuOpen) : undefined)
  useOnClickOutside(lendRef, isLendMenuOpen ? () => toggleLendMenu(!isLendMenuOpen) : undefined)
  useOnClickOutside(statsRef, isStatsMenuOpen ? () => toggleStatsMenu(!isStatsMenuOpen) : undefined)
  useOnClickOutside(otherRef, isOtherMenuOpen ? () => toggleOtherMenu(!isOtherMenuOpen) : undefined)
  useOnClickOutside(
    globalSettingsRef,
    isGlobalSettingsOpen ? () => toggleGlobalSettings(!isGlobalSettingsOpen) : undefined
  )

  const isSwapPages = pathname.startsWith('/swap') || pathname.startsWith('/token')
  const isBridgePage = pathname.startsWith('/bridge')
  const isEarnPages =
    pathname.startsWith('/garden') ||
    pathname.startsWith('/add') ||
    pathname.startsWith('/staking') ||
    pathname.startsWith('/fountain') ||
    pathname.startsWith('/nursery') ||
    pathname.startsWith('/terrace')
  const isBuyPages = pathname.startsWith('/how-to-buy') || pathname.startsWith('/buy-crypto')
  let isEcosPages = false
  const isFaucetPage = pathname.startsWith('/chain/testnet/faucet')
  const isConvertPage = pathname.startsWith('/convert')

  if (!isFaucetPage) {
    isEcosPages =
      pathname.startsWith('/wallet') ||
      pathname.startsWith('/chain') ||
      pathname.startsWith('/l3usd') ||
      pathname.startsWith('/ai') ||
      pathname.startsWith('/events')
  }

  const marketSearchParams = useMemo(
    () => new URLSearchParams({ chainId: String(chainId), swapType: 'Market' }),
    [chainId]
  )

  const chainsWithoutEarn = !CHAIN_IDS_WITHOUT_EARN.includes(chainId)
  const chainsWithConvert = SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES.includes(chainId)

  return (
    <HeaderFrame>
      <Title>
        <Link style={{ textDecoration: 'none' }} to="/">
          <LogoDesktop>
            <Logo />
          </LogoDesktop>
        </Link>
      </Title>
      {!upToMedium && (
        <HeaderLinks>
          {chainId === ChainId.LIF3CHAIN_TESTNET ? (
            <StLink $isActive={isSwapPages} to={{ pathname: '/swap', search: marketSearchParams.toString() }}>
              <StLinkIcon>
                <SwapIcon />
              </StLinkIcon>
              <StLinkText>{t('swap')}</StLinkText>
            </StLink>
          ) : (
            // <TradeWrapper>
            //   <StyledDropBtn $isActive={isSwapPages} onClick={() => toggleTradeMenu(!isTradeMenuOpen)}>
            //     <StLinkIcon>
            //       <TradeIcon />
            //     </StLinkIcon>
            //     <StLinkText>{t('trade')}</StLinkText>
            //     <StyledDropIcon selected={isTradeMenuOpen} />
            //   </StyledDropBtn>
            //   <TradeDropdown />
            // </TradeWrapper>

            <StLink $isActive={isSwapPages} to={{ pathname: '/swap', search: chainIdOnlysearchParams.toString() }}>
              <StLinkIcon>
                <SwapIcon />
              </StLinkIcon>
              <StLinkText>{t('swap')}</StLinkText>
            </StLink>
          )}
          {chainId !== ChainId.LIF3CHAIN_TESTNET && (
            <BuyWrapper>
              <StyledDropBtn
                $isActive={isBuyPages}
                onMouseEnter={() => toggleBuyMenu(true)}
                onMouseLeave={() => toggleBuyMenu(false)}
              >
                <StLinkIcon>
                  <LendIcon />
                </StLinkIcon>
                <StLinkText>{t('buy')}</StLinkText>
                <StyledDropIcon selected={isBuyMenuOpen} />
              </StyledDropBtn>
              <BuyDropdown toggleIcon={toggleBuyMenu} />
            </BuyWrapper>
          )}
          {chainsWithoutEarn && (
            <EarnWrapper>
              <StyledDropBtn
                $isActive={isEarnPages}
                onMouseEnter={() => toggleEarnMenu(true)}
                onMouseLeave={() => toggleEarnMenu(false)}
              >
                <StLinkIcon>
                  <EarnIcon />
                </StLinkIcon>
                <StLinkText>{t('staking')}</StLinkText>
                <StyledDropIcon selected={isEarnMenuOpen} />
              </StyledDropBtn>
              <EarnDropdown toggleIcon={toggleEarnMenu} />
            </EarnWrapper>
          )}
          {chainId == ChainId.LIF3CHAIN_TESTNET && (
            <StLink
              $isActive={isFaucetPage}
              to={{ pathname: '/chain/testnet/faucet', search: chainIdOnlysearchParams.toString() }}
            >
              <StLinkIcon>
                <FaucetIcon />
              </StLinkIcon>
              <StLinkText>{t('faucet')}</StLinkText>
            </StLink>
          )}
          {chainId !== ChainId.LIF3CHAIN_TESTNET && (
            <StLink $isActive={isBridgePage} to={{ pathname: '/bridge', search: chainIdOnlysearchParams.toString() }}>
              <StLinkIcon>
                <BridgeIcon />
              </StLinkIcon>
              <StLinkText>{t('bridge')}</StLinkText>
            </StLink>
          )}
          <CommunityWrapper>
            <StyledDropBtn
              $isActive={isCommMenuOpen}
              onMouseEnter={() => toggleCommMenu(true)}
              onMouseLeave={() => toggleCommMenu(false)}
            >
              <StLinkIcon>
                <CommunityIcon />
              </StLinkIcon>
              <StLinkText>{t('common:community')}</StLinkText>
              <StyledDropIcon selected={isCommMenuOpen} />
            </StyledDropBtn>
            <CommunityDropdown toggleIcon={toggleCommMenu} />
          </CommunityWrapper>
          <EcosWrapper>
            <StyledDropBtn
              $isActive={isEcosPages}
              onMouseEnter={() => toggleEcosMenu(true)}
              onMouseLeave={() => toggleEcosMenu(false)}
            >
              <StLinkIcon>
                <Lif3LogoShortSmallIcon />
              </StLinkIcon>
              <StLinkText>{t('ecosystem')}</StLinkText>
              <StyledDropIcon selected={isEcosMenuOpen} />
            </StyledDropBtn>
            <EcosystemDropdown toggleIcon={toggleEcosMenu} />
          </EcosWrapper>
          {/*{chainsWithConvert && (
            <StLink isActive={isConvertPage} to={{ pathname: '/convert', search: chainIdOnlysearchParams.toString() }}>
              <StLinkIcon>
                <ConvertIcon />
              </StLinkIcon>
              <StLinkText>{t('convert')}</StLinkText>
            </StLink>
          )} */}
        </HeaderLinks>
      )}
      <HeaderMobileRow>
        <HeaderMobileLink to="/">
          <LogoMobile>
            <Logo />
          </LogoMobile>
        </HeaderMobileLink>
      </HeaderMobileRow>
      <HeaderSide>
        <CarouselWrapperDesktop>
          <TokensCarousel tokens={sliderTokensInfo} />
        </CarouselWrapperDesktop>

        <Web3Status selectedChainId={chainId} />
        <ChainToggle selectedChainId={chainId} setSelectedChainId={setSelectedChainId} />
        <Box display="flex" alignItems="center" ref={globalSettingsRef}>
          <GlobalSettignsBtn onClick={() => toggleGlobalSettings(!isGlobalSettingsOpen)}>
            <GlobalSettingsIcon />
          </GlobalSettignsBtn>

          {isGlobalSettingsOpen && (
            <GlobalSettingsPanel isOpen={isGlobalSettingsOpen} setIsOpen={toggleGlobalSettings} />
          )}
        </Box>
      </HeaderSide>
    </HeaderFrame>
  )
}
