/**
 * Add token to wallet
 * @returns {boolean} true if token added succesfully, false if provider returns error
 */

interface Token {
  address: string
  symbol: string
  decimals: number
  logo: string
}

export const addTokenToWallet = async ({ address, logo, symbol, decimals }: Token) => {
  const provider = window.ethereum as any

  const symbolToAdd = symbol.split(' ')?.[1] === '(Old)' ? symbol.split(' ')?.[0] : symbol

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol: symbolToAdd,
            decimals,
            image: logo,
          },
        },
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't add token to wallet because window.ethereum is undefined")
    return false
  }
}
