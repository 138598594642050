import { Box } from 'shared'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Tooltip from '../Tooltip'
import { QuestionIcon } from './QuestionIcon'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;

  :hover,
  :focus {
    opacity: 0.8;
  }
`

export default function QuestionHelper({
  text,
  noMargin,
  color,
}: {
  text: React.ReactNode
  noMargin?: boolean
  color?: string
}) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <Box ml={noMargin ? 0 : '4px'} display="inline-flex" alignItems="center">
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <QuestionIcon fill={color} />
        </QuestionWrapper>
      </Tooltip>
    </Box>
  )
}
