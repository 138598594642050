import { Pair, Token } from 'sdk'
import { useQuery } from 'react-query'
import UniswapV2PairUpdatedAbi from '../../constants/abis/UniswapV2PairUpdatedAbi.json'

import { multicallFailSafe } from 'shared'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import chunkArray from 'utils/chunkArray'
import { useChainId } from 'hooks'

export const usePairsReservesQuery = (
  tokensMap: Record<
    string,
    {
      tokenA: Token
      tokenB: Token
    }
  >
) => {
  const chainId = useChainId()

  const tokens = Object.values(tokensMap).reduce<Array<[Token, Token]>>((acc, { tokenA, tokenB }) => {
    const address = tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB, chainId) : undefined

    if (address && tokenA && tokenB) {
      acc.push([tokenA, tokenB])
    }

    return acc
  }, [])

  const pairAddresses = tokens.reduce<Record<string, { tokenA: Token | undefined; tokenB: Token | undefined }>>(
    (acc, [tokenA, tokenB]) => {
      const address = tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB, chainId) : undefined

      if (address) {
        acc[address] = { tokenA, tokenB }
      }

      return acc
    },
    {}
  )

  return useQuery(
    ['pairs-reserves', ...Object.keys(pairAddresses), chainId],
    async () => {
      const requests = Object.keys(pairAddresses).map(async (pairAddress) => {
        return pairAddress
      })

      const addressesWithLiquidity = (
        (await Promise.all(requests).catch((errors) => console.log('something wrong', errors))) ?? []
      ).filter((pairAddress) => Boolean(pairAddress)) as Array<string>

      const calls = addressesWithLiquidity
        .map((pairAddress) => [
          {
            address: pairAddress as string,
            name: 'getReserves',
          },
          {
            address: pairAddress as string,
            name: 'totalSupply',
          },
        ])
        .flat()

      const response = await multicallFailSafe(
        UniswapV2PairUpdatedAbi,
        calls,
        PROVIDERS_BY_CHAIN[chainId] as any,
        chainId
      )

      const chunkedResponse = chunkArray(response, 2)

      const result = chunkedResponse
        .map((item: any, index) => {
          const [_reserve0, _reserve1] = item[0] as any

          const totalSupply = item[1][0]

          return _reserve0 && _reserve1 && totalSupply
            ? { reserve0: _reserve0, reserve1: _reserve1, totalSupply, pairAddress: addressesWithLiquidity[index] }
            : null
        })
        .filter((reserve) => Boolean(reserve))

      return result
    },
    { enabled: Boolean(Object.keys(pairAddresses).length), refetchOnMount: false, refetchOnWindowFocus: true }
  )
}
