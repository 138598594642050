import useTheme from 'hooks/useTheme'
import React from 'react'

export const ArrowDownIcon: React.FC = () => {
  const theme = useTheme()

  return (
    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11L5 15L9 11" stroke={theme.primary1} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5 1V15" stroke={theme.primary1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
