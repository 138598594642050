import React, { useMemo } from 'react'
import { useChainId } from 'hooks'
import {
  BuyDropdownBox,
  BuyDropdownCol,
  BuyDropdownTitle,
  BuyDropdownList,
  BuyDropdownListItem,
  BuyDropdownListItemCol,
  BuyDropdownListItemTitle,
  BuyDropdownListItemText,
  ArrowRightStyledIcon,
  Lif3TokenStyledIcon,
  LshareTokenStyledIcon,
  L3usdTokenStyledIcon,
  CreditCardStyledIcon,
  BitmartStyledIcon,
  HowToBuyStyledIcon,
  BitfinexStyledIcon,
  MEXCStyledIcon,
} from './styles'
import { listedTokensList } from 'constants/aggregatorTokensList'
import { v3TokensList } from 'constants/v3/v3TokenList'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { CurrencyDirection } from 'enums/common'
import { Token } from 'sdk'

export const BuyDropdown = ({ toggleIcon }: { toggleIcon?: (state: boolean) => void }) => {
  const chainId = useChainId()
  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])
  const { onCurrencySelection } = useSwapActionHandlers()
  const lif3Address = Array.isArray(v3TokensList[chainId])
    ? v3TokensList[chainId].find((token) => token.symbol === 'LIF3')?.address ?? ''
    : ''

  const lshareAddress = Array.isArray(v3TokensList[chainId])
    ? v3TokensList[chainId].find((token) => token.symbol === 'LSHARE')?.address ?? ''
    : ''

  const l3usdAddress = listedTokensList[chainId].find((token) => token.symbol === 'L3USD')?.address ?? ''

  const buyLIF3SearchParams = new URLSearchParams({
    ...Object.fromEntries(chainIdOnlySearchParams),
    swapType: 'Aggregator',
  })
  buyLIF3SearchParams.set('outputCurrency', lif3Address)

  const buyLSHARESearchParams = new URLSearchParams({
    ...Object.fromEntries(chainIdOnlySearchParams),
    swapType: 'Aggregator',
  })
  buyLSHARESearchParams.set('outputCurrency', lshareAddress)

  const buyL3USDSearchParams = new URLSearchParams({
    ...Object.fromEntries(chainIdOnlySearchParams),
  })
  buyL3USDSearchParams.set('outputCurrency', l3usdAddress)

  const handleDispatchOutputCurrency = (symbol: string) => {
    const currency = listedTokensList[chainId].find((item) => item.symbol === symbol)
    const token = new Token({
      address: currency.address,
      decimals: currency.decimals,
      symbol: currency.symbol,
      name: currency.name,
      logo: currency.logoURI,
    })
    onCurrencySelection(CurrencyDirection.OUTPUT, token)
  }

  return (
    <BuyDropdownBox onMouseEnter={() => toggleIcon?.(true)} onMouseLeave={() => toggleIcon?.(false)}>
      <BuyDropdownCol>
        <BuyDropdownTitle>Buy</BuyDropdownTitle>
        <BuyDropdownList>
          {lif3Address && (
            <BuyDropdownListItem
              to={{
                pathname: '/swap',
                search: `${buyLIF3SearchParams.toString()}`,
              }}
              onClick={() => handleDispatchOutputCurrency('LIF3')}
            >
              <Lif3TokenStyledIcon />
              <BuyDropdownListItemCol>
                <BuyDropdownListItemTitle>Buy LIF3 on Lif3 Swap</BuyDropdownListItemTitle>
                <BuyDropdownListItemText>Buy/Trade Lif3 on Lif3 Swap.</BuyDropdownListItemText>
              </BuyDropdownListItemCol>
              <ArrowRightStyledIcon />
            </BuyDropdownListItem>
          )}
          {lshareAddress && (
            <BuyDropdownListItem
              to={{
                pathname: '/swap',
                search: `${buyLSHARESearchParams.toString()}`,
              }}
              onClick={() => handleDispatchOutputCurrency('LSHARE')}
            >
              <LshareTokenStyledIcon />
              <BuyDropdownListItemCol>
                <BuyDropdownListItemTitle>Buy LSHARE on Lif3 Swap</BuyDropdownListItemTitle>
                <BuyDropdownListItemText>Buy/Trade LSHARE on Lif3 Swap.</BuyDropdownListItemText>
              </BuyDropdownListItemCol>
              <ArrowRightStyledIcon />
            </BuyDropdownListItem>
          )}
          {l3usdAddress && (
            <BuyDropdownListItem
              to={{ pathname: 'https://polygon.curve.fi/factory/456' }}
              target="_blank"
              rel="noreferrer"
            >
              <L3usdTokenStyledIcon />
              <BuyDropdownListItemCol>
                <BuyDropdownListItemTitle>Buy L3USD on Curve</BuyDropdownListItemTitle>
                <BuyDropdownListItemText>Swap to L3USD using USDT, USDC or DAI.</BuyDropdownListItemText>
              </BuyDropdownListItemCol>
              <ArrowRightStyledIcon />
            </BuyDropdownListItem>
          )}
          <BuyDropdownListItem to={{ pathname: '/buy-crypto', search: chainIdOnlySearchParams.toString() }}>
            <CreditCardStyledIcon />
            <BuyDropdownListItemCol>
              <BuyDropdownListItemTitle>Buy Crypto</BuyDropdownListItemTitle>
              <BuyDropdownListItemText>
                Buy crypto using your Credit Card, Apple Pay or Google Pay.
              </BuyDropdownListItemText>
            </BuyDropdownListItemCol>
            <ArrowRightStyledIcon />
          </BuyDropdownListItem>
          <BuyDropdownListItem
            to={{ pathname: 'https://www.bitmart.com/trade/en-US?symbol=LIF3_USDT&layout=basic' }}
            target="_blank"
            rel="noreferrer"
          >
            <BitmartStyledIcon />
            <BuyDropdownListItemCol>
              <BuyDropdownListItemTitle>Buy LIF3 on Bitmart</BuyDropdownListItemTitle>
              <BuyDropdownListItemText>Buy and Trade $LIF3 on Bitmart.</BuyDropdownListItemText>
            </BuyDropdownListItemCol>
            <ArrowRightStyledIcon />
          </BuyDropdownListItem>
          <BuyDropdownListItem
            to={{ pathname: 'https://trading.bitfinex.com/t/LIFIII:USD?demo=true' }}
            target="_blank"
            rel="noreferrer"
          >
            <BitfinexStyledIcon />
            <BuyDropdownListItemCol>
              <BuyDropdownListItemTitle>Buy Lif3 on Bitfinex</BuyDropdownListItemTitle>
              <BuyDropdownListItemText>You can now Buy and Trade LIF3 on Bitfinex.</BuyDropdownListItemText>
            </BuyDropdownListItemCol>
            <ArrowRightStyledIcon />
          </BuyDropdownListItem>
          <BuyDropdownListItem
            to={{ pathname: 'https://www.mexc.com/exchange/LIF3_USDT' }}
            target="_blank"
            rel="noreferrer"
          >
            <MEXCStyledIcon />
            <BuyDropdownListItemCol>
              <BuyDropdownListItemTitle>Buy Lif3 on MEXC</BuyDropdownListItemTitle>
              <BuyDropdownListItemText>You can now Buy and Trade LIF3 on MEXC.</BuyDropdownListItemText>
            </BuyDropdownListItemCol>
            <ArrowRightStyledIcon />
          </BuyDropdownListItem>
          <BuyDropdownListItem to={{ pathname: '/how-to-buy', search: chainIdOnlySearchParams.toString() }}>
            <HowToBuyStyledIcon />
            <BuyDropdownListItemCol>
              <BuyDropdownListItemTitle>How to Buy $LIF3</BuyDropdownListItemTitle>
              <BuyDropdownListItemText>Which ever method works for you, we’ve got you covered!</BuyDropdownListItemText>
            </BuyDropdownListItemCol>
            <ArrowRightStyledIcon />
          </BuyDropdownListItem>
        </BuyDropdownList>
      </BuyDropdownCol>
    </BuyDropdownBox>
  )
}
