import { rgba } from 'polished'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SocialList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
`

export const Social = styled(Link)`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  border-radius: 50%;
  color: ${({ theme }) => theme.primaryText1};

  & > svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover {
    color: ${({ theme }) => theme.secondaryText1};
  }
`
