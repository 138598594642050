import { Currency } from 'sdk'
import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ sizeraw }) => `${sizeraw + sizeraw / 2}px`};
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

const HigherLogo = styled.div<{ size: string }>`
  z-index: 2;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`
const CoveredLogo = styled.div<{ size: string }>`
  transform: translateX(-50%);
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 16,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && (
        <HigherLogo size={size.toString() + 'px'}>
          <CurrencyLogo currency={currency0} size={size.toString() + 'px'} />
        </HigherLogo>
      )}
      {currency1 && (
        <CoveredLogo size={size.toString() + 'px'}>
          <CurrencyLogo currency={currency1} size={size.toString() + 'px'} sizeraw={size} />
        </CoveredLogo>
      )}
    </Wrapper>
  )
}
