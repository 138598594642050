import { useSimplexAuth } from 'pages/Trade/Swap/components/SimplexSection/hooks/useSimplexAuth'
import { useQuery } from 'react-query'
import { Currency } from 'sdk'
import { useApi, useLocalStorage } from 'shared'
import { useSelectedCurrencies, useSwapState } from 'state/swap/hooks'
import { useDebounce } from 'use-debounce'

export const useGetSimplexQuoteQuery = () => {
  const api = useApi()
  const { typedValue } = useSwapState()
  const { inputCurrency } = useSelectedCurrencies()
  const [authToken] = useLocalStorage<string>('simplex-auth-token')
  const { checkAuth } = useSimplexAuth()

  const [debouncedTypedValue] = useDebounce(typedValue, 300)

  return useQuery(
    ['get-simplex-quote', debouncedTypedValue],
    async () => {
      if (inputCurrency !== Currency.USD_CARD && !debouncedTypedValue) return

      try {
        const response = await api?.simplexApi.getQuote({
          amount: Number(debouncedTypedValue),
          authentication: authToken ?? '',
        })

        return response?.data
      } catch (error) {
        throw error
      }
    },
    {
      enabled: Boolean(debouncedTypedValue) && inputCurrency === Currency.USD_CARD,
      retry: false,
      onError: () => {
        checkAuth()
      },
    }
  )
}
