import { rgba } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 140px;
  color: ${({ theme }) => theme.primaryText1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 20px;
    margin-bottom: 76px;
  `};
`
export const WrapperInner = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: flex-start;
  column-gap: 30px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      padding: 0 10px;
  `};
`

export const FooterLink = styled.a`
  font-size: 14px;
  color: inherit;
  opacity: 0.5;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
`

export const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  grid-column-start: 5;
  grid-column-end: 13;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.primaryText1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-column: span 12;
    margin-bottom: 48px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 48px;
    margin-bottom: 48px;
  `};
`

export const FooterLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
`

export const FooterLinksLabel = styled.div`
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  line-height: 24px;
`

export const FooterSocialsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 30px;
  row-gap: 24px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  color: ${({ theme }) => rgba(theme.white, 0.4)};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-row-start: 2;
    grid-column: span 12;
    column-gap: 48px;
    margin-bottom: 24px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: center;
    gap: 30px;
  `};
`

export const FooterText = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 20px;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-row-start: 3;
    grid-column: span 12;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: center;
    text-align: center;
  `};
`

export const FooterTextItem = styled.p`
  display: inline-block;
  margin: 0;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-self: center;
  `}
`

export const FooterSocialItem = styled.a`
  font-size: 14px;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.white};
  }

  & > svg {
    object-fit: contain;
  }
`

export const CopyrightRow = styled.div`
  grid-row-start: 2;
  grid-column: span 12;
  width: calc(100% + 40px);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 30px;
  padding: 16px 20px 30px;
  align-items: center;
  margin: 0 -20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-row-start: 4;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-bottom: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-top: 1px solid ${({ theme }) => theme.border1};
    padding: 14px 10px;
    width: calc(100% + 20px);
    margin: 0 -10px;
  `};
`

export const FooterCopyrightText = styled.div`
  grid-column: span 6;
  color: inherit;
  font-size: 15px;
  margin-right: 12px;
  opacity: 0.5;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-column: span 12;
    margin: 0;
    text-align: center;
  `};
`

export const FooterAppsRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    justify-content: center;
  `};
`

export const FooterLinksItem = styled.a`
  width: 100%;
  max-width: 120px;
  display: block;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`

export const FooterLinksItemLogo = styled.img`
  max-width: 120px;
  width: 100%;
`

export const FooterAuditText = styled.div`
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  margin-right: 12px;
  transition: all 0.3s;
`

export const FooterAuditWrapper = styled.a`
  grid-column: -1;
  max-width: 238px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 7px 21px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => rgba(theme.white, 0.2)};
  white-space: nowrap;
  color: ${({ theme }) => rgba(theme.white, 0.5)};
  transition: border-color 0.3s;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 30px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
   margin-right: 0;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-column: -1 / -9;
    margin-left: auto;
    padding: 7px 16px;
  `};

  &:hover {
    border-color: ${({ theme }) => theme.primaryText1};
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover ${FooterAuditText} {
    opacity: 1;
  }
`
