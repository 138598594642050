import { Activity } from 'react-feather'
import styled from 'styled-components'
import { ReactComponent as DropDown } from 'assets/images/dropdown.svg'
import { ButtonPrimaryGradient } from 'components/Button'

export const Web3StatusGeneric = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  width: 100%;
  align-items: center;
  user-select: none;
  padding: 8px 16px;
  height: 35px;
  gap: 6px;

  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:active,
  &:focus {
    opacity: 0.8;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    border-radius: 50%;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 0;
  `};
`

export const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  cursor: pointer;
  color: ${({ theme }) => theme.white};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    border-radius: 0;
    padding: 0 10px;
    background: transparent;
    border: none;
    gap: 8px;
    color: ${({ theme }) => theme.red1};

    &:hover {
      border: none;
    }
  `};

  &:hover {
    box-shadow: 0px 20px 40px rgb(0 0 0 / 14%), 0px 10.125px 17.4375px rgb(0 0 0 / 9%), 0px 4px 6.5px rgb(0 0 0 / 7%),
      0px 0.875px 2.3125px rgb(0 0 0 / 5%);
  }
`

export const StyledText = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 240px;
  color: inherit;
  font-size: 13px;
  font-weight: 500;
`

export const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  position: relative;
  border: none;
  width: 100%;
  padding: 6px 15px;
  gap: 5px;
  height: 35px;
  color: ${({ pending, theme }) => (pending ? theme.secondaryText1 : theme.white)};
  border-radius: 5px;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(95deg, rgba(255, 255, 255, 0.1) 2.05%, rgba(255, 255, 255, 0.05) 96.48%);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.9;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
    padding: 6px;
  `};

  ${StyledText} {
    font-size: 12px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-weight: 700;

    ${({ theme }) => theme.mediaWidth.upToLarge`
      font-size: 11px;
    `};
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
  `};
`

export const NetworkIcon = styled(Activity)`
  width: 14px;
  height: 14px;
`

export const ConnectButton = styled(ButtonPrimaryGradient)`
  height: 33px;
  border-radius: 5px;
  border: none;
  overflow: hidden;
  padding: 8px 16px;
  gap: 6px;

  font-size: 13px;
  font-weight: 500;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    padding: 0 14px;
    border: none;
    gap: 8px;

    &:hover {
      border: none;
    }
  `};
`

export const Web3Button = styled(Web3StatusGeneric)<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  flex-shrink: 0;

  background: transparent;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 700;
  border: 1px solid ${({ theme }) => theme.white};

  ${StyledText} {
    font-size: 13px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-weight: 700;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
    border-radius: 30px;
    
    ${StyledText} {
      display: flex;
    }
  `};
`

export const StyledDropDown = styled(DropDown)<{ selected?: boolean }>`
  width: 10px;
  height: 8px;
  transform: ${({ selected }) => (selected ? 'rotate(180deg)' : 'none')};
  transform-origin: center;

  path {
    stroke: ${({ theme }) => theme.primaryText1};
    stroke-width: 1.5px;
  }
`
