import React, { useState } from 'react'
import { Box, useLocalStorage } from 'shared'
import styled from 'styled-components'
import useLockRatio from 'hooks/useLockRatio'

import { PairSelect } from './PairSelect'
import ChartToggle from 'components/Toggle/ChartToggle'
import QuestionHelper from 'components/QuestionHelper'
import { useSelectedCurrencies } from 'state/swap/hooks'
import useTheme from 'hooks/useTheme'
import { useGetSelectedChartToken } from '../hooks/useGetSelectedChartToken'
import { SimpleRechart } from './SimpleRechart'

const ChartWrapper = styled.div`
  flex-grow: 1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 10px;
  `};
`

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
`

type Props = {
  isMobile?: boolean
  isTokenChart?: boolean
  inputCurrency?: any
  outputCurrency?: any
}

export const Charts: React.FC<Props> = (props) => {
  const { wrappedInputCurrency, wrappedOutputCurrency } = useSelectedCurrencies()
  const [isUsdChart, setIsUsdChart] = useLocalStorage('isusdChart', false)
  const { inputCurrency, outputCurrency } = props

  const selectedChartTokenQuery = useGetSelectedChartToken(wrappedInputCurrency, wrappedOutputCurrency)

  // const [isFullChart, toggleFullChart] = useLocalStorage('chart-type', false)

  return (
    <Box
      minHeight={props.isTokenChart ? '300px' : '600px'}
      height={props.isTokenChart ? 'auto' : '100%'}
      display="flex"
      flexDirection="column"
      position="relative"
    >
      {!props.isTokenChart && (
        <HeaderWrapper>
          <Box px={props.isMobile ? '5px' : '4px'} display="flex" width="100%" alignItems="center">
            <PairSelect
              hasMetisPair={selectedChartTokenQuery.data?.hasMetisPair}
              isUsdChart={isUsdChart}
              selectedChartToken={selectedChartTokenQuery.data?.tokenAddress}
              setIsUsdChart={setIsUsdChart}
            />

            {/* <Box ml="20px" display="flex" alignItems="center">
            <ChartToggle isActive={lockRatio?.locked} toggle={toggleLockRatio} />
            <QuestionHelper text="Lock current pair ratio" />
          </Box> */}
          </Box>
          {/* <FullChartButtonWrapper>
          <FullChartButton
            onClick={() => {
              toggleFullChart(!isFullChart)
            }}
          >
            {isFullChart ? 'Simple chart' : 'Full chart'}
          </FullChartButton>
        </FullChartButtonWrapper> */}
        </HeaderWrapper>
      )}

      <ChartWrapper>
        {/* {isFullChart && inputCurrency && outputCurrency ? ( */}
        {inputCurrency && outputCurrency ? <SimpleRechart isTokenChart={props.isTokenChart} /> : null}
        {/* ) : null}
        {!isFullChart ? (
          <ChartPriceSimple lockRatio={lockRatio} toggleFullChart={toggleFullChart} isFullChart={isFullChart} />
        ) : null} */}
      </ChartWrapper>
    </Box>
  )
}
