import React, { useMemo, useState } from 'react'
import Subgraph from '../subgraph'
import { useChainId } from 'hooks'

const SubgraphContext = React.createContext<SubgraphContextInterface | undefined>(undefined)

interface SubgraphProviderProps {
  children: React.ReactNode
}

const SubgraphProvider = ({ children }: SubgraphProviderProps): JSX.Element => {
  const chainId = useChainId()
  const [subgraph, setSubgraph] = useState<Subgraph | null>(null)

  const memoizedSubgraph = useMemo(() => {
    if (!subgraph || subgraph.chainId !== chainId) {
      const newSubgraph = new Subgraph(chainId)
      setSubgraph(newSubgraph)
      return newSubgraph
    }
    return subgraph
  }, [chainId])

  return <SubgraphContext.Provider value={{ subgraph: memoizedSubgraph }}>{children}</SubgraphContext.Provider>
}

export interface SubgraphContextInterface {
  subgraph: Subgraph
}

export { SubgraphContext }
export default SubgraphProvider
