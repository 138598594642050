import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Trade, TokenAmount, CurrencyAmount, NATIVE_CURRENCY } from 'sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTokenAllowanceQuery } from '../data/Allowances'
import { useTransactionAdder, useHasPendingApproval } from '../state/transactions/hooks'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
import { calculateGasMargin, getRouterAddress } from '../utils'
import { useTokenContract } from './useContract'
import { useActiveWeb3React, useChainId } from './index'
import { CurrencyDirection } from 'enums/common'

import { waitAndFinalizeTransaction } from 'utils/waitAndFinalizeTransaction'
import { BigNumber } from 'ethers'
import { useAddPopup } from 'state/application/hooks'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string,
  type?: string,
  fetchedAllowance?: CurrencyAmount,
  force?: boolean
): [ApprovalState, () => Promise<void>] {
  const { account } = useActiveWeb3React()
  const chainId = useChainId()
  const [isApproving, setIsApproving] = useState(false)
  const [approveTransactionSuccess, setApproveTxSuccess] = useState(false)
  const addPopup = useAddPopup()

  const getToken = () => {
    const token = amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined

    return token
  }

  const token = getToken()

  const currentAllowanceQuery = useTokenAllowanceQuery(token, account ?? undefined, spender, fetchedAllowance)

  const pendingApproval = useHasPendingApproval(token?.address, spender)

  useEffect(() => {
    currentAllowanceQuery.refetch()
  }, [pendingApproval])

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (!Boolean(force) && amountToApprove.currency.symbol === NATIVE_CURRENCY[chainId].symbol)
      return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowanceQuery?.data) return ApprovalState.UNKNOWN

    if (approveTransactionSuccess) {
      return ApprovalState.APPROVED
    }

    // amountToApprove will be defined if currentAllowance is
    return currentAllowanceQuery.data.lessThan(amountToApprove) || currentAllowanceQuery.data.equalTo('0')
      ? pendingApproval || isApproving
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [
    amountToApprove,
    approveTransactionSuccess,
    pendingApproval,
    spender,
    currentAllowanceQuery.data,
    isApproving,
    chainId,
  ])

  useEffect(() => {
    setApproveTxSuccess(false)
  }, [token])

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      addPopup({
        notification: {
          success: false,
          text: 'No token',
        },
      })
      return
    }

    if (!tokenContract) {
      addPopup(
        {
          notification: {
            success: false,
            text: "Token contract doesn't exist",
          },
        },
        undefined,
        true
      )
      return
    }

    if (!amountToApprove) {
      addPopup(
        {
          notification: {
            success: false,
            text: 'Missing amount to Approve',
          },
        },
        undefined,
        true
      )
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    setIsApproving(true)

    //approve is very determenistic and we can predict it without calling estimateGas. This limit supposed to be 2x of what is actually required to execute tx, the overhead added just in case for weird approval implementations
    const estimatedGas = BigNumber.from(75000)

    return tokenContract
      .approve(spender, MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then(async (response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approve ' + amountToApprove.currency.symbol,
          approval: { tokenAddress: token.address, spender: spender },
        })
        await waitAndFinalizeTransaction(response.hash, chainId)

        setApproveTxSuccess(true)
      })
      .catch((error: Error) => {
        addPopup(
          {
            notification: {
              success: false,
              text: error.message,
            },
          },
          undefined,
          true
        )
      })
      .finally(() => {
        setIsApproving(false)
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction, chainId])

  return [approvalState, approve]
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(trade?: Trade, allowedSlippage = 0) {
  const chainId = useChainId()
  const amountToApprove = useMemo(
    () =>
      trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage, chainId)[CurrencyDirection.INPUT] : undefined,
    [trade, allowedSlippage, chainId]
  )

  return useApproveCallback(amountToApprove, getRouterAddress(chainId))
}
