import { useGetComplexProtocolListQuery } from './queries/useGetComplexProtocolListQuery'

export const usePortfolioTokens = () => {
  const getComplexProtocolListQuery = useGetComplexProtocolListQuery()
  const getComplexProtocolListData = getComplexProtocolListQuery.data

  const liquidityTokens = getComplexProtocolListData
    ? getComplexProtocolListData.lp.map((lp) => ({
        valueUSD: lp.stats.net_usd_value,
        token0: lp.supplyList[0],
        token1: lp.supplyList[1],
      }))
    : []

  const farmTokens = getComplexProtocolListData
    ? getComplexProtocolListData.farming.map((farm) => ({
        earnedBalance: farm.rewardList[0].amount,
        stakedUSD: farm.stats.net_usd_value,
        rewardTokenPrice: farm.rewardList[0].price,
        token0: farm.supplyList[0],
        token1: farm.supplyList[1],
      }))
    : []

  return {
    liquidityTokens,
    farmTokens,
    isLoading: getComplexProtocolListQuery.isLoading,
  }
}
