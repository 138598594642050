export function formatAmount(num: number): string {
  const numStr = String(num)
  if (numStr.includes('e')) {
    const [coefficient, exponent] = numStr.split('e')
    let formattedNumber: string
    if (parseInt(exponent) < 0) {
      const zerosToAdd = Math.max(
        Math.abs(parseInt(exponent)) - (coefficient.indexOf('.') !== -1 ? coefficient.indexOf('.') - 1 : 0),
        0
      )
      formattedNumber = '0.' + '0'.repeat(zerosToAdd) + coefficient.replace('.', '')
    } else {
      const zerosToAdd = Math.max(parseInt(exponent) - coefficient.length + 1, 0)
      formattedNumber = coefficient.replace('.', '') + '0'.repeat(zerosToAdd)
    }
    return formattedNumber
  } else {
    return numStr.replace(/(\.\d*?)0*$/, '$1').replace(/\.$/, '.0')
  }
}
