import useTheme from 'hooks/useTheme'
import React from 'react'
import ReactModal from 'react-modal'
import { Box } from 'shared'
import { X } from 'react-feather'
import './styles.css'
import styled from 'styled-components'

type Props = {
  closeModal: () => void
}

const CloseIcon = styled(X)<{ onClick: () => void }>`
  cursor: pointer;
  color: ${({ theme }) => theme.accentText};
`

export const SimplexCheckoutModal: React.FC<ReactModal.Props & Props> = (props) => {
  const theme = useTheme()

  return (
    <ReactModal
      closeTimeoutMS={150}
      style={{
        overlay: {
          zIndex: 99999,
          overflow: 'hidden',
          background: theme.modalOverlay,
          backdropFilter: 'blur(20px)',
          WebkitBackdropFilter: 'blur(20px)',
        },
        content: {
          background: theme.modalBG,
          boxShadow: theme.modalBoxShadow,
          maxWidth: '700px',
          minHeight: '80vh',
          maxHeight: '90vh',
          display: 'flex',
          borderRadius: '20px',
          margin: 'auto',
          border: 'none',
          padding: 0,
          inset: 'unset',
          transform: 'translateY(-50%)',
        },
      }}
      onRequestClose={props.closeModal}
      {...props}
    >
      <Box position="absolute" right="20px" top="20px" zIndex={2}>
        <CloseIcon onClick={props.closeModal} />
      </Box>
      <form id="simplex-form">
        <div id="checkout-element"></div>
      </form>
    </ReactModal>
  )
}
