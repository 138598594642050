import React from 'react'

export const ArrowBackModalIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 19 14" fill="none">
      <path
        d="M18 7.02081H1M1 7.02081L7.375 13.3958M1 7.02081L7.375 0.645813"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
