import { Box } from 'shared'
import React, { ReactElement } from 'react'
import { ArrowDownToggle } from 'icons/ArrowDownToggle'
import { SelectViewToggleBox, SelectViewToggleBtn } from './styles'
import { Text } from 'rebass'

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  head: string | JSX.Element | JSX.Element[]
  children: string | JSX.Element | JSX.Element[] | undefined
  isMint?: boolean
  showArrow?: boolean
}

export const SelectViewToggle = ({ head, children, isOpen, setIsOpen, isMint, showArrow = true }: Props) => {
  return (
    <SelectViewToggleBox disabled={isMint}>
      <SelectViewToggleBtn disabled={isMint} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {head}

        {showArrow && (
          <Text display="flex" alignItems="center" ml="auto">
            <ArrowDownToggle />
          </Text>
        )}
      </SelectViewToggleBtn>
      {isOpen && <Box paddingBottom="15px">{children}</Box>}
    </SelectViewToggleBox>
  )
}
