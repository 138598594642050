import { Currency, Price } from '@uniswap/sdk-core'
import { useCallback, useEffect, useState } from 'react'
import { Text } from 'rebass'
import { Field } from 'state/swap/v3/actions'
import styled from 'styled-components/macro'
import { formatToCurrency, formatToUSD } from 'utils/formatters'

interface TradePriceProps {
  currencies: Price<Currency, Currency>
  prices: any
}

const StyledPriceContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  grid-template-columns: 1fr auto;
  grid-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  text-align: left;
  flex-wrap: wrap;
  user-select: text;
  font: inherit;
  color: ${({ theme }) => theme.accentText};
`

export default function TradePrice({ currencies, prices }: TradePriceProps) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const [text, setText] = useState('')
  const [usdPrice, setUsdPrice] = useState<number>(0)
  const { baseCurrency, quoteCurrency } = currencies

  const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])
  useEffect(() => {
    const amount = showInverted ? prices[Field.INPUT].converted : prices[Field.OUTPUT].converted
    const firstToken = showInverted ? quoteCurrency.symbol : baseCurrency.symbol
    const secondToken = showInverted ? baseCurrency.symbol : quoteCurrency.symbol
    const newText = `${'1 ' + firstToken + ' = ' + formatToCurrency(amount) ?? '-'} ${secondToken}`
    setUsdPrice(showInverted ? prices[Field.OUTPUT].usd : prices[Field.INPUT].usd)
    setText(newText)
  }, [showInverted, currencies, prices])

  return (
    <StyledPriceContainer
      onClick={(e) => {
        e.stopPropagation() // dont want this click to affect dropdowns / hovers
        flipPrice()
      }}
      title={text}
    >
      <Text>{text}</Text> {usdPrice && <Text>({formatToUSD(usdPrice)})</Text>}
    </StyledPriceContainer>
  )
}
