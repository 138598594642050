import { useQuery } from 'react-query'
import { gql, request } from 'graphql-request'
import { STAKING_REWARDS_V3_SUBGRAPH_URL } from 'constants/v3/urls'
import { useActiveWeb3React, useChainId } from 'hooks'
import { PositionRaw } from 'types/v3'

const query = (account: string) => gql`
    query {
        positions(where: {owner: "${account}"}, orderDirection: desc, orderBy: id) {
            id
            liquidity
            oldOwner
            owner
            staked
            tokenId
            approved
            incentiveId
        }
    }
`

export function useStakingPositionsByOwnerQuery() {
  const chainId = useChainId()
  const { account } = useActiveWeb3React()

  return useQuery(
    ['v3', 'staking-positions-by-owner', account, chainId],
    async () => {
      return await request<{ positions: PositionRaw[] }>(STAKING_REWARDS_V3_SUBGRAPH_URL[chainId], query(account ?? ''))
    },
    {
      enabled: !!account,
    }
  )
}
