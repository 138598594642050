import { getAddress } from '@ethersproject/address'
import { useListedTokens } from 'hooks/Tokens'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'
import { useStakingIncentivesQuery } from 'hooks/queries/v3/useStakingIncentivesQuery'
import { fromWei } from 'pages/Farms/utils/formatNumber'

export const useCurrentIncentiveApr = ({ poolId, tvl }: { poolId: string; tvl: number }) => {
  const oneDayInMs = 86400000
  const daysInYear = 365
  const msInYear = daysInYear * oneDayInMs

  const listedTokens = useListedTokens('Market')
  const { data: incentivesData } = useStakingIncentivesQuery('active')
  const incentivesByPoolId =
    incentivesData?.incentives.filter((incentive) => incentive?.pool === poolId?.toLowerCase()) ?? []

  const now = new Date().getTime()
  const actualIncentivesByPoolId =
    incentivesByPoolId?.filter((incentive) => Number(incentive.endTime) * 1000 > now) ?? []
  const longestActualIncentive = actualIncentivesByPoolId[0]

  const startTimeMs = Number(longestActualIncentive?.startTime ?? 0) * 1000
  const endTimeMs = Number(longestActualIncentive?.endTime ?? 0) * 1000
  const stakePeriod = endTimeMs - startTimeMs

  const rewardTokenAddress = longestActualIncentive?.rewardToken ? getAddress(longestActualIncentive?.rewardToken) : ''
  const rewardTokenInfo = listedTokens[rewardTokenAddress]
  const rewardTokenSymbol = rewardTokenInfo?.symbol?.replace(' (OLD)', '')

  const rewardTokenUSDPrice = useCGTokenPrices({ tokenSymbols: [rewardTokenInfo?.symbol ?? ''] })

  const rewardAmount = fromWei(longestActualIncentive?.reward, rewardTokenInfo?.decimals).toNumber()
  const rewardAmountUSD = rewardAmount * (rewardTokenUSDPrice?.data?.[rewardTokenSymbol ?? '']?.usd ?? 0)
  const rewardAmountForYear = (rewardAmountUSD / stakePeriod) * msInYear ?? 0

  const apr = (rewardAmountForYear / Number(tvl)) * 100

  return {
    apr: isNaN(apr) ? 0 : apr,
    isIncentivised: !!longestActualIncentive,
  }
}
