import { V3_SUBGRAPH_URL } from 'constants/v3/urls'
import request, { gql } from 'graphql-request'
import { useChainId } from 'hooks'
import { useQuery } from 'react-query'
import { PoolRaw } from 'types/v3'

const query = gql`
  query {
    pools {
      collectedFeesToken0
      collectedFeesToken1
      collectedFeesUSD
      createdAtBlockNumber
      createdAtTimestamp
      feeGrowthGlobal0X128
      feeGrowthGlobal1X128
      feeTier
      feesUSD
      id
      liquidity
      liquidityProviderCount
      observationIndex
      sqrtPrice
      tick
      token0Price
      token1Price
      totalValueLockedETH
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedUSD
      totalValueLockedUSDUntracked
      txCount
      untrackedVolumeUSD
      volumeToken0
      volumeToken1
      volumeUSD
      token1 {
        id
        symbol
        decimals
      }
      token0 {
        id
        symbol
        decimals
      }
    }
  }
`

export const usePoolsListQuery = () => {
  const chainId = useChainId()

  return useQuery(['v3', 'pools-list'], async () => {
    return await request<{ pools: PoolRaw[] }>(V3_SUBGRAPH_URL[chainId], query)
  })
}
