import { useQuery } from 'react-query'
import { api } from 'shared'

type Response = {
  id: string
  symbol: string
  name: string
  platforms: Record<string, string>
}[]

export const useCGAllCoinsList = () => {
  return useQuery(['coingecko-all-coins'], async () => {
    const response = await api.coingeckoApi.get<Response>('/coins/list?include_platform=true')

    return response.data
  })
}
