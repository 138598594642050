import { Currency, NATIVE_CURRENCY, Token } from 'sdk'
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import { useIsUserAddedToken, useToken, useUserAddedTokensMap } from '../../hooks/Tokens'
import { CloseIcon, TYPE } from '../../theme'
import Column from '../Column'
import Row, { RowBetween, RowFixed } from '../Row'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import { useTokenComparator } from './sorting'
import { PaddedColumn, SearchInput, Separator } from './styleds'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import useToggle from 'hooks/useToggle'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import useDebounce from 'hooks/useDebounce'
import { CurrencyDirection } from 'enums/common'
import { useListedTokens } from 'hooks/Tokens'

import ImportRow from './ImportRow'
import { useChainId } from 'hooks'
import { SwapType } from 'state/swap/reducer'
import { defaultTokens } from 'sdk/constants'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: ${({ theme }) => theme.componentBg2};
  border-top: 1px solid ${({ theme }) => theme.border1};
`

interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  activeCurrencyDirection: CurrencyDirection | null
  showImportView: () => void
  setSearchTokenQuery: (searchQuery: string) => void
  setImportToken: (token: Token) => void
  showImportSelectedTokenView: () => void
  listType: SwapType
  market?: boolean
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  onDismiss,
  isOpen,
  activeCurrencyDirection,
  showImportView,
  setSearchTokenQuery,
  setImportToken,
  showImportSelectedTokenView,
  listType,
  market = false,
}: CurrencySearchProps) {
  const theme = useTheme()
  const chainId = useChainId()
  const [isLoading, setIsLoading] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 500)

  const [invertSearchOrder] = useState<boolean>(false)

  const listedTokens = useListedTokens(listType)

  // const testData = useListOf1InchAvaibleTokensForSwap()

  const searchToken = useToken(searchQuery)
  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  const userAddedTokensMap = useUserAddedTokensMap()

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredTokens: Token[] = useMemo(() => {
    const tokens = { ...userAddedTokensMap, ...listedTokens } as Record<string, Token>

    return filterTokens(
      Object.values(tokens)
        .filter((token) => token.symbol !== NATIVE_CURRENCY[chainId].symbol)
        .filter((token) => {
          return (
            !market ||
            [NATIVE_CURRENCY[chainId]?.symbol, `W${NATIVE_CURRENCY[chainId]?.symbol}`, 'LIF3'].includes(
              String(token.symbol)
            )
          )
        }),
      debouncedQuery
    )
  }, [debouncedQuery])

  const sortedTokens: Token[] = useMemo(() => {
    const initial = filteredTokens.filter((item: any) => {
      return defaultTokens.includes(item.symbol)
    })
    const trimmedQuery = searchQuery.trim()

    if (!trimmedQuery) {
      return initial
    }
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator, searchQuery, defaultTokens])

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  const importToken = useCallback(
    (searchQuery: string) => {
      setSearchTokenQuery(searchQuery)

      showImportView()
    },
    [setSearchTokenQuery, showImportView]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
    if (isOpen && inputRef?.current) inputRef.current.focus()
  }, [isOpen])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    setSearchQuery(input)

    if (input.trim() === '') {
      setIsTyping(false)
      setIsLoading(false)
      return
    }

    setIsTyping(true)
    const trimmedInput = input.trim()
    if (!trimmedInput) {
      return
    }
    setIsLoading(true)
    fixedList.current?.scrollTo(0)
  }, [])

  useEffect(() => {
    if (debouncedQuery) {
      setIsLoading(false)
      setIsTyping(false)
    }
  }, [debouncedQuery])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === NATIVE_CURRENCY[chainId].symbol.toLowerCase) {
          handleCurrencySelect(NATIVE_CURRENCY[chainId])
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, debouncedQuery]
  )

  // menu ui
  const [open, toggle] = useToggle(false)
  const node = useRef<HTMLDivElement>()

  useOnClickOutside(node, open ? toggle : undefined)

  const showFTM: boolean = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()
    const nativeSymbol = NATIVE_CURRENCY[chainId]

    return s === '' || s === nativeSymbol[0] || s === nativeSymbol[1] || s === nativeSymbol[2]
  }, [debouncedQuery])

  return (
    <ContentWrapper>
      <PaddedColumn gap="16px">
        <RowBetween>
          <Text fontWeight={500} fontSize={16} color={theme.primaryText1}>
            Select a token
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <Row>
          <SearchInput
            type="text"
            id="token-search-input"
            placeholder={'Search name or paste address'}
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </Row>
        <CommonBases onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency} />
        {/* <BuyCryptoLink /> */}
      </PaddedColumn>
      <Separator />
      {isTyping ? (
        <Column style={{ padding: '20px', height: '100%' }}>
          <TYPE.main color={theme.accentText} textAlign="center" mb="20px">
            Loading...
          </TYPE.main>
        </Column>
      ) : isLoading ? (
        <Column style={{ padding: '20px', height: '100%' }}>
          <TYPE.main color={theme.accentText} textAlign="center" mb="20px">
            Loading...
          </TYPE.main>
        </Column>
      ) : searchToken && !searchTokenIsAdded ? (
        <Column style={{ padding: '20px', height: '100%' }}>
          <ImportRow token={searchToken} showImportView={showImportSelectedTokenView} setImportToken={setImportToken} />
        </Column>
      ) : filteredSortedTokens.length > 0 || showFTM ? (
        <div style={{ flex: '1' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                currencies={filteredSortedTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                selectedCurrency={selectedCurrency}
                fixedListRef={fixedList}
                showFTM={showFTM}
                setImportToken={setImportToken}
              />
            )}
          </AutoSizer>
        </div>
      ) : (
        <Column style={{ padding: '20px', height: '100%' }}>
          <TYPE.main color={theme.accentText} textAlign="center" mb="20px">
            No results found.
          </TYPE.main>
        </Column>
      )}
    </ContentWrapper>
  )
}

{
  /* <Footer>
        <Row justify="center">
          <ButtonText onClick={() => importToken('')} color={theme.accent2} className="list-token-manage-button">
            <RowFixed>
              <IconWrapper size="16px" marginRight="6px">
                <Edit />
              </IconWrapper>
              <TYPE.main color={theme.accent2}>Manage tokens</TYPE.main>
            </RowFixed>
          </ButtonText>
        </Row>
      </Footer> */
}
