import { ChainId } from 'sdk/constants'
import { Token } from './Token'
export { Ether } from './Ether'
export { Token } from './Token'
export { CurrencyAmount } from './CurrencyAmount'

export type { Currency } from './Currency'

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  [ChainId.OPTIMISM]: new Token(
    ChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.POLYGON]: new Token(
    ChainId.POLYGON,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [ChainId.FANTOM]: new Token(
    ChainId.FANTOM,
    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    18,
    'WFTM',
    'Wrapped FANTOM'
  ),
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ethereum'
  ),
  [ChainId.LIF3CHAIN_TESTNET]: new Token(
    ChainId.LIF3CHAIN_TESTNET,
    '0x6883d461FE9FB40D3Ff3393fDE5147A6617b3E30',
    18,
    'WLIF3',
    'Wrapped LIF3'
  ),
  [ChainId.BSC]: new Token(ChainId.BSC, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'WBNB'),
}
