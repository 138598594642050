import styled from 'styled-components'
import Settings from 'components/Settings'
import { Box } from 'shared'
import { Text } from 'rebass'
import { useSelectedCurrencies } from 'state/swap/hooks'
import { Currency } from 'sdk'
import SwapAggregator from '../SwapAggregator'
import { usePrevious } from 'react-use'
import { useChainId } from 'hooks'
import { SwapType } from 'state/swap/reducer'
import { useLocation } from 'react-router'
import { SwapV3 } from 'pages/SwapV3'
import SwapMarket from '../Swap/SwapMarket'
import { isV2 } from 'utils'

const TradeFormHeader = styled.div<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 20px;
  padding-right: 23px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  z-index: 1;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  border-radius: 10px 10px 0 0;
`

const TradeFormRoot = styled.div<{ isTransparent?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background: ${({ theme, isTransparent }) => (isTransparent ? 'transparent' : theme.componentBg1)};
  border-radius: 10px;
  backdrop-filter: ${({ isTransparent }) => (isTransparent ? 'none' : 'blur(15px)')};
  -webkit-backdrop-filter: ${({ isTransparent }) => (isTransparent ? 'none' : 'blur(15px)')};

  @media screen and (max-height: 900px) {
    height: 100%;
  }
`

const TradeFormInner = styled.div<{ isStyled?: boolean }>`
  padding-top: ${({ isStyled }) => (isStyled ? '0' : '18px')};
  border-radius: 0 0 20px 20px;
  flex-grow: 1;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;

  & > div {
    height: auto !important;
  }
`

const TradeFormWrapper = styled.div<{ isExpanded?: boolean }>`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: ${({ isExpanded }) => (isExpanded ? 'initial' : 'auto')};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

type TradeFormProps = {
  isTradeSwap?: boolean
}

export const TradeForm = ({ isTradeSwap }: TradeFormProps) => {
  const { inputCurrency } = useSelectedCurrencies()
  const chainId = useChainId()
  const previousChainId = usePrevious(chainId)
  const didChainChange = previousChainId !== undefined && previousChainId !== chainId
  // const isExpertMode = useIsExpertMode()
  const isExpertMode = false

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const swapType = (searchParams.get('swapType') as SwapType) ?? 'Market'

  const isSimpleChart = swapType === 'CrossChain' || isTradeSwap

  const renderSwapLabel = () => {
    switch (swapType) {
      case 'Aggregator':
        return 'Swap'

      case 'Market':
        return 'Swap'
      case 'Limit':
        return 'Limit Order'

      // case 'CrossChain':
      //   return 'Cross-Chain'

      default:
        return null
    }
  }

  const renderContent = () => {
    switch (swapType) {
      case 'Aggregator':
        return <SwapAggregator didChainChange={didChainChange} disabled={false} />

      case 'Market':
        return isV2(chainId) ? <SwapMarket didChainChange={didChainChange} /> : <SwapV3 />

      case 'Limit':
        return <SwapV3 />

      default:
        return <SwapV3 />
    }
  }

  return (
    <TradeFormRoot isTransparent={isSimpleChart}>
      <TradeFormHeader isHidden={isSimpleChart}>
        <Box display="flex" alignItems="center">
          <Text fontSize="18px" fontWeight={500}>
            {renderSwapLabel()}
          </Text>
        </Box>

        {inputCurrency !== Currency.USD_CARD && (
          <>
            {/* <ActionButtons /> */}
            <Settings />
          </>
        )}
      </TradeFormHeader>
      <TradeFormWrapper isExpanded={isSimpleChart && !isExpertMode}>
        <TradeFormInner isStyled={isSimpleChart}>{renderContent()}</TradeFormInner>
      </TradeFormWrapper>
    </TradeFormRoot>
  )
}
