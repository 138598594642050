import styled from 'styled-components'

export const TokenInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const TokenPriceInfoDiff = styled.span<{ diffColor?: string }>`
  display: flex;
  align-items: center;
  gap: 7px;

  font-size: 12px;
  font-weight: 400;

  & > div {
    color: ${({ diffColor }) => diffColor};
    transform: ${({ diffColor, theme }) => (diffColor === theme.green1 ? 'none' : 'rotate(180deg)')};
  }
`
