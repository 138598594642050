import React from 'react'

export const BridgeIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16" fill="none">
      <path
        d="M15 15C15 11.1365 11.8635 8 8 8C4.13654 8 1 11.1365 1 15M1.0668 4.49991H14.9996M1.0668 1H14.9996"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}
