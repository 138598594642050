import { ChainId } from 'sdk'
import { Token, WRAPPED_NATIVE_CURRENCY } from 'sdk/v3'

export const USDC = {
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6, 'USDC', 'USDC'),
  [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', 6, 'USDC', 'USDC'),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 6, 'USDC', 'USDC'),
  [ChainId.BSC]: new Token(ChainId.BSC, '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', 18, 'USDC', 'USDC'),
}

export const USDT = {
  [ChainId.FANTOM]: new Token(ChainId.FANTOM, '0x049d68029688eAbF473097a2fC38ef61633A3C7A', 6, 'fUSDT', 'fUSDT'),
  [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6, 'USDT', 'USDT'),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 6, 'USDT', 'USDT'),
  [ChainId.BSC]: new Token(ChainId.BSC, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'USDT'),
}

export const BASES_TO_CHECK_TRADES_AGAINST = {
  [ChainId.FANTOM]: [WRAPPED_NATIVE_CURRENCY[ChainId.FANTOM], USDC[ChainId.FANTOM], USDT[ChainId.FANTOM]],
  [ChainId.ETHEREUM]: [WRAPPED_NATIVE_CURRENCY[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  [ChainId.POLYGON]: [WRAPPED_NATIVE_CURRENCY[ChainId.POLYGON], USDC[ChainId.POLYGON], USDT[ChainId.POLYGON]],
  [ChainId.BSC]: [WRAPPED_NATIVE_CURRENCY[ChainId.BSC], USDC[ChainId.BSC], USDT[ChainId.BSC]],
}
