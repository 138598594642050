import React from 'react'
import { TradeForm } from '../../TradeForm'
import styled from 'styled-components'
import { OrdersInfo } from 'pages/Trade/OrdersInfo'
import { BuyCryptoLink } from 'components/BuyCryptoLink'
import { SwapType } from 'state/swap/reducer'
import { SwapTabs } from 'pages/Trade/SwapTabs'
import { TradeTokenBox } from '../styles'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 25px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  `};
`

const TradeFormWrapper = styled.div`
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const OrderInfoWrapper = styled.div`
  width: 100%;
  max-width: 460px;
`

type ClassicLayoutType = {}

export const ClassicLayout = (props: ClassicLayoutType) => {
  const searchParams = new URLSearchParams(location?.search)
  const swapType = (searchParams.get('swapType') as SwapType) ?? 'Aggregator'

  // const isCrossSwap = swapType === 'CrossChain'
  const isCrossSwap = false

  return (
    <Wrapper>
      <TradeFormWrapper>
        <TradeTokenBox isTokenLayout={isCrossSwap}>
          <SwapTabs isTradeSwap={!isCrossSwap} />
          <TradeForm isTradeSwap={!isCrossSwap} />
        </TradeTokenBox>
        {/* <BuyCryptoLink /> */}
      </TradeFormWrapper>
      {/* {tab === 'Limit' && (
      <OrderInfoWrapper>
        <OrdersInfo />
      </OrderInfoWrapper>
    )} */}
    </Wrapper>
  )
}
