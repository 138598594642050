import { RPC } from 'connectors'
import { currentMainNetwork } from 'sdk'
import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'

const httpProvider = new Web3.providers.HttpProvider(RPC[currentMainNetwork], {
  headers: [
    {
      name: 'Access-Control-Allow-Origin',
      value: "'*'",
    },
  ],
})

export const web3NoAccount = new Web3(httpProvider)
