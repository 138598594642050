import { useStakingPositionByTokenIdQuery } from 'hooks/queries/v3/useStakingPositionByTokenIdQuery'
import { PositionRaw } from 'types/v3'

export type UsePositionByTokenIdInputProps = {
  tokenId: number
}
export type UsePositionByTokenIdOutputProps = {
  position?: PositionRaw
}

export const usePositionByTokenId = ({ tokenId }: UsePositionByTokenIdInputProps): UsePositionByTokenIdOutputProps => {
  const { data: positionByTokenIdData } = useStakingPositionByTokenIdQuery(tokenId)

  if (positionByTokenIdData) {
    return {
      position: positionByTokenIdData?.positions[0],
    }
  }

  return {}
}
