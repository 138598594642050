// TODO: <
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: >

import useAccount from './useAccount'
import { useState } from 'react'
import useSubgraph, { SUBGRAPH_QUERY } from './useSubgraph'
import { UserData, Address, PoolTokenType } from '../types/interfaces'
import usePairAddress from './usePairAddress'
import { useQuery } from 'react-query'
import { useChainId } from 'hooks'

// export function useUserData(): UserData {
//   const account = useAccount()
//   const [userData, setUserData] = useState<UserData>()
//   useSubgraphCallback(async (subgraph) => account && setUserData(await subgraph.getUserData(account)), [account])
//   return userData
// }

export function useBorrowPositionsQuery() {
  const chainId = useChainId()
  const account = useAccount()
  const subgraph = useSubgraph()

  return useQuery<Address[]>(
    [SUBGRAPH_QUERY, 'account', 'borrow-positions', account, chainId],
    () => {
      return subgraph.getBorrowPositions(account)
    },
    {
      enabled: Boolean(account),
    }
  )
}

export function useSupplyPositionsQuery() {
  const chainId = useChainId()

  const account = useAccount()
  const subgraph = useSubgraph()

  return useQuery<Address[]>(
    [SUBGRAPH_QUERY, 'account', 'supply-positions', account, chainId],
    () => {
      return subgraph.getSupplyPositions(account)
    },
    {
      enabled: Boolean(account),
    }
  )
}

export function useCollateralAmountQuery() {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'collateral-amount', account, uniswapV2PairAddress, chainId],
    () => {
      return subgraph.getCollateralAmount(account, uniswapV2PairAddress)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useCollateralValueQuery() {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'collateral-value', account, uniswapV2PairAddress, chainId],
    () => {
      return subgraph.getCollateralValue(account, uniswapV2PairAddress)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useBorrowedAmountQuery(poolTokenType: PoolTokenType) {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'borrowed-amount', account, uniswapV2PairAddress, poolTokenType, chainId],
    () => {
      return subgraph.getBorrowedAmount(account, uniswapV2PairAddress, poolTokenType)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useBorrowedValueQuery(poolTokenType: PoolTokenType) {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'borrowed-value', account, uniswapV2PairAddress, poolTokenType, chainId],
    () => {
      return subgraph.getBorrowedValue(account, uniswapV2PairAddress, poolTokenType)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useBorrowerEquityValueQuery() {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'borrowed-equity-value', account, uniswapV2PairAddress, chainId],
    () => {
      return subgraph.getBorrowerEquityValue(account, uniswapV2PairAddress)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useSuppliedAmountQuery(poolTokenType: PoolTokenType) {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'supplied-amount', account, uniswapV2PairAddress, poolTokenType, chainId],
    () => {
      return subgraph.getSuppliedAmount(account, uniswapV2PairAddress, poolTokenType)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useSuppliedValueQuery(poolTokenType: PoolTokenType) {
  const chainId = useChainId()

  const account = useAccount()
  const uniswapV2PairAddress = usePairAddress()
  const subgraph = useSubgraph()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'supplied-value', account, uniswapV2PairAddress, poolTokenType, chainId],
    () => {
      return subgraph.getSuppliedValue(account, uniswapV2PairAddress, poolTokenType)
    },
    {
      enabled: Boolean(account && uniswapV2PairAddress),
    }
  )
}

export function useAccountTotalValueLockedQuery() {
  const account = useAccount()
  const subgraph = useSubgraph()
  const chainId = useChainId()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'total-value-locked', account, chainId],
    () => {
      return subgraph.getAccountTotalValueLocked(account)
    },
    {
      enabled: Boolean(account),
    }
  )
}

export function useAccountTotalValueSuppliedQuery() {
  const account = useAccount()
  const subgraph = useSubgraph()
  const chainId = useChainId()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'total-value-supplied', account, chainId],
    () => {
      return subgraph.getAccountTotalValueSupplied(account)
    },
    {
      enabled: Boolean(account),
    }
  )
}

export function useAccountTotalValueBorrowedQuery() {
  const account = useAccount()
  const subgraph = useSubgraph()
  const chainId = useChainId()

  return useQuery<number>(
    [SUBGRAPH_QUERY, 'account', 'total-value-borrowed', account, chainId],
    () => {
      return subgraph.getAccountTotalValueBorrowed(account)
    },
    {
      enabled: Boolean(account),
    }
  )
}
