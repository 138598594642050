import { useQuery } from 'react-query'
import { gql, request } from 'graphql-request'
import { useChainId } from 'hooks'
import { STAKING_REWARDS_V3_SUBGRAPH_URL } from 'constants/v3/urls'
import { IncentiveRaw } from 'types/v3'

type incentiveType = 'active' | 'all'

// longest first
const query = (type: incentiveType, currentTime: number) => gql`
  query {
    incentives(${
      type === 'active' ? `where: {ended: false, startTime_lt: ${currentTime}, endTime_gt: ${currentTime}}` : ''
    } orderBy: endTime, orderDirection: desc) {
      id
      rewardToken
      pool
      startTime
      endTime
      refundee
      reward
      ended
      totalStaked
    }
  }
`

export function useStakingIncentivesQuery(type: incentiveType = 'all') {
  const chainId = useChainId()
  const currentTime = Math.floor(Date.now() / 1000)
  return useQuery(['v3', 'staking-incentives'], async () => {
    return await request<{ incentives: IncentiveRaw[] }>(
      STAKING_REWARDS_V3_SUBGRAPH_URL[chainId],
      query(type, currentTime)
    )
  })
}
