import { DiscordIcon } from 'icons/DiscordIcon'
import { InstagramIcon } from 'icons/InstagramIcon'
import { MediumIcon } from 'icons/MediumIcon'
import { TelegramIcon } from 'icons/TelegramIcon'
import { TwitterIcon } from 'icons/TwitterIcon'
import { YoutubeIcon } from 'icons/YoutubeIcon'
import React, { useMemo } from 'react'
import { SocialList, Social } from './styles'

export const SocialsList = () => {
  return (
    <SocialList>
      <Social to={{ pathname: 'https://twitter.com/Official_LIF3' }} target="_blank">
        <TwitterIcon />
      </Social>
      <Social to={{ pathname: 'https://t.me/Lif3_Official' }} target="_blank">
        <TelegramIcon />
      </Social>
      <Social to={{ pathname: 'https://www.instagram.com/lif3official/' }} target="_blank">
        <InstagramIcon />
      </Social>
      <Social to={{ pathname: 'https://official-lif3.medium.com/' }} target="_blank">
        <MediumIcon />
      </Social>
      <Social to={{ pathname: 'https://discord.gg/lif3' }} target="_blank">
        <DiscordIcon />
      </Social>
      <Social to={{ pathname: 'https://www.youtube.com/@Official_LIF3' }} target="_blank">
        <YoutubeIcon />
      </Social>
    </SocialList>
  )
}
