import { ChainId } from 'sdk'

export const v3TokensList = {
  [ChainId.FANTOM]: [
    {
      address: '0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf',
      chainId: ChainId.FANTOM,
      name: 'USD Coin',
      symbol: 'USDC (Layer Zero)',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      chainId: ChainId.FANTOM,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },

    {
      address: '0x5e074C28149cC35c1e5fbC79d8A6ea688dba2314',
      chainId: 250,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    /* {
      address: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
      chainId: 250,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    }, */
    {
      address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      chainId: 250,
      name: 'WFTM',
      symbol: 'WFTM',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WFTM.svg',
    },
    {
      address: '0x8fF27b58E1d969bEFC9c8aEAE93A6EB7E2250c8f',
      chainId: 250,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
      chainId: 250,
      name: 'LSHARE (Old)',
      symbol: 'LSHARE (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
      chainId: ChainId.FANTOM,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
  ],
  [ChainId.ETHEREUM]: [
    {
      address: '0x7138Eb0d563f3F6722500936A11DcAe99D738A2c',
      chainId: ChainId.ETHEREUM,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      chainId: ChainId.ETHEREUM,
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WETH.svg',
    },
    {
      address: '0x8E01397163b21F64ceC1f06cA6cC7d9aA8F718e9',
      chainId: ChainId.ETHEREUM,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
      chainId: ChainId.ETHEREUM,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
    {
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      chainId: ChainId.ETHEREUM,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      chainId: ChainId.ETHEREUM,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      chainId: ChainId.ETHEREUM,
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      logoURI: 'https://assets.lif3.com/swap/tokens/WBTC.svg',
    },
  ],
  [ChainId.AMOY_TESTNET]: [
    {
      address: '0x293475783df8fc51258b1004aFF1Cb1886BbDa6e',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x40FE2F4EeaD3d472E5c064b8e970e5a595A44558',
      chainId: ChainId.AMOY_TESTNET,
      name: 'WMATIC',
      symbol: 'WMATIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WMATIC.svg',
    },
    {
      address: '0x9880aDdD2b2acA4a8fa02274dAd1679B38F0A303',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x293475783df8fc51258b1004aFF1Cb1886BbDa6e',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LIF3 (Old)',
      symbol: 'LIF3 (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x9880aDdD2b2acA4a8fa02274dAd1679B38F0A303',
      chainId: ChainId.AMOY_TESTNET,
      name: 'LSHARE (Old)',
      symbol: 'LSHARE (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
      chainId: ChainId.AMOY_TESTNET,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
    {
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      chainId: ChainId.AMOY_TESTNET,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      chainId: ChainId.AMOY_TESTNET,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
  ],
  [ChainId.POLYGON]: [
    {
      address: '0x110B25D2B21eE73eB401F3aE7833f7072912A0Bf',
      chainId: ChainId.POLYGON,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      chainId: ChainId.POLYGON,
      name: 'WMATIC',
      symbol: 'WMATIC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/WMATIC.svg',
    },
    {
      address: '0x5eAB32Fe1D104cE0C5436feDC3433B646096e47C',
      chainId: ChainId.POLYGON,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
      chainId: ChainId.POLYGON,
      name: 'LIF3 (Old)',
      symbol: 'LIF3 (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0xFB40b1eFe90D4b786D2D9d9dc799B18BDe92923b',
      chainId: ChainId.POLYGON,
      name: 'LSHARE (Old)',
      symbol: 'LSHARE (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0x2C2D8a078B33bf7782a16AcCE2C5BA6653a90D5f',
      chainId: ChainId.POLYGON,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
    {
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      chainId: ChainId.POLYGON,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      chainId: ChainId.POLYGON,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
  ],
  [ChainId.BSC]: [
    {
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      chainId: ChainId.BSC,
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
    },
    {
      chainId: ChainId.BSC,
      address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      symbol: 'WBNB',
      decimals: 18,
      name: 'Wrapped BNB',
      logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
      tags: ['PEG:BNB', 'tokens'],
    },
    {
      address: '0x55d398326f99059fF775485246999027B3197955',
      chainId: ChainId.BSC,
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x336f374198c872Ba760e85af9c6331C3c5A535d3',
      chainId: ChainId.BSC,
      name: 'LIF3',
      symbol: 'LIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
      chainId: ChainId.BSC,
      name: 'LIF3 (Old)',
      symbol: 'LIF3 (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x0c08638473CAfbCa3beb113616A1871F4bfaD4f9',
      chainId: ChainId.BSC,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
      chainId: ChainId.BSC,
      name: 'LSHARE (Old)',
      symbol: 'LSHARE (Old)',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
      chainId: ChainId.BSC,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
  ],
  [ChainId.LIF3CHAIN_TESTNET]: [
    {
      address: '0x6883d461FE9FB40D3Ff3393fDE5147A6617b3E30',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'Wrapped Lif3',
      symbol: 'WLIF3',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
    },
    {
      address: '0x621A936084BBb73F947C827a92C93Ef6A3612c3A',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'USDT',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://assets.lif3.com/swap/tokens/USDT.svg',
    },
    {
      address: '0x6aAaa67227D8D29D26115a433130D882A876507d',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'LSHARE',
      symbol: 'LSHARE',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
    },
    {
      address: '0xe97EF94FF6df13d769f1F1ac23C2419eFF501ad8',
      chainId: ChainId.LIF3CHAIN_TESTNET,
      name: 'L3USD',
      symbol: 'L3USD',
      decimals: 18,
      logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
    },
  ],
}
