import { ChainId } from 'sdk'

const ROUTER_ADDRESSES: {
  [chainId: number]: string
} = {
  [ChainId.METIS]: '0xd0ADab1583B87cEfa06eF3F9C79C88B6be40083b',
  [ChainId.TOMBCHAINTESTNET]: '0x61b34c89563D6F8B8A7BEaD7F4d85c87a0a20113',
  [ChainId.TOMB]: '',
  [ChainId.FANTOM]: '0x360008f66220660ec410194ce1ae602a2a86c1b6',
  [ChainId.BSC]: '0x5F79B0dB98dA437dee6e6AA8780FA1290ebC453c',
  [ChainId.POLYGON]: '0x3Cb84455F391Daabe71Db66250Fd243C91b0a137',
}

export { ROUTER_ADDRESSES }

export const ROUTER_ADDRESSES_ODOS_V2: {
  [chainId: number]: string
} = {
  [ChainId.ETHEREUM]: '0xCf5540fFFCdC3d510B18bFcA6d2b9987b0772559',
  [ChainId.BSC]: '0x89b8AA89FDd0507a99d334CBe3C808fAFC7d850E',
  [ChainId.FANTOM]: '0xD0c22A5435F4E8E5770C1fAFb5374015FC12F7cD',
  [ChainId.POLYGON]: '0x4E3288c9ca110bCC82bf38F09A7b425c095d92Bf',
}
