import styled, { keyframes } from 'styled-components'

export const CSSVariables = styled.div<{
  noOfSlides: number
  slidesInView: number
  slideHeight: string
  iterationTime: string
}>`
  --no-of-slides: ${(props) => props.noOfSlides};
  --slides-in-view: ${(props) => props.slidesInView};
  --slide-height: ${(props) => props.slideHeight};
  --iteration-time: ${(props) => props.iterationTime};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: auto;
  `};
`

const slideAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(calc(-1 * var(--slide-height)));
  }
  63% {
    transform: translateY(calc(-1 * var(--slide-height)));
  }
  66% {
    transform: translateY(calc(-2 * var(--slide-height)));
  }
  96% {
    transform: translateY(calc(-2 * var(--slide-height)));
  }
  100% {
    transform: translateY(calc(-3 * var(--slide-height)));
  }
`

export const PricesCarousel = styled.div`
  display: flex;
  flex-direction: column;
  height: var(--slide-height);
  overflow: hidden;
  padding: 0 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(95deg, rgba(255, 255, 255, 0.1) 2.05%, rgba(255, 255, 255, 0.05) 96.48%);
  border-radius: 5px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 0 6px;
  `};
`

export const PricesCarouselTrack = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(var(--slides-in-view) * var(--slide-height));
  margin: 0 auto;
  animation-name: ${slideAnimation};
  animation-duration: var(--iteration-time);
  animation-iteration-count: infinite;
`

export const PricesSlide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: var(--slide-height);
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`

export const TethysPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  min-width: 75px;
  color: ${({ theme }) => theme.primaryText1};
  transition: color 0.3s;
`

export const TokenLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
`

export const TokenLogo = styled.img`
  width: 18px;
  height: 18px;
`
