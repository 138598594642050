import React from 'react'

export const ExclamationCircleIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 0.5C9.37071 0.5 11.1269 1.22747 12.4497 2.55024C13.7725 3.87306 14.5 5.62929 14.5 7.5C14.5 9.37071 13.7725 11.1269 12.4497 12.4497C11.1269 13.7725 9.37071 14.5 7.5 14.5C5.62926 14.5 3.87303 13.7725 2.55023 12.4497C1.22744 11.1269 0.5 9.37071 0.5 7.5C0.5 5.62928 1.22744 3.87302 2.55023 2.55023C3.873 1.22747 5.62926 0.5 7.5 0.5Z"
        fill="#E2726B"
        stroke="#E2726B"
      />
      <path d="M7 3V9H8.07778V3H7ZM7 9.92222V11H8.07778V9.92222H7Z" fill="white" />
    </svg>
  )
}
