import { isAddress } from '@ethersproject/address'
import { useActiveWeb3React } from './useActiveWeb3React'
import { TRACKING_ADDRESS_KEY } from '../constants/localStorage'
import { useMemo } from 'react'
import { useLocalStorage } from './useLocalStorage'

export const useAccountAddress = () => {
  const { account } = useActiveWeb3React()
  const [trackingAddress, setTrackingAddress] = useLocalStorage<string>(TRACKING_ADDRESS_KEY)

  return useMemo(() => {
    if (trackingAddress && !isAddress(trackingAddress)) {
      setTrackingAddress('')
    }

    return account ?? trackingAddress ?? null
  }, [trackingAddress, setTrackingAddress, account])
}
