import { ChainId } from 'sdk'

export const getExchangeSubgraphUrl = (exchange?: string) => {
  switch (exchange) {
    case 'LIF3Swap':
      return {
        [ChainId.TOMBCHAINTESTNET]: 'http://168.119.232.67:8000/subgraphs/name/lifeswap',
        [ChainId.TOMB]: 'https://graph-node.lif3.com/subgraphs/name/lifeswap',
        [ChainId.FANTOM]: 'https://api.studio.thegraph.com/query/72851/tombswap-subgraph/v0.0.1',
        [ChainId.BSC]: 'https://api.studio.thegraph.com/query/72851/lif3-bsc/v0.0.1',
        [ChainId.POLYGON]: 'https://api.studio.thegraph.com/query/72851/lif3-matic/v0.0.1',
        [ChainId.AMOY_TESTNET]: 'https://api.studio.thegraph.com/query/72851/lif3-matic/v0.0.1',
        [ChainId.ETHEREUM]: 'https://api.studio.thegraph.com/query/72851/lif3-mainnet/v0.0.1', // TODO: replace for eth when token info subgraph is ready
        [ChainId.OPTIMISM]: 'https://api.studio.thegraph.com/query/72851/lif3-mainnet/v0.0.1', // TODO: replace for eth when token info subgraph is ready
        [ChainId.ARBITRUM]: 'https://api.studio.thegraph.com/query/72851/lif3-mainnet/v0.0.1', // TODO: replace for eth when token info subgraph is ready
        [ChainId.LIF3CHAIN_TESTNET]: 'https://testnet-graph-node.lif3chain.com/subgraphs/name/lif3v2-subgraph',
      }

    case 'FirebirdSwap': {
      return {
        [ChainId.FANTOM]: 'https://api.thegraph.com/subgraphs/name/eerieeight/spookyswap',
        [ChainId.BSC]: 'https://api.thegraph.com/subgraphs/name/mmontes11/pancakeswap',
      }
    }

    case 'TombSwap':
      return {
        [ChainId.FANTOM]: 'https://graph-node.tomb.com/subgraphs/name/tombswap-subgraph',
      }

    default:
      return {
        [ChainId.TOMBCHAINTESTNET]: 'http://168.119.232.67:8000/subgraphs/name/lifeswap',
        [ChainId.TOMB]: 'https://graph-node.lif3.com/subgraphs/name/lifeswap',
        [ChainId.FANTOM]: 'https://graph-node.tomb.com/subgraphs/name/tombswap-subgraph',
        [ChainId.POLYGON]: 'https://api.studio.thegraph.com/query/72851/lif3-matic/v0.0.1',
        [ChainId.AMOY_TESTNET]: 'https://api.studio.thegraph.com/query/72851/lif3-matic/v0.0.1',
      }
  }
}
