import { Percent, TradeType } from '@uniswap/sdk-core'
import { useContext, useState } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'
import { Trade } from '@uniswap/v3-sdk'
import { Currency } from 'sdk/v3'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
  priceImpactSeverity,
}: {
  trade: Trade<Currency, Currency, TradeType>
  allowedSlippage: Percent
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  priceImpactSeverity: any
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)

  return (
    <>
      <AutoColumn gap="0px">
        <RowBetween align="center">
          <Text fontWeight={400} fontSize={14} color={theme.primaryText1}>
            Price
          </Text>
          <Text
            fontWeight={500}
            fontSize={14}
            color={theme.primaryText1}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px',
            }}
          >
            {showInverted
              ? `${trade.executionPrice.invert().toSignificant(6)} ${trade.inputAmount.currency.symbol} / ${
                  trade.outputAmount.currency.symbol
                }`
              : `${trade.executionPrice.toSignificant(6)} ${trade.outputAmount.currency.symbol} / ${
                  trade.inputAmount.currency.symbol
                }`}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <Repeat size={14} />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.primaryText1}>
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <TYPE.black fontSize={14}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.minimumAmountOut(allowedSlippage)?.toSignificant(4) ?? '-'
                : trade.maximumAmountIn(allowedSlippage)?.toSignificant(4) ?? '-'}
            </TYPE.black>
            <TYPE.black fontSize={14} marginLeft={'4px'}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        <ButtonError
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={priceImpactSeverity > 2}
          style={{ margin: '10px 0 0 0' }}
        >
          {priceImpactSeverity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
