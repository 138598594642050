import { useMemo } from 'react'

import { getContract } from '../utils/getContract'
import { useAccountAddress } from './useAccountAddress'
import { useActiveWeb3React } from './useActiveWeb3React'

export const useContract = (address: string | undefined, ABI: any, withSignerIfPossible = true) => {
  const { library } = useActiveWeb3React()
  const commonAccount = useAccountAddress()

  return useMemo(() => {
    if (!address || !ABI || !library) return null

    try {
      return getContract(address, ABI, library, withSignerIfPossible && commonAccount ? commonAccount : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, commonAccount])
}
