import axios from 'axios'
import { NATIVE_CURRENCY } from 'sdk'
import { useChainId } from 'hooks'
import { useSelectedCurrencies } from 'state/swap/hooks'
import { odosBaseUrl } from 'sdk/constants'

export const useODOSTokensPrices = () => {
  const chainId = useChainId()
  const tokenPriceBaseUrl = `${odosBaseUrl}/pricing/token`

  const { inputCurrency, wrappedInputCurrency, wrappedOutputCurrency, outputCurrency } = useSelectedCurrencies()

  const fetchSelectedTokensPrices = async () => {
    const from = (
      inputCurrency === NATIVE_CURRENCY[chainId]
        ? '0x0000000000000000000000000000000000000000'
        : wrappedInputCurrency?.address
    )?.toLowerCase()

    const to = (
      outputCurrency === NATIVE_CURRENCY[chainId]
        ? '0x0000000000000000000000000000000000000000'
        : wrappedOutputCurrency?.address
    )?.toLowerCase()

    if (!from || !to) return

    const { data: inputPriceUSDResponse } = await axios.get(`${tokenPriceBaseUrl}/${chainId}/${from}`)
    const { data: outputPriceUSDResponse } = await axios.get(`${tokenPriceBaseUrl}/${chainId}/${to}`)

    const { price: inputPriceUSD } = inputPriceUSDResponse
    const { price: outputPriceUSD } = outputPriceUSDResponse

    return +outputPriceUSD <= 0 ? 0 : +inputPriceUSD / +outputPriceUSD
  }

  return { fetchSelectedTokensPrices }
}
