import React from 'react'

export const CardIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 13" fill="none">
      <path
        d="M8.5 7.61111H8.50556M1 2.61111V10.3889C1 11.0025 1.49746 11.5 2.11111 11.5H9.88889C10.5025 11.5 11 11.0025 11 10.3889V4.83333C11 4.21968 10.5025 3.72222 9.88889 3.72222L2.11111 3.72222C1.49746 3.72222 1 3.22476 1 2.61111ZM1 2.61111C1 1.99746 1.49746 1.5 2.11111 1.5H8.77778M8.77778 7.61111C8.77778 7.76452 8.65341 7.88889 8.5 7.88889C8.34659 7.88889 8.22222 7.76452 8.22222 7.61111C8.22222 7.4577 8.34659 7.33333 8.5 7.33333C8.65341 7.33333 8.77778 7.4577 8.77778 7.61111Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
