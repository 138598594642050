import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import SwapRoute from './SwapRoute'
import { Text } from 'rebass'
import { Separator } from 'components/swap/styleds'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/v3'
import { Ether } from 'sdk/v3'
import { useChainId } from 'hooks'
import { RowBetween, RowFixed } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { AutoColumn } from 'components/Column'
import { InterfaceTrade } from 'hooks/v3/types'
import { useTheme } from 'styled-components'
import { Box } from 'shared'
import { formatToCurrency } from 'utils/formatters'

interface AdvancedSwapDetailsProps {
  trade: InterfaceTrade<Currency, Currency, TradeType>
  allowedSlippage: Percent
  syncing?: boolean
}

function TextWithLoadingPlaceholder({
  syncing,
  width,
  children,
}: {
  syncing: boolean
  width: number
  children: JSX.Element
}) {
  return syncing ? <div style={{ height: '15px', width: `${width}px` }} /> : children
}

export function AdvancedSwapDetails({ trade, allowedSlippage, syncing = false }: AdvancedSwapDetailsProps) {
  const chainId = useChainId()
  const theme = useTheme()
  const nativeCurrency = Ether.onChain(chainId)

  return (
    <AutoColumn gap="4px">
      {!trade.gasUseEstimateUSD || !chainId || !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId) ? null : (
        <RowBetween>
          <MouseoverTooltip
            text={`The fee paid to miners who process your transaction. This must be paid in ${nativeCurrency.symbol}.`}
          >
            <Text fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              Network fee
            </Text>
          </MouseoverTooltip>
          <Text color={theme.primaryText1} fontSize={13}>
            ~${trade.gasUseEstimateUSD.toFixed(2)}
          </Text>
        </RowBetween>
      )}
      <RowBetween>
        <RowFixed>
          <MouseoverTooltip
            text={`The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will
                revert.`}
          >
            <Text fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              Minimum output
            </Text>
          </MouseoverTooltip>
        </RowFixed>
        <TextWithLoadingPlaceholder syncing={syncing} width={70}>
          <Text color={theme.primaryText1} fontSize={13}>
            {trade.tradeType === TradeType.EXACT_INPUT
              ? `${formatToCurrency(Number(trade.minimumAmountOut(allowedSlippage).toSignificant(6)))} ${
                  trade.outputAmount.currency.symbol
                }`
              : `${formatToCurrency(Number(trade.maximumAmountIn(allowedSlippage).toSignificant(6)))} ${
                  trade.inputAmount.currency.symbol
                }`}
          </Text>
        </TextWithLoadingPlaceholder>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <MouseoverTooltip
            text={
              'The amount you expect to receive at the current market price. You may receive less or more if the market price changes while your transaction is pending.'
            }
          >
            <Text fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              Expected output
            </Text>
          </MouseoverTooltip>
        </RowFixed>
        <TextWithLoadingPlaceholder syncing={syncing} width={65}>
          <Text color={theme.primaryText1} fontSize={13}>{`${formatToCurrency(
            Number(trade.outputAmount.toSignificant(6))
          )} ${trade.outputAmount.currency.symbol}`}</Text>
        </TextWithLoadingPlaceholder>
      </RowBetween>
      <Box m="10px 0">
        <Separator />
      </Box>
      <RowBetween>
        <Text fontSize={13} fontWeight={400} color={theme.secondaryText1}>
          Order routing
        </Text>
        <SwapRoute data-testid="swap-route-info" trade={trade} syncing={syncing} />
      </RowBetween>
    </AutoColumn>
  )
}
