import { THEME_KEY } from '../constants/localStorage'
import { Theme } from '../types'
import { useLocalStorage } from './useLocalStorage'

const themes: Array<Theme> = ['light', 'dark']

export const getNextTheme = (theme: Theme): Theme => {
  const activeThemeIndex = themes.indexOf(theme)

  if (activeThemeIndex === -1) {
    return themes[0]
  }

  const nextThemeIndex = activeThemeIndex === 0 ? activeThemeIndex + 1 : activeThemeIndex - 1

  return themes[nextThemeIndex]
}

export const useThemeGeneral = (): [Theme, () => void] => {
  const [theme, setTheme] = useLocalStorage<Theme>(THEME_KEY, 'dark')

  const changeTheme = () => {
    setTheme(getNextTheme(theme))
  }

  return [theme, changeTheme]
}
