import { StaticJsonRpcProvider, JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { isAddress } from '@ethersproject/address'

export function getSigner(account: string, library?: Web3Provider | StaticJsonRpcProvider): JsonRpcSigner | null {
  return library ? library.getSigner(account).connectUnchecked() : null
}

export const getProviderOrSigner = (
  account?: string | null,
  library?: Web3Provider | StaticJsonRpcProvider
): Web3Provider | JsonRpcSigner | StaticJsonRpcProvider | null | undefined => {
  return account ? getSigner(account, library) : library
}

export const getContract = (
  address: string,
  ABI: any,
  library?: Web3Provider | StaticJsonRpcProvider,
  account?: string | null
): Contract => {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(account, library) as any)
}
