import { useChainId } from 'hooks'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import { useQuery } from 'react-query'

export const useNftOwner = (tokenId: string | undefined) => {
  const positionManager = useV3NFTPositionManagerContract()
  const chainId = useChainId()

  return useQuery(
    ['v3', 'position-owner', tokenId, chainId],
    async () => {
      const result = await positionManager?.ownerOf(tokenId)

      return result
    },
    { enabled: Boolean(tokenId) }
  )
}
