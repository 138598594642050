import { Base } from 'components/Button'
import styled from 'styled-components'
import { ReactComponent as DropDown } from 'assets/images/dropdown.svg'
import { Box } from 'shared'

export const GlobalPanelBox = styled(Box)`
  position: absolute;
  top: calc(100% + 5px);
  right: 20px;

  display: flex;
  flex-direction: column;
  min-width: 220px;
  border-radius: 5px;
  background: ${({ theme }) => theme.componentBg4};
  color: ${({ theme }) => theme.accentText};
  padding-bottom: 5px;
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;

  & > div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    right: 10px;
  `};
`

export const GlobalPanelRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
`

export const GlobalPanelAction = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
  color: ${({ theme }) => theme.primaryText1};

  &:hover > * {
    color: ${({ theme }) => theme.secondaryText1};
    transition: color 0.3s;
  }
`

export const GlobalPanelLanaugeTitle = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.accentText};
  justify-content: center;
  display: flex;
  width: 100%;
`

export const GlobalPanelTitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryText1};
`

export const GlobalPanelText = styled(GlobalPanelTitle)`
  font-weight: 400;
`

export const BackButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 400;
  cursor: pointer;
`

export const LanguageHeadericon = styled(DropDown)`
  height: 35%;
  transform: rotate(90deg);
  transform-origin: center;

  path {
    stroke: currentColor;
    stroke-width: 1.5px;
  }
`

export const GlobalPanelHr = styled.hr`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.border1};
  border: none;
  margin: 0;
`

export const GlobalPanelLangIcon = styled.img`
  display: flex;
  margin-right: 4px;
  height: 11px;
  width: 14px;
`

export const GlobalPanelArrow = styled(DropDown)`
  height: 35%;
  transform: rotate(-90deg);
  transform-origin: center;

  path {
    stroke: ${({ theme }) => theme.primaryText1};
    stroke-width: 1.5px;
  }
`
