import { getAddress } from '@ethersproject/address'
import { getExchangeSubgraphUrl } from 'shared'

import request, { gql } from 'graphql-request'
import { useListedTokens } from 'hooks/Tokens'
import { useQuery } from 'react-query'
import { ChainId } from 'sdk'
import { useChainId } from 'hooks'

const gqlQueryString = gql`
  query {
    tokens(first: 30, orderBy: tradeVolumeUSD, orderDirection: desc) {
      id
      symbol
      name
      decimals
      derivedETH
    }
    ethPrice: bundle(id: 1) {
      ethPrice
    }
  }
`

export const useGetDexTokensQuery = () => {
  const listedTokens = useListedTokens()
  const chainId = useChainId()

  return useQuery(
    ['dex-tokens', chainId],
    async () => {
      const result = await request<Record<string, any>>(getExchangeSubgraphUrl('LIF3Swap')[chainId], gqlQueryString)

      const tokens = result.tokens.map((token) => {
        const checksummedAddr = getAddress(token.id)
        const listedToken = listedTokens[checksummedAddr]

        const tokenName = listedToken?.name ?? token.name
        const tokenSymbol = listedToken?.symbol ?? token.symbol

        return {
          address: checksummedAddr,
          symbol: tokenSymbol,
          name: tokenName,
          decimals: Number(token.decimals),
          logo: listedToken ? listedToken.logo : null,
          derivedETH: token.derivedETH,
        }
      })
      return {
        tokens,
        ethPrice: result.ethPrice.ethPrice,
      }
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}
