import { Protocol } from '@uniswap/router-sdk'
import { Currency } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Row, { AutoRow } from 'components/Row'
import { Box, Text } from 'rebass'
import styled from 'styled-components/macro'
import { RoutingDiagramEntry } from './getRoutingDiagramEntries'
import CurrencyLogo from 'components/CurrencyLogo'
import { MouseoverTooltip } from 'components/Tooltip'

const Wrapper = styled(Box)`
  align-items: center;
  width: 100%;
  gap: 4px;
`

const RouteContainerRow = styled(Row)`
  display: flex;
  gap: 4px;
`

const RouteRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

const PoolBadge = styled(Badge)`
  display: flex;
`

const OpaqueBadge = styled(Badge)`
  display: grid;
  font-size: 12px;
  grid-gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
  z-index: 10;
`

const ProtocolBadge = styled.span`
  color: ${({ theme }) => theme.text1};
  padding: 4px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.text1};
  width: 20px;
  height: 20px;
  z-index: 11;
`

const BadgeText = styled(Text)`
  word-break: normal;
`

export default function RoutingDiagram({
  currencyIn,
  currencyOut,
  routes,
}: {
  currencyIn: Currency
  currencyOut: Currency
  routes: RoutingDiagramEntry[]
}) {
  return (
    <Wrapper>
      {routes.map((entry, index) => (
        <RouteContainerRow key={index}>
          <CurrencyLogo currency={currencyIn} size="28px" />
          -
          <Route entry={entry} />
          -
          <CurrencyLogo currency={currencyOut} size="28px" />
        </RouteContainerRow>
      ))}
    </Wrapper>
  )
}

function Route({ entry: { percent, path, protocol } }: { entry: RoutingDiagramEntry }) {
  return (
    <RouteRow gap="4px">
      <OpaqueBadge>
        {protocol === Protocol.MIXED ? (
          <ProtocolBadge>V3 + V2</ProtocolBadge>
        ) : (
          <ProtocolBadge>{protocol.toUpperCase()}</ProtocolBadge>
        )}
        <BadgeText fontSize={12} style={{ minWidth: 'auto' }}>
          {percent.toSignificant(2)}%
        </BadgeText>
      </OpaqueBadge>
      <AutoRow gap="1px" width="100%" style={{ justifyContent: 'space-evenly', zIndex: 2 }}>
        {path.map(([currency0, currency1, feeAmount], index) => (
          <Pool key={index} currency0={currency0} currency1={currency1} feeAmount={feeAmount} />
        ))}
      </AutoRow>
    </RouteRow>
  )
}

function Pool({ currency0, currency1, feeAmount }: { currency0: Currency; currency1: Currency; feeAmount: FeeAmount }) {
  // TODO - link pool icon to info.uniswap.org via query params
  return (
    <MouseoverTooltip text={`${currency0?.symbol + '/' + currency1?.symbol + ' ' + feeAmount / 10000}% pool`}>
      <PoolBadge>
        <Box margin="0 8px 0 0">
          <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={20} />
        </Box>
        <Text fontSize={12}>{feeAmount / 10000}%</Text>
      </PoolBadge>
    </MouseoverTooltip>
  )
}
