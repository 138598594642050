import { Currency, CurrencyAmount, NATIVE_CURRENCY, Token, TokenAmount, WNATIVE } from 'sdk'

export function wrappedCurrency(currency: Currency | undefined | null, chainId: number): Token | undefined {
  return currency?.symbol === NATIVE_CURRENCY[chainId].symbol
    ? WNATIVE[chainId]
    : currency instanceof Token
    ? currency
    : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: number
): TokenAmount | undefined {
  const token = currencyAmount ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

export function unwrappedToken(token: Token, chainId: number): Currency {
  if (token.equals(WNATIVE[chainId])) return NATIVE_CURRENCY[chainId]
  return token
}
