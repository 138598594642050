import styled from 'styled-components'

export const TimeframeButton = styled.button<{ active: boolean; isTokenChart?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ isTokenChart }) => (isTokenChart ? '6px 12px' : '6px')};
  min-width: ${({ isTokenChart }) => (isTokenChart ? '40px' : '32px')};
  min-height: ${({ isTokenChart }) => (isTokenChart ? '33px' : '24px')};
  border-radius: ${({ isTokenChart }) => (isTokenChart ? '5px' : '50%')};
  background: ${({ theme, active, isTokenChart }) =>
    active ? (isTokenChart ? theme.white : theme.primary1) : 'transparent'};
  color: ${({ theme, active }) => (active ? theme.text1 : theme.white)};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  font-size: 15px;
  line-height: 17px;

  &:hover {
    color: ${({ theme, active }) => (active ? theme.text1 : theme.accentText)};
  }
`

export const PriceRow = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 13px;
  height: 60px;
  width: calc(100% - 230px);
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    position: initial;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 10px 10px;
    height: 66px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    gap: 6px;
    height: 60px;
    align-items: center;
  `};
`

export const DateRow = styled.div<{ isTokenChart?: boolean }>`
  width: ${({ isTokenChart }) => (isTokenChart ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: ${({ isTokenChart }) => (isTokenChart ? 'flex-end' : 'initial')};
  gap: ${({ isTokenChart }) => (isTokenChart ? '0' : '6px')};
  padding-left: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    padding-right: 20px;
  `};

  @media screen and (max-width: 480px) {
    padding-right: 5px;
  }
`
