import React, { useMemo } from 'react'
import { useChainId } from 'hooks'
import {
  EcosystemDropdownBox,
  EcosystemDropdownCol,
  EcosystemDropdownTitle,
  EcosystemDropdownList,
  EcosystemDropdownListItem,
  EcosystemDropdownListItemCol,
  EcosystemDropdownListItemTitle,
  EcosystemDropdownListItemText,
  ArrowRightStyledIcon,
  ChainIconStyled,
  AiIconStyled,
  L3UsdStyled,
  EnjoyIconStyled,
  ReserveStyled,
  WalletIconStyled,
  TradeIconStyled,
} from './styles'

export const EcosystemDropdown = ({ toggleIcon }: { toggleIcon?: (state: boolean) => void }) => {
  const chainId = useChainId()
  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  return (
    <EcosystemDropdownBox onMouseEnter={() => toggleIcon?.(true)} onMouseLeave={() => toggleIcon?.(false)}>
      <EcosystemDropdownCol>
        <EcosystemDropdownTitle>Ecosystem</EcosystemDropdownTitle>
        <EcosystemDropdownList>
          <EcosystemDropdownListItem to={{ pathname: '/wallet', search: chainIdOnlySearchParams.toString() }}>
            <WalletIconStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>Lif3 Wallet</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>
                A powerful yet simple self custody web3 wallet.
              </EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem>
          <EcosystemDropdownListItem to={{ pathname: '/chain', search: chainIdOnlySearchParams.toString() }}>
            <ChainIconStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>Lif3 Chain</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>The Future of Blockchain.</EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem>
          <EcosystemDropdownListItem as={'a'} href="https://trade.lif3.com/" target="_blank" rel="noreferrer">
            <TradeIconStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>Lif3 Trade (Paused)</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>
                Trade up to 50x leverage right from your wallet.
              </EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem>
          {/* <EcosystemDropdownListItem to={{ pathname: '/l3usd', search: chainIdOnlySearchParams.toString() }}>
            <ReserveStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>L3 Reserve</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>The Future of Transactions.</EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem> */}
          <EcosystemDropdownListItem to={{ pathname: '/ai', search: chainIdOnlySearchParams.toString() }}>
            <AiIconStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>Lif3 AI</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>Making DeFi Simple.</EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem>
          <EcosystemDropdownListItem to={'/l3usd?chainId=137'}>
            <L3UsdStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>L3USD</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>Next generation stable-asset.</EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem>
          <EcosystemDropdownListItem to={{ pathname: '/events', search: chainIdOnlySearchParams.toString() }}>
            <EnjoyIconStyled />
            <EcosystemDropdownListItemCol>
              <EcosystemDropdownListItemTitle>Lif3 Events</EcosystemDropdownListItemTitle>
              <EcosystemDropdownListItemText>Be the Lif3 of the Party.</EcosystemDropdownListItemText>
            </EcosystemDropdownListItemCol>
            <ArrowRightStyledIcon />
          </EcosystemDropdownListItem>
        </EcosystemDropdownList>
      </EcosystemDropdownCol>
    </EcosystemDropdownBox>
  )
}
