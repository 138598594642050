import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelectedCurrencies } from 'state/swap/hooks'

export const PriceHelmet: React.FC = () => {
  const { inputCurrency, outputCurrency } = useSelectedCurrencies()

  return inputCurrency && outputCurrency ? (
    <Helmet defer={false}>
      <title>
        {inputCurrency?.symbol}-{outputCurrency?.symbol} | LIF3
      </title>
    </Helmet>
  ) : null
}
