import { ChainId, WNATIVE } from 'sdk'

const W_ETH_ADDRESSES: {
  [chainId: number]: string
} = {
  [ChainId.METIS]: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  [ChainId.TOMBCHAINTESTNET]: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  [ChainId.TOMB]: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  [ChainId.FANTOM]: WNATIVE[ChainId.FANTOM].address,
  [ChainId.BSC]: WNATIVE[ChainId.BSC].address,
  [ChainId.POLYGON]: WNATIVE[ChainId.POLYGON].address,
}

export { W_ETH_ADDRESSES }
