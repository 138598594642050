import React from 'react'
import qs from 'qs'
import { theme, useMediaWith } from 'theme'

import { TradeForm } from '../../TradeForm'
import { Box } from 'shared'

import { MobileMenu } from './MobileMenu'
import { PoolData } from '../../PoolData'
import { Charts } from '../../Charts'
import { LiquidityEvents } from 'pages/Trade/PoolData/PoolTables/LiquidityEvents'
import { PairTransactions } from 'pages/Trade/PoolData/PoolTables/PairTransactions'
import useTheme from 'hooks/useTheme'
import { TokenInfo } from 'pages/Trade/TradeForm/TokenInfo'
import { useMedia } from 'react-use'
import { MergedTransactions } from 'pages/Trade/PoolData/PoolTables/MergedTransactions'
import { OrdersInfo } from 'pages/Trade/OrdersInfo'
import {
  DesktopLayout,
  MobileLayout,
  MobileMenuWrapper,
  MobileScreens,
  MobileScreenWrapper,
  ChartsWrapper,
  TablesWrapper,
  TablesBox,
  TablesCol,
  TradeBox,
  InfoBox,
  MobileScreenBox,
  MergedWrapper,
  OrderInfoWrapper,
} from '../styles'
import { BuyCryptoLink } from 'components/BuyCryptoLink'
import { SwapType } from 'state/swap/reducer'
import { SwapTabs } from 'pages/Trade/SwapTabs'

export enum Screen {
  // ORDERS = 'orders',
  TRADES = 'trades',
  CHART = 'chart',
  TOKEN_INFO = 'tokenInfo',
}

type ClassicLayoutType = {}

export const ProLayout = (props: ClassicLayoutType) => {
  const { screen } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { screen?: Screen }

  const activeScreen = screen ?? Screen.TRADES

  const { upToMedium } = useMediaWith()
  const upToExtraHeight = useMedia('(max-height: 1220px)')

  return upToMedium ? (
    <MobileLayout>
      <MobileMenuWrapper>
        <MobileMenu activeScreen={activeScreen} />
      </MobileMenuWrapper>
      <MobileScreens>
        {/* <MobileScreenWrapper active={activeScreen === Screen.ORDERS}>
          <PoolData />
        </MobileScreenWrapper> */}
        <MobileScreenWrapper active={activeScreen === Screen.TRADES}>
          <SwapTabs />
          <TradeForm />
          {/* <BuyCryptoLink /> */}
          {/* {tab === 'Limit' && (
            <OrderInfoWrapper>
              <OrdersInfo />
            </OrderInfoWrapper>
          )} */}
        </MobileScreenWrapper>
        <MobileScreenWrapper active={activeScreen === Screen.CHART}>
          <MobileScreenBox>
            <Charts isMobile />
          </MobileScreenBox>
        </MobileScreenWrapper>
        {/* <MobileScreenWrapper active={activeScreen === Screen.INFO}>
          <TokenInfo />
        </MobileScreenWrapper> */}
      </MobileScreens>
    </MobileLayout>
  ) : (
    <DesktopLayout isOrdersLayout={false}>
      <ChartsWrapper>
        <Charts />
      </ChartsWrapper>
      {/* <TablesBox>
        {upToExtraHeight ? (
          <MergedWrapper>
            <MergedTransactions />
          </MergedWrapper>
        ) : (
          <TablesCol>
            <TablesWrapper>
              <PairTransactions />
            </TablesWrapper>
            <TablesWrapper>
              <LiquidityEvents />
            </TablesWrapper>
          </TablesCol>
        )}
      </TablesBox> */}
      <TradeBox>
        <SwapTabs />
        <TradeForm />
        {/* <BuyCryptoLink /> */}
        {/* {tab === 'Limit' && (
          <OrderInfoWrapper>
            <OrdersInfo />
          </OrderInfoWrapper>
        )} */}
      </TradeBox>
      {/* <InfoBox>
        <TokenInfo />
      </InfoBox> */}
    </DesktopLayout>
  )
}
