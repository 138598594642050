import { useQuery } from 'react-query'
import axios from 'axios'

export const useCGAssetPlatformsList = () => {
  return useQuery(['use-coingecko-asset-prices'], async () => {
    try {
      const response = await axios.get('https://pro-api.coingecko.com/api/v3/asset_platforms', {
        headers: { 'x-cg-pro-api-key': 'CG-BQ8mb7aut1hmNytgwmHofZoU' },
      })

      return response.data
    } catch (error) {
      console.error(error)
      // TODO: Change this part to normal error handling
      throw error
    }
  })
}
