import { useIncentivizedPools } from './useIncentivizedPools'
import { getAddress } from '@ethersproject/address'

export const useIsPoolIncentivized = (poolId: string) => {
  const incentivizedPools = useIncentivizedPools()
  const incentivizedPoolsIds = incentivizedPools.map((pool) => getAddress(pool.id))

  const result = { isIncentivized: incentivizedPoolsIds.includes(poolId) }

  return result
}
