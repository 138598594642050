import styled, { keyframes } from 'styled-components'
import { AppLink } from '../AppLink'

export const PromoSection = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 511px;
  grid-template-rows: auto;
  align-items: center;
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;
  gap: 75px;
  padding: 120px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 110px 20px 0;
    gap: 70px 40px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 80px 20px 0;
    gap: 40px 30px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 20px 20px 0;
    gap: 40px 20px;
  `}
`

export const PromoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    padding: 0 20px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: flex-end;
  `}
`

export const PromoSideWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 50px;
  width: 100%;
  max-width: 490px;
  border-radius: 20px;
  gap: 10px;

  background: ${({ theme }) => theme.componentBg4};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 34px 30px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
    max-width: 80%;
    margin-top: -20px;
    gap: 20px;
    text-align: center;
    order: 2;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
    margin-bottom: 0;
  `};
`

export const PromoSideImages = styled.div<{ isLifeWallet?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  height: 477px;

  ${({ theme, isLifeWallet }) => theme.mediaWidth.upToSmall`
    max-width: 284px;
    height: 300px;
  `};
`

const moveLeftPhone = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0);
  }
`

const moveRightPhone = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
`

export const PromoSideLeftImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 268px;
  height: 376px;
  object-fit: contain;
  animation: ${moveLeftPhone} 8s infinite linear;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 170px;
    height: 237px;
  `};
`

export const PromoSideRightImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 290px;
  height: 417px;
  object-fit: contain;
  animation: ${moveRightPhone} 8s infinite linear;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 183px;
    height: 263px;
  `};
`

export const PromoBox = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.primaryText1};
  max-width: 652px;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
    max-width: 100%;
  `}
`

export const PromoNumbersBox = styled.div`
  margin-top: 50px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 40px;
  `}
`

export const PromoLinksItem = styled.a`
  width: 100%;
  max-width: 155px;
  display: block;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`

export const PromoLinksItemLogo = styled.img`
  max-width: 155px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 122px;
  `}
`

export const PromoLinksRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0px;
  `}
`

export const PromoHeading = styled.h1`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 0 25px;
  font-weight: 500;
  font-size: 51px;
  line-height: 49px;
  justify-content: center;
  min-width: 420px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
    flex-grow: 1;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
     font-size: 30px;
     line-height: 40px;
  `}
`

export const PromoHeadingLeft = styled.span`
  height: 60px;
  padding-right: 180px;
  & > svg {
    width: 159px;
    height: 60px;
  }
  & > span {
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-right: auto;
    padding-right: 130px;
    height: 40px;

    & > svg {
      width: 100px;
      height: 40px;
    }
  `}
`

export const PromoHeadingRight = styled.span`
  position: absolute;
  width: 0px;
`

export const PromoHeadingBox = styled.span`
  position: relative;
`

export const PromoText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 18px;
    line-height: 24px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
    line-height: 22px;
  `}
`

export const PromoSideLabel = styled.h4`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`

export const PromoSideHeading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
`

export const PromoSideText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.accentText};
`

export const PromoLink = styled(AppLink)`
  display: flex;
  width: 250px;
  margin-top: 18px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
`

export const ChainIcon = styled.img`
  max-width: 36px;
  width: 100%;
  height: 100%;
  display: block;
`

export const PromoTokens = styled.div`
  grid-column: span 2;
  width: 100%;
`

export const ChainIconBox = styled.div`
  margin-top: -10px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 36px;
  height: 79px;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    height: 68px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    height: 60px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 78px;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 36px;
  `}
`
