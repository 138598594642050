import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { QuoteOptions, Route, encodeRouteToPath, toHex } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { useMemo } from 'react'
import { useAllV3Routes } from './useAllV3Routes'
import { InterfaceTrade, TradeState } from './types'
import { useQuery } from 'react-query'
import { QUOTER_ADDRESSES } from 'constants/v3/addresses'
import { useChainId } from 'hooks'
import { multicallFailSafe } from 'shared'

import QuoterV2Json from '@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
import { PROVIDERS_BY_CHAIN } from 'connectors'

const { abi: QuoterV2ABI } = QuoterV2Json

function quoteCallParameters<TInput extends Currency, TOutput extends Currency>(
  route: Route<TInput, TOutput>,
  amount: CurrencyAmount<TInput | TOutput>,
  tradeType: TradeType,
  options: QuoteOptions = {}
) {
  const singleHop = route.pools.length === 1
  const quoteAmount: string = toHex(amount.quotient)

  if (singleHop) {
    const baseQuoteParams = [
      route.tokenPath[0].address,
      route.tokenPath[1].address,
      quoteAmount,
      route.pools[0].fee,
      toHex(options?.sqrtPriceLimitX96 ?? 0),
    ]

    const tradeTypeFunctionName =
      tradeType === TradeType.EXACT_INPUT ? 'quoteExactInputSingle' : 'quoteExactOutputSingle'

    return { name: tradeTypeFunctionName, params: [baseQuoteParams] }
  } else {
    const path: string = encodeRouteToPath(route, tradeType === TradeType.EXACT_OUTPUT)

    const tradeTypeFunctionName = tradeType === TradeType.EXACT_INPUT ? 'quoteExactInput' : 'quoteExactOutput'

    return { name: tradeTypeFunctionName, params: [path, quoteAmount] }
  }
}

/**
 * Returns the best v3 trade for a desired swap
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useClientSideV3Trade<TTradeType extends TradeType>(
  tradeType: TTradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): { state: TradeState; trade: InterfaceTrade<Currency, Currency, TTradeType> | undefined } {
  const chainId = useChainId()
  const [currencyIn, currencyOut] =
    tradeType === TradeType.EXACT_INPUT
      ? [amountSpecified?.currency, otherCurrency]
      : [otherCurrency, amountSpecified?.currency]
  const { routes, loading: routesLoading } = useAllV3Routes(currencyIn, currencyOut)

  // Chains deployed using the deploy-v3 script only deploy QuoterV2.
  // const useQuoterV2 = useMemo(() => Boolean(chainId && isCelo(chainId)), [chainId])
  const callDatas = useMemo(
    () =>
      amountSpecified
        ? routes.map((route) => quoteCallParameters(route, amountSpecified, tradeType, { useQuoterV2: true }))
        : [],
    [amountSpecified, routes, tradeType]
  )

  const quoteResultsQuery = useQuery(
    [
      'v3',
      'quoter-call',
      routes.map((route) => `${route.input.symbol}-${route.output.symbol}-${amountSpecified?.toSignificant(6)}`),
      amountSpecified?.toExact(),
    ],
    async () => {
      // Create call objects
      const calls = callDatas.map(({ name, params }) => ({
        address: QUOTER_ADDRESSES[chainId],
        name,
        params,
        // gasRequired: chainId ? QUOTE_GAS_OVERRIDES[chainId] ?? DEFAULT_GAS_QUOTE : undefined,
      }))

      const response = await multicallFailSafe(QuoterV2ABI, calls, PROVIDERS_BY_CHAIN[chainId], chainId)

      return response
    }
  )

  const quotesResults = quoteResultsQuery.data

  const currenciesAreTheSame = useMemo(
    () => currencyIn && currencyOut && (currencyIn.equals(currencyOut) || currencyIn.wrapped.equals(currencyOut)),
    [currencyIn, currencyOut]
  )

  return useMemo(() => {
    if (!amountSpecified || !currencyIn || !currencyOut || currenciesAreTheSame || quoteResultsQuery.failureCount > 0) {
      return {
        state: TradeState.INVALID,
        trade: undefined,
      }
    }

    if (routesLoading || quoteResultsQuery.isLoading) {
      return {
        state: TradeState.LOADING,
        trade: undefined,
      }
    }

    const { bestRoute, amountIn, amountOut } = quotesResults.reduce(
      (
        currentBest: {
          bestRoute: Route<Currency, Currency> | null
          amountIn: CurrencyAmount<Currency> | null
          amountOut: CurrencyAmount<Currency> | null
        },
        result,
        i
      ) => {
        if (!result) return currentBest

        const [amountResult] = result

        // overwrite the current best if it's not defined or if this route is better
        if (tradeType === TradeType.EXACT_INPUT) {
          const amountOut = CurrencyAmount.fromRawAmount(currencyOut, amountResult?.toString() ?? '0')
          if (currentBest.amountOut === null || JSBI.lessThan(currentBest.amountOut.quotient, amountOut.quotient)) {
            return {
              bestRoute: routes[i],
              amountIn: amountSpecified,
              amountOut,
            }
          }
        } else {
          const amountIn = CurrencyAmount.fromRawAmount(currencyIn, amountResult?.toString() ?? '0')
          if (currentBest.amountIn === null || JSBI.greaterThan(currentBest.amountIn.quotient, amountIn.quotient)) {
            return {
              bestRoute: routes[i],
              amountIn,
              amountOut: amountSpecified,
            }
          }
        }

        return currentBest
      },
      {
        bestRoute: null,
        amountIn: null,
        amountOut: null,
      }
    )

    if (!bestRoute || !amountIn || !amountOut) {
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: undefined,
      }
    }

    return {
      state: TradeState.VALID,
      trade: new InterfaceTrade({
        v2Routes: [],
        v3Routes: [
          {
            routev3: bestRoute,
            inputAmount: amountIn,
            outputAmount: amountOut,
          },
        ],
        tradeType,
      }),
    }
  }, [amountSpecified, currenciesAreTheSame, currencyIn, currencyOut, quotesResults, routes, routesLoading, tradeType])
}
