import React, { useEffect, useState } from 'react'
import { TokenInfoBox, TokenPriceInfoDiff } from './styles'
import Row from 'components/Row'
import CurrencyLogo from 'components/CurrencyLogo'
import { Text } from 'rebass'
import { IconWrapper } from 'theme'
import { ArrowPriceIcon } from 'icons/ArrowPriceIcon'
import { Currency } from 'sdk'
import { CGCoinDetailsResponse } from 'hooks/coingecko/useCGCoinDetails'

type Props = {
  price: string
  diffPrice: string
  diffColor?: string
  currency: Currency | undefined
}

export const TokenPriceInfo: React.FC<Props> = (props) => {
  return (
    <TokenInfoBox>
      <Row gap="6px" alignItems="center">
        <CurrencyLogo currency={props.currency} size="32px" />
        <Text fontSize="20px">{props.currency?.name ?? '-'}</Text>
        <Text fontSize="20px" color={'rgba(255, 255, 255, 0.50)'}>
          {props.currency?.symbol ?? '-'}
        </Text>
      </Row>

      <Row gap="10px" alignItems="center">
        <Text fontSize="35px" minWidth="auto">
          {props.price}
        </Text>
        <TokenPriceInfoDiff diffColor={props.diffColor}>
          {props.diffPrice}
          <IconWrapper size="12px">
            <ArrowPriceIcon />
          </IconWrapper>
        </TokenPriceInfoDiff>
      </Row>
    </TokenInfoBox>
  )
}
