import qs from 'qs'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { ChainId, currentMainNetwork } from 'sdk'

export const BRIDGE_DEFAULT_STATE = {
  //replace to 0x3fb1e7d5d9c974141a5b6e5fa4edab0a7aa15c6a for testnet
  tokenAddress: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  chainFrom: ChainId.FANTOM,
  chainTo: ChainId.TOMB,
}

export const useBridgeState = () => {
  // const location = useLocation()

  const queryObject = qs.parse('', {
    ignoreQueryPrefix: true,
  }) as { currency?: string; from?: string; to: string }

  const parsedQs = {
    tokenAddress: queryObject.currency ?? '',
    chainFrom: queryObject.from ? Number(queryObject.from) : undefined,
    chainTo: queryObject.to ? Number(queryObject.to) : undefined,
  }

  useEffect(() => {
    if (!(queryObject.from && queryObject.to)) {
      parsedQs.tokenAddress = BRIDGE_DEFAULT_STATE.tokenAddress
      parsedQs.chainFrom = BRIDGE_DEFAULT_STATE.chainFrom
      parsedQs.chainTo = BRIDGE_DEFAULT_STATE.chainTo
    }
  }, [queryObject.from, queryObject.to])

  return parsedQs
}
