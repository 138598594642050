import { Currency, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import RoutingDiagram from './RoutingDiagram/RoutingDiagram'
import { Text } from 'rebass'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/v3'
import getRoutingDiagramEntries from './RoutingDiagram/getRoutingDiagramEntries'
import { InterfaceTrade } from 'hooks/v3/types'
import { useTheme } from 'styled-components'

export default function SwapRoute({
  trade,
  syncing,
}: {
  trade: InterfaceTrade<Currency, Currency, TradeType>
  syncing: boolean
}) {
  const { chainId } = useWeb3React()
  const theme = useTheme()
  const autoRouterSupported = false

  const routes = getRoutingDiagramEntries(trade)

  const gasPrice =
    // TODO(WEB-3303)
    // Can `trade.gasUseEstimateUSD` be defined when `chainId` is not in `SUPPORTED_GAS_ESTIMATE_CHAIN_IDS`?
    trade.gasUseEstimateUSD && chainId && SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId)
      ? trade.gasUseEstimateUSD.toFixed(2) === '0.00'
        ? '<$0.01'
        : '$' + trade.gasUseEstimateUSD.toFixed(2)
      : undefined

  return (
    <AutoColumn gap="4px">
      {syncing ? (
        <div style={{ width: '100%', height: '30px' }} />
      ) : (
        <RoutingDiagram
          currencyIn={trade.inputAmount.currency}
          currencyOut={trade.outputAmount.currency}
          routes={routes}
        />
      )}
      {autoRouterSupported && (
        <>
          {syncing ? (
            <div style={{ width: '100%', height: '15px' }} />
          ) : (
            <Text fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              {gasPrice ? `Best price route costs ~${gasPrice} in gas.` : null} This route optimizes your total output
              by considering split routes, multiple hops, and the gas cost of each step.
            </Text>
          )}
        </>
      )}
    </AutoColumn>
  )
}
