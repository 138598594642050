import styled from 'styled-components'
import {
  position,
  display,
  color,
  flexbox,
  layout,
  typography,
  space,
  border,
  DisplayProps,
  PositionProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  TypographyProps,
  SpaceProps,
  BorderProps,
} from 'styled-system'

export type BoxProps = DisplayProps &
  PositionProps &
  ColorProps &
  FlexboxProps &
  LayoutProps &
  TypographyProps &
  SpaceProps &
  BorderProps

export const Box = styled.div<BoxProps>`
  ${position}
  ${display}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${space}
  ${border}
`
