import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right-icon.svg'
import { ReactComponent as LifChainIcon } from 'assets/svg/nav/lif-chain.svg'
import { ReactComponent as L3ReserveIcon } from 'assets/svg/nav/l3-reserve.svg'
import { ReactComponent as LifAiIcon } from 'assets/svg/nav/lif-ai.svg'
import { ReactComponent as EnjoyLifIcon } from 'assets/svg/nav/lif-enjoy.svg'
import { ReactComponent as L3usdIcon } from 'assets/svg/nav/lif-stable.svg'
import { ReactComponent as WalletIcon } from 'assets/svg/lif3-wallet.svg'
import { ReactComponent as TradeIcon } from 'assets/svg/trade-icon.svg'

export const EcosystemDropdownBox = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  transform-origin: 50px 0px;
  transform: translateX(0) scale(0.8);
  max-width: 400px;

  display: flex;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  padding: 30px;
  width: max-content;
  border-radius: 12px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;
  visibility: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    top: -17px;
    left: 0;
  }

  @media screen and (max-width: 1510px) {
    transform: translateX(0) scale(0.8);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    right: 0;
    transform: translateX(20%);
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: 10px;
    top: auto;
    left: 11px;
    right: 11px;
    transform: translate(0, 100%);

    width: calc(100% - 22px);
    max-width: 100%;
    padding: 20px;

    background: ${({ theme }) => theme.componentBg4};
    backdrop-filter: blur(30px);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 15px 20px;
  `}
`

export const EcosystemDropdownCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`

export const EcosystemDropdownTitle = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.dropdownTitle};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export const EcosystemDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    gap: 10px;
  `}
`

export const ArrowRightStyledIcon = styled(ArrowRight)`
  width: 6px;
  height: 10px;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
  margin-left: auto;
`

export const ChainIconStyled = styled(LifChainIcon)`
  width: 24px;
  height: 22px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const WalletIconStyled = styled(WalletIcon)`
  width: 32px;
  height: 32px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const ReserveStyled = styled(L3ReserveIcon)`
  width: 25px;
  height: 25px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const AiIconStyled = styled(LifAiIcon)`
  width: 25px;
  height: 25px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const L3UsdStyled = styled(L3usdIcon)`
  width: 25px;
  height: 25px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const EnjoyIconStyled = styled(EnjoyLifIcon)`
  width: 25px;
  height: 23px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const TradeIconStyled = styled(TradeIcon)`
  width: 25px;
  height: 25px;
  align-self: flex-start;
  transition: color 0.3s;
`

export const EcosystemDropdownListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  gap: 16px;
  text-decoration: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.primaryText1};

  &:hover {
    background: #ffffff0d;
  }

  & > svg {
    flex: 0 0 auto;
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover > svg {
    color: ${({ theme }) => theme.secondaryText1};
  }

  &:hover > ${ArrowRightStyledIcon} {
    opacity: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 15px;
  `}
`

export const EcosystemDropdownListItemCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex-grow: 1;
`

export const EcosystemDropdownListItemTitle = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 15px;
  `}
`

export const EcosystemDropdownListItemText = styled.span`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`
