import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TradeDropdownBox } from 'components/Header/components/TradeDropdown/styles'
import { BuyDropdownBox } from 'components/Header/components/BuyDropdown/styles'
import { EarnDropdownBox } from 'components/Header/components/EarnDropdown/styles'
import { MoreDropdownBox } from 'components/Header/components/MoreDropdown/styles'
import { EcosystemDropdownBox } from 'components/Header/components/EcosystemDropdown/styles'
import { CommMobileDropdownBox } from 'components/Header/components/CommunityMobileDropdown/styles'
import { AnalyticsDropdownBox } from 'components/Header/components/AnalyticsDropdown/styles'
import { LegacyDropdownBox } from 'components/Header/components/EarnDropdownMobile/styles'
import Row from 'components/Row'

export const HeaderBox = styled.div<{ $isActive: boolean }>`
  position: fixed;
  bottom: -2px;
  left: 0;
  right: 0;
  z-index: 5;

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    z-index: -1;
  }

  &::before {
    position: fixed;
    top: 0;
    bottom: 70px;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: -1;
    background: rgba(0, 4, 16, 0.7);

    visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    pointer-events: ${({ $isActive }) => ($isActive ? 'initial' : 'none')};

    transition: opacity 0.2s;
  }
`

export const HeaderFrame = styled.div`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.componentBg4};
  padding: 0 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: none;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`

export const HeaderLinks = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: grid;
    grid-template-columns: repeat(5, auto);
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    gap: 0;
  `};
`

export const StLinkIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`

export const ExtendedStyledMenuButton = styled.button<{ $isActive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 15px 10px;
  font-size: 12px;
  width: 50px;
  min-height: 68px;
  color: ${({ theme }) => theme.white};
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0.5')};

  transition: opacity 0.3s;

  ${StLinkIcon} {
    color: ${({ theme }) => theme.white};
  }

  &:active {
    opacity: 1;
  }

  &:after {
    position: absolute;
    left: 1px;
    top: calc(100% - 3px);

    width: 100%;
    height: 1px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, #fff 47.92%, rgba(255, 255, 255, 0) 100%);
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    content: '';
    transition: transform 0.3s, opacity 0.5s;
  }
`

export const StLink = styled(Link)<{ $isActive?: boolean; isHighLight?: boolean }>`
  position: relative;
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  width: fit-content;
  max-width: 50px;
  transition: all 0.3s;
  align-items: center;
  padding: 15px 10px;
  min-height: 68px;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  flex-grow: 1;
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0.5')};

  transition: opacity 0.3s;

  ${StLinkIcon} {
    color: ${({ theme }) => theme.white};
  }

  &:active {
    opacity: 1;
  }

  &:after {
    position: absolute;
    left: 1px;
    top: calc(100% - 3px);

    width: 100%;
    height: 1px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, #fff 47.92%, rgba(255, 255, 255, 0) 100%);
    opacity: 0;
    content: '';
    transition: transform 0.3s, opacity 0.5s;
  }

  &:hover::after {
    opacity: 1;
  }
`

export const DropdownWrapper = styled.div<{ $isActive: boolean; isHidden?: boolean }>`
  justify-content: center;
  align-items: center;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  ${TradeDropdownBox},
  ${BuyDropdownBox},
  ${EarnDropdownBox},
  ${MoreDropdownBox},
  ${EcosystemDropdownBox},
  ${CommMobileDropdownBox},
  ${AnalyticsDropdownBox},
  ${LegacyDropdownBox} {
    visibility: ${({ $isActive }) => $isActive && 'visible'};
    opacity: ${({ $isActive }) => $isActive && '1'};
    transform: ${({ $isActive }) => $isActive && 'translate(0, -78px) scale(1)'};
  }
`
