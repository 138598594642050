import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

const i18nInstance = i18next.createInstance()

i18nInstance
  .use(Backend)
  .use(LanguageDetector)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: 'en',
    defaultNS: 'common',
    ns: ['common', 'buy-crypto'],
    preload: ['en'],
    interpolation: { escapeValue: false },
  })

export default i18nInstance
