import React from 'react'
import styled, { CSSProperties } from 'styled-components'

const TokenBadgeChip = styled.div<{ danger?: boolean }>`
  border-color: ${({ danger }) => (danger ? '#E2726B' : '#CCB175')};
  border-radius: 100px;
  border-width: 1px;
  border-style: solid;
  display: inline;
  font-size: 12px;
  padding: 1px 6px;
  font-weight: 500;
`

type Props = {
  style?: CSSProperties
  symbol: string
  danger?: boolean
}

export const TokenBadge: React.FC<Props> = (props) => {
  return (
    <TokenBadgeChip danger={props.danger} style={props.style}>
      {props.symbol}
    </TokenBadgeChip>
  )
}
