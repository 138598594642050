import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'
import { useApi, useLocalStorage } from 'shared'

export enum SimplexAuthState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  NEEDS_AUTH = 'NEEDS_AUTH',
  AUTHORIZED = 'AUTHORIZED',
}

export const useSimplexAuth = () => {
  const [authToken, setAuthToken] = useLocalStorage('simplex-auth-token', '')
  const { library, account } = useWeb3React<Web3Provider>()
  const api = useApi()

  const [authState, setAuthState] = useState(SimplexAuthState.INITIAL)

  const checkAuth = async () => {
    try {
      setAuthState(SimplexAuthState.LOADING)

      await api?.simplexApi.checkAuth({ authentication: authToken ?? '' })

      setAuthState(SimplexAuthState.AUTHORIZED)
    } catch (error) {
      setAuthState(SimplexAuthState.NEEDS_AUTH)

      throw error
    }
  }

  const tryAuth = async () => {
    try {
      setAuthState(SimplexAuthState.LOADING)

      await checkAuth()

      setAuthState(SimplexAuthState.AUTHORIZED)
    } catch (error) {
      setAuthToken('')

      setAuthState(SimplexAuthState.LOADING)

      const timestamp = Number(new Date().getTime().toString().slice(0, -3))
      const message = `I confirm that ${account} is my wallet and I agree to connect to tomb.com ${timestamp}`

      try {
        const signature = await library?.getSigner().signMessage(message)

        const authResponse = await api?.simplexApi.authorize({
          signature: signature ?? '',
          timestamp,
          address: account ?? '',
        })

        setAuthToken(authResponse?.data)

        setAuthState(SimplexAuthState.AUTHORIZED)
      } catch (error) {
        setAuthState(SimplexAuthState.NEEDS_AUTH)

        throw error
      }
    }
  }

  return { tryAuth, authState, authToken, checkAuth }
}
