import React from 'react'

export const LayoutContainerContext = React.createContext<
  | {
      breakpoint: 'xlDown' | 'lgDown' | 'mdDown' | 'smDown'
      breakpoints: {
        xlDown: number
        lgDown: number
        mdDown: number
        smDown: number
      }
    }
  | undefined
>(undefined)
