import axios from 'axios'
import { usePositionByTokenId } from './usePositionByTokenId'
import { useIncentiveByPosition } from './useIncentiveByPosition'
import { getAddress } from '@ethersproject/address'
import { useListedTokens } from 'hooks/Tokens'
import { Token } from 'sdk'
import { odosBaseUrl } from 'sdk/constants'
import { useV3StakingRewardsContract } from 'hooks/useContract'
import { useEffect, useState } from 'react'
import { getMappedIncentive } from 'utils/v3/getMappedIncentive'
import { MappedIncentive } from 'types/v3'
import { fromWei } from 'pages/Farms/utils/formatNumber'
import { useChainId } from 'hooks'

export type UseRewardInputProps = {
  tokenId: number
  poolId: string
}

export type UseRewardOutputProps = {
  userReward?: string
  userRewardUSD?: string
  incentiveReward?: string
  incentiveRewardUSD?: string
  rewardTokenInfo?: Token
  isStaked?: boolean
}

export const useUserRewardInfo = ({ tokenId, poolId }: UseRewardInputProps): UseRewardOutputProps => {
  const { position } = usePositionByTokenId({ tokenId })
  const { incentive } = useIncentiveByPosition({ position, poolId })
  const chainId = useChainId()
  const [isStaked, setIsStaked] = useState(false)
  const [mappedIncentive, setMappedIncentive] = useState<MappedIncentive>()
  const listedTokens = useListedTokens('V3')
  const rewardTokenAddress = mappedIncentive ? getAddress(mappedIncentive?.key.rewardToken) : ''
  const rewardTokenInfo = listedTokens[rewardTokenAddress]
  const [rewardTokenPrice, setRewardTokenPrice] = useState(0)
  const stakingRewardsContract = useV3StakingRewardsContract()
  const [userRewardAmount, setUserRewardAmount] = useState('0')
  const [incentiveRewardAmount, setIncentiveAmount] = useState(0)

  useEffect(() => {
    setIsStaked(!!position?.staked)
  }, [position])

  useEffect(() => {
    setMappedIncentive(getMappedIncentive(incentive))
  }, [incentive])

  useEffect(() => {
    const getUserRewardInfo = async () => {
      if (mappedIncentive) {
        if (isStaked) {
          try {
            const tokenPriceBaseUrl = `${odosBaseUrl}/pricing/token`
            const { data: priceUSDResponse } = await axios.get(
              `${tokenPriceBaseUrl}/${chainId}/${rewardTokenInfo.address}`
            )
            const { price: rewardTokenPriceUSD } = priceUSDResponse

            if (rewardTokenPriceUSD) setRewardTokenPrice(parseRewardAmount(rewardTokenPriceUSD))

            const userRewardInfo = await stakingRewardsContract?.getRewardInfo(mappedIncentive?.key, tokenId)
            const rewardResult = fromWei(userRewardInfo?.reward.toString(), rewardTokenInfo?.decimals).toString()
            // const secondsInside = userRewardInfo?.secondsInsideX128.toString()

            setUserRewardAmount(rewardResult)
          } catch (error) {
            console.error(error)
          }
        } else {
          const rewardResult = fromWei(mappedIncentive?.reward.toString(), rewardTokenInfo?.decimals).toNumber()

          setIncentiveAmount(rewardResult)
        }
      }
    }

    getUserRewardInfo()
  }, [stakingRewardsContract, mappedIncentive, tokenId])

  function parseRewardAmount(amount: string | null | undefined): number {
    if (amount === null || amount === undefined || isNaN(parseFloat(amount))) {
      return 0
    }
    return parseFloat(amount)
  }

  return isStaked
    ? {
        userReward: parseRewardAmount(userRewardAmount) > 0.0001 ? parseRewardAmount(userRewardAmount).toFixed(4) : '0',
        userRewardUSD:
          +(parseRewardAmount(userRewardAmount) * rewardTokenPrice).toFixed(4) > 0.0001
            ? (parseRewardAmount(userRewardAmount) * rewardTokenPrice).toFixed(4)
            : '0',
        rewardTokenInfo,
        isStaked,
      }
    : {
        incentiveReward: incentiveRewardAmount.toFixed(4),
        incentiveRewardUSD: (incentiveRewardAmount * rewardTokenPrice).toFixed(4),
        rewardTokenInfo,
        isStaked,
      }
}
