import React, { useState } from 'react'
import { Token, Currency } from 'sdk'
import styled from 'styled-components'
import { TYPE, CloseIcon } from 'theme'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed, AutoRow } from 'components/Row'
import CurrencyLogo from 'components/CurrencyLogo'
import { ArrowLeft, AlertTriangle } from 'react-feather'
import { transparentize } from 'polished'
import useTheme from 'hooks/useTheme'
import { ButtonPrimary } from 'components/Button'
import { SectionBreak } from 'components/swap/styleds'
import { useAddUserToken } from 'state/user/hooks'
import { getExplorerLink } from 'utils'
import { ExternalLink } from '../../theme/components'
import { PaddedColumn, Checkbox } from './styleds'
import { useLPorCurrenciesBalanceQuery } from 'state/wallet/hooks'
import { useChainId } from 'hooks'
import { BuyCryptoLink } from 'components/BuyCryptoLink'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
`

const WarningWrapper = styled(Card)<{ highWarning: boolean }>`
  background-color: ${({ theme, highWarning }) =>
    highWarning ? transparentize(0.8, theme.red1) : transparentize(0.8, theme.yellow2)};
  width: fit-content;
`

const AddressText = styled(TYPE.blue)`
  font-size: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 10px;
`}
`

interface ImportProps {
  tokens: Token[]
  onBack?: () => void
  onDismiss?: () => void
  handleCurrencySelect?: (currency: Currency) => void
}

export function ImportToken({ tokens, onBack, onDismiss, handleCurrencySelect }: ImportProps) {
  const theme = useTheme()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  const updateBalancesAfter = useLPorCurrenciesBalanceQuery()

  const chainId = useChainId()

  return (
    <Wrapper>
      <PaddedColumn gap="14px" style={{ width: '100%', flex: '1 1' }}>
        <RowBetween>
          {onBack ? <ArrowLeft style={{ cursor: 'pointer' }} onClick={onBack} /> : <div></div>}
          <TYPE.mediumHeader>Import {tokens.length > 1 ? 'Tokens' : 'Token'}</TYPE.mediumHeader>
          {onDismiss ? <CloseIcon onClick={onDismiss} /> : <div></div>}
        </RowBetween>
        {/* <BuyCryptoLink /> */}
      </PaddedColumn>
      <SectionBreak />
      <PaddedColumn gap="md">
        {tokens.map((token) => {
          return (
            <Card
              backgroundColor={theme.componentBg2}
              key={'import' + token.address}
              className=".token-warning-container"
            >
              <AutoColumn gap="10px">
                <AutoRow align="center">
                  <CurrencyLogo currency={token} size={'24px'} />
                  <TYPE.body ml="8px !important" mr="8px !important" fontWeight={500}>
                    {token.symbol}
                  </TYPE.body>
                  <TYPE.darkGray fontWeight={300}>{token.name}</TYPE.darkGray>
                </AutoRow>

                <ExternalLink href={getExplorerLink(token.address, 'address', chainId)}>
                  <AddressText>{token.address}</AddressText>
                </ExternalLink>
              </AutoColumn>
            </Card>
          )
        })}

        <Card style={{ backgroundColor: transparentize(0.8, theme.red1) }}>
          <AutoColumn justify="center" style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
            <AlertTriangle stroke={theme.red1} size={32} />
            <TYPE.body fontWeight={500} fontSize={20} color={theme.red1}>
              Trade at your own risk!
            </TYPE.body>
          </AutoColumn>

          <AutoColumn style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
            <TYPE.body fontWeight={400} color={theme.red1}>
              Anyone can create a token, including creating fake versions of existing tokens that claim to represent
              projects.
            </TYPE.body>
            <TYPE.body fontWeight={500} color={theme.red1}>
              If you purchase this token, you may not be able to sell it back.
            </TYPE.body>
          </AutoColumn>
          <AutoRow justify="center" style={{ cursor: 'pointer' }} onClick={() => setConfirmed(!confirmed)}>
            <Checkbox
              className=".understand-checkbox"
              name="confirmed"
              type="checkbox"
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
            <TYPE.body ml="10px !important" fontSize="16px" color={theme.red1} fontWeight={500}>
              I understand
            </TYPE.body>
          </AutoRow>
        </Card>
        <ButtonPrimary
          disabled={!confirmed}
          altDisabledStyle={true}
          borderRadius="20px"
          padding="10px 1rem"
          onClick={async () => {
            await tokens.map((token) => addToken(token))
            ;(await handleCurrencySelect) && handleCurrencySelect(tokens[0])
            updateBalancesAfter.refetch()
          }}
          className=".token-dismiss-button"
        >
          Import
        </ButtonPrimary>
      </PaddedColumn>
    </Wrapper>
  )
}
