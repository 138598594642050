import React from 'react'

export const TransactionsIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.95 1.55L10.9 0.5L9.85002 1.55L8.80002 0.5L7.75002 1.55L6.70002 0.5L5.65002 1.55L4.60002 0.5L3.55002 1.55L2.50002 0.5V10.3H0.400024V12.4C0.400024 13.562 1.33802 14.5 2.50002 14.5H10.9C12.062 14.5 13 13.562 13 12.4V0.5L11.95 1.55ZM11.6 12.4C11.6 12.785 11.285 13.1 10.9 13.1C10.515 13.1 10.2 12.785 10.2 12.4V10.3H3.90002V2.6H11.6V12.4Z"
        fill="currentColor"
      />
      <path d="M8.80004 4H4.60004V5.4H8.80004V4Z" fill="currentColor" />
      <path d="M10.9 4H9.5V5.4H10.9V4Z" fill="currentColor" />
      <path d="M8.80004 6.09985H4.60004V7.49985H8.80004V6.09985Z" fill="currentColor" />
      <path d="M10.9 6.09985H9.5V7.49985H10.9V6.09985Z" fill="currentColor" />
    </svg>
  )
}
