import { Percent } from '@uniswap/sdk-core'
import { useTheme } from 'styled-components/macro'
import Row from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { Text } from 'rebass'

interface PriceImpactWarningProps {
  priceImpact: Percent
}

const formatPriceImpact = (priceImpact: Percent) => `${priceImpact.multiply(-1).toFixed(2)}%`

export default function PriceImpactWarning({ priceImpact }: PriceImpactWarningProps) {
  const theme = useTheme()

  return (
    <MouseoverTooltip
      text={
        'A swap of this size may have a high price impact, given the current liquidity in the pool. There may be a large difference between the amount of your input token and what you will receive in the output token'
      }
    >
      <Row gap="6px" justify="flex-end">
        <Text fontSize={13} color={theme.red1}>
          Price impact warning:
        </Text>
        <Text textAlign="right" fontSize={13} color={theme.red1}>
          {formatPriceImpact(priceImpact)}
        </Text>
      </Row>
    </MouseoverTooltip>
  )
}
