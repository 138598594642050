import { useQuery } from 'react-query'
import { api } from 'shared'
import { useCGAllCoinsList } from './useCGAllCoinsList'
import { useListedTokens } from 'hooks/Tokens'
import { useChainId } from 'hooks'
import { useMemo } from 'react'
import { calculatePercentageDifference } from 'utils/calculatePercentageDifference'
import { NATIVE_CURRENCY } from 'sdk'

type Response = {
  ath: number
  ath_change_percentage: number
  ath_date: string
  atl: number
  atl_change_percentage: number
  atl_date: string
  circulating_supply: number
  current_price: number
  fully_diluted_valuation: number
  high_24h: number
  id: string
  image: string
  last_updated: string
  low_24h: number
  market_cap: number
  market_cap_change_24h: number
  market_cap_change_percentage_24h: number
  market_cap_rank: number
  max_supply: null
  name: string
  price_change_24h: number
  price_change_percentage_24h: number
  roi: null
  sparkline_in_7d: { price: number[] }
  symbol: string
  total_supply: number
  total_volume: number
}[]

export const useCGMarketsData = () => {
  const chainId = useChainId()
  const supportedTokensList = useListedTokens('Aggregator')
  const coinsListQuery = useCGAllCoinsList()

  const idsList = useMemo(
    () =>
      [...Object.values(supportedTokensList), NATIVE_CURRENCY[chainId]]
        .map((token) => {
          const cgToken = coinsListQuery.data?.find(
            (coin) =>
              coin.symbol === token.symbol?.toLowerCase() &&
              (coin.symbol === NATIVE_CURRENCY[chainId].symbol.toLowerCase() ||
                Object.values(coin.platforms).length === 0 ||
                Object.values(coin.platforms).includes(token?.address?.toLowerCase()) ||
                coin.name === 'LIF3 (OLD)')
          )

          return cgToken?.id
        })
        .filter((val) => val !== undefined),
    [coinsListQuery.data]
  )

  return useQuery(
    ['coingecko-markets-data', chainId],
    async () => {
      const response = await api.coingeckoApi.get<Response>('/coins/markets', {
        params: {
          ids: idsList.reduce((acc, id) => `${acc},${id}`, ''),
          order: 'market_cap_desc',
          per_page: 100,
          page: 1,
          sparkline: true,
          locale: 'en',
          vs_currency: 'usd',
        },
      })

      const result = response.data.map((coin) => {
        const priceStart = coin.sparkline_in_7d.price[0]
        const priceLast = coin.sparkline_in_7d.price[coin.sparkline_in_7d.price.length - 1]

        const percentageChange7d =
          priceStart && priceStart ? calculatePercentageDifference(priceLast, priceStart) : null

        return { ...coin, percentageChange7d }
      })

      return result
    },
    { enabled: Boolean(coinsListQuery.data) && idsList.length > 0 }
  )
}
