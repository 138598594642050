import React from 'react'

type Props = {
  width?: number
  height?: number
  style?: React.CSSProperties
}

export const CommunityIcon: React.FC<Props> = ({ width = 14, height = 14, style }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21049 0C0.98967 0 0 1.04467 0 2.33333V6.22222C0 7.51089 0.98967 8.55556 2.21049 8.55556L2.21049 10.5511C2.21049 10.8514 2.68669 11.0019 2.9652 10.7895L5.89464 8.55556H8.10513C9.32595 8.55556 10.3156 7.51089 10.3156 6.22222V2.33333C10.3156 1.04467 9.32595 0 8.10513 0H2.21049Z"
        fill="white"
      />
      <path
        d="M11.4209 3.11111H11.7895C13.0103 3.11111 14 4.15578 14 5.44444V9.33333C14 10.622 13.0103 11.6667 11.7895 11.6667V13.6622C11.7895 13.9625 11.3133 14.113 11.0348 13.9006L8.10536 11.6667H5.89487C5.34172 11.6667 4.83602 11.4522 4.44833 11.0977L6.25211 9.72222H8.10513C9.93636 9.72222 11.4209 8.15522 11.4209 6.22222V3.11111Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M5.043 5.23457C4.79593 5.23457 4.59099 5.02237 4.6184 4.76318C4.62761 4.67602 4.63963 4.59932 4.65445 4.54289C4.69506 4.39361 4.75387 4.2739 4.83087 4.18374C4.90788 4.09211 5.00169 4.0086 5.1123 3.93323C5.20051 3.87411 5.27892 3.81425 5.34753 3.75366C5.41754 3.69158 5.47284 3.62507 5.51345 3.55413C5.55405 3.48319 5.57435 3.40264 5.57435 3.31248C5.57435 3.22528 5.55475 3.14843 5.51555 3.08192C5.47634 3.01541 5.42244 2.96442 5.35383 2.92895C5.28662 2.892 5.21171 2.87353 5.12911 2.87353C5.0465 2.87353 4.96879 2.89348 4.89598 2.93339C4.82457 2.97329 4.76577 3.03019 4.71956 3.10409C4.63984 3.23295 4.53315 3.36791 4.38735 3.36791H4.1741C3.90465 3.36791 3.67254 3.12758 3.76385 2.85998C3.79568 2.7667 3.83702 2.6818 3.88787 2.60528C4.02089 2.40723 4.1966 2.26091 4.41503 2.16632C4.63345 2.07026 4.87428 2.02222 5.13751 2.02222C5.42594 2.02222 5.68217 2.06952 5.90619 2.16411C6.13161 2.2587 6.30873 2.39836 6.43755 2.58311C6.56636 2.76785 6.63077 2.99398 6.63077 3.26149C6.63077 3.43737 6.60277 3.59256 6.54676 3.72705C6.49075 3.86155 6.41234 3.98052 6.31153 4.08398C6.21212 4.18596 6.09521 4.27907 5.9608 4.36331C5.85578 4.42834 5.76828 4.49633 5.69827 4.56727C5.62966 4.63822 5.57785 4.7195 5.54285 4.81114C5.45776 5.03029 5.30845 5.23457 5.08408 5.23457H5.043ZM5.0598 6.68889C4.90858 6.68889 4.77907 6.63273 4.67126 6.5204C4.56484 6.40808 4.51234 6.2721 4.51374 6.11248C4.51234 5.95582 4.56484 5.82206 4.67126 5.71122C4.77907 5.59889 4.90858 5.54273 5.0598 5.54273C5.20401 5.54273 5.33073 5.59889 5.43994 5.71122C5.54915 5.82206 5.60446 5.95582 5.60586 6.11248C5.60446 6.2189 5.57785 6.3157 5.52605 6.4029C5.47564 6.4901 5.40914 6.55957 5.32653 6.6113C5.24532 6.66302 5.15641 6.68889 5.0598 6.68889Z"
        fill="#39404E"
      />
    </svg>
  )
}
