import { useContext } from 'react'
import PairAddressContext from '../contexts/PairAddress'

import useLendingPool from '../services/hooks/use-lending-pool'
import { useParams } from 'react-router'
import { PARAMETERS } from '../utils/constants/links'
import { useErrorHandler } from 'react-error-boundary'

export default function usePairAddress(): string {
  const uniswapV2PairAddress = useContext(PairAddressContext)
  return uniswapV2PairAddress
}

export const useUniswapLpPairAddress = () => {
  const { [PARAMETERS.UNISWAP_V2_PAIR_ADDRESS]: selectedUniswapV2PairAddress } = useParams<Record<string, string>>()

  const {
    isLoading: selectedLendingPoolLoading,
    data: selectedLendingPool,
    error: selectedLendingPoolError,
  } = useLendingPool(selectedUniswapV2PairAddress)
  useErrorHandler(selectedLendingPoolError)

  return selectedLendingPool?.pair.uniswapV2PairAddress ?? ''
}
