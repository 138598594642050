import React from 'react'

export const MoreIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 16" fill="none">
      <path
        d="M1.5 8H15.5M1.5 1H15.5M1.5 15H15.5"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
