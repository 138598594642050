import { ChainId } from 'sdk'

const IMX_ADDRESSES: {
  [chainId: number]: string
} = {
  [ChainId.METIS]: '0x69fdb77064ec5c84FA2F21072973eB28441F43F3',
  [ChainId.TOMBCHAINTESTNET]: '0x0E1E4Ee9B9443D179f5C78eFF2Da3402C9819b6f',
  [ChainId.TOMB]: '0x4200000000000000000000000000000000000108',
  [ChainId.FANTOM]: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  [ChainId.BSC]: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
  [ChainId.POLYGON]: '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
}

const IMX_DECIMALS = 18

export { IMX_ADDRESSES, IMX_DECIMALS }
