import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 18px;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primaryText1};
`
export const MaxButton = styled.button<{ width: string }>`
  padding: 0 10px;
  height: 24px;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: none;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.border2};
  color: ${({ theme }) => theme.primaryText1};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  transition: all 0.3s;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  color: #fff;

  :hover {
    border: 1px solid ${({ theme }) => theme.accent2Border};
    color: ${({ theme }) => theme.primary1};
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
