import React, { Suspense, useEffect, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { ReactQueryDevtools } from 'react-query/devtools'
import { chains } from 'components/ChainToggle/constants'
import { rgba } from 'polished'

import { api, ApiContext } from 'shared'

import { Header } from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

import { MobileNavigation } from 'components/MobileNavigation'
import TradePage from './Trade'
import { Footer } from 'components/Footer'
import { useIsDarkMode } from 'state/user/hooks'
import { SkeletonTheme } from 'react-loading-skeleton'

import BgImg from 'assets/images/sky.svg'
import ExpertImg from 'assets/images/expert-bg.svg'
import { useMediaWith } from 'theme'
import Faq from './Faq'
import { useChainId } from 'hooks'
import { ChainId } from 'sdk'
import TagManager from 'react-gtm-module'
import PositionPage from './V3Pool/PositionPage'

const AddLiquidityV3 = lazy(() => import('./AddLiquidityV3'))
const V3Pool = lazy(() => import('./V3Pool'))
const RemoveLiquidityV3 = lazy(() => import('pages/RemoveLiquidityV3'))

const AddLiquidity = lazy(() => import('./AddLiquidity'))

const Pool = lazy(() => import('./Pool'))

const PoolFinder = lazy(() => import('./PoolFinder'))
const RemoveLiquidity = lazy(() => import('./RemoveLiquidity'))
const Portfolio = lazy(() => import('./Portfolio'))
const Staking = lazy(() => import('./Staking'))
const Farms = lazy(() => import('./Farms'))
const Bridge = lazy(() => import('./Bridge'))
const Masonry = lazy(() => import('./Masonry'))
const Lending = lazy(() => import('./Lending'))
const LandingPage = lazy(() => import('./Landing'))
const Pit = lazy(() => import('./Pit'))
// const Genesis = lazy(() => import('./Genesis'))
const Genesis = lazy(() => import('./Genesis'))
const Fountain = lazy(() => import('./Fountain'))
const BuyCrypto = lazy(() => import('./BuyCrypto'))
const Convert = lazy(() => import('./Convert'))

const Tokens = lazy(() => import('./Tokens'))
const Token = lazy(() => import('./Token'))
const Wallet = lazy(() => import('./Wallet'))

const HowToBuy = lazy(() => import('./HowToBuy'))
const L3USD = lazy(() => import('./L3USD'))
const AI = lazy(() => import('./AI'))
const Chain = lazy(() => import('./Chain'))
const Comics = lazy(() => import('./Comics'))
const Enjoy = lazy(() => import('./Enjoy'))
const Start = lazy(() => import('./Start'))
const News = lazy(() => import('./News'))
const Faucet = lazy(() => import('./Faucet'))
const Ambassadors = lazy(() => import('./Ambassadors'))
const Partners = lazy(() => import('./Partners'))
const Validators = lazy(() => import('./Validators'))
const Verify = lazy(() => import('./Verify'))
const Affiliate = lazy(() => import('./Affiliate'))
const Media = lazy(() => import('./Media'))

const BackgroundOverlay = styled.div`
  position: fixed;
  bottom: -40%;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  pointer-events: none;

  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    bottom: -30%;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    bottom: -40%;
  `};

  img {
    width: 100%;
    height: 100%;
    object-position: bottom;
    object-fit: contain;

    ${({ theme }) => theme.mediaWidth.upToLarge`
      object-fit: cover;
    `};
  }
`

const ThemedBackground = styled.div<{ $backgroundColor: string }>`
  position: absolute;
  top: 0;
  right: -50vw;
  pointer-events: none;
  max-width: 100vw !important;
  width: 100%;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ $backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${rgba($backgroundColor, 0.2)} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-155vh);

  ${({ theme }) => theme.mediaWidth.upToMedium`
    transform: translateY(-165vh);
    right: -40vw;
  `};
`

const AppWrapper = styled.div<{ isOverflow: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  background: ${({ theme }) => theme.appBg};
  overflow: ${({ isOverflow }) => (isOverflow ? 'hidden' : 'visible')};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const FooterWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  position: relative;
  z-index: 1;
  max-height: 100%;
  min-height: calc(100vh - 90px);

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 10px;
    padding-top: 10px;
    min-height: calc(100vh - 56px);
  `};
`

export const AppBg = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
  width: 100%;
  height: 1110px;
  pointer-events: none;
  z-index: -1;
  overflow: hidden;
`

export const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  min-height: 934px;
  opacity: ${({ theme }) => (theme.darkMode ? '0.6' : '0.35')};
`

export const ExpertImage = styled.img`
  position: fixed;
  bottom: 0;
  right: 0;
  object-fit: cover;
  opacity: 0.8;
`

export default function App() {
  const chainId = useChainId()
  const isDarkMode = useIsDarkMode()
  const { pathname } = useLocation()
  const isSwapPage = pathname.startsWith('/swap')
  const isTokensSwap = pathname.startsWith('/tokens/token')
  const isNeedOverflow = pathname.startsWith('/staking')
  const { upToMedium } = useMediaWith()
  const isExpertMode = false
  const isExpertBg = (isExpertMode && isSwapPage && !upToMedium) || (isExpertMode && isTokensSwap)

  const tagManagerArgs = {
    gtmId: 'GTM-K49M7KW',
  }

  TagManager.initialize(tagManagerArgs)

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const ChainRoute = ({ children, enabledChains, ...rest }) => {
    const chainId = useChainId()

    return (
      <Route
        {...rest}
        render={() => {
          return enabledChains.includes(chainId) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
        }}
      />
    )
  }

  const currentChain = chains[chainId]

  return (
    <SkeletonTheme baseColor={isDarkMode ? '#929191' : ''} highlightColor={isDarkMode ? '#a1a1a1' : ''}>
      <Web3ReactManager>
        <ApiContext.Provider value={api}>
          <Route component={DarkModeQueryParamReader} />
          <AppWrapper
            className={isDarkMode ? 'theme-dark' : 'theme-light'}
            // isReversedBg={isExpertBg}
            isOverflow={!isNeedOverflow}
          >
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <BodyWrapper>
              {isExpertBg ? (
                <ExpertImage src={ExpertImg} />
              ) : (
                <AppBg>
                  <BgImage src={BgImg} />
                </AppBg>
              )}

              <Popups />

              <>
                <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
                <Suspense fallback={null}>
                  <Switch>
                    <Route path="/how-to-buy" component={HowToBuy} />
                    <Route path="/" exact component={LandingPage} />
                    <Route path="/wallet" exact component={Wallet} />
                    <Route path="/l3usd" exact component={L3USD} />
                    <Route path="/ai" exact component={AI} />
                    <Route path="/chain" exact component={Chain} />
                    <Route path="/comics" component={Comics} />
                    <Route path="/events" component={Enjoy} />
                    <Route path="/start" component={Start} />
                    <Route path="/news" component={News} />
                    <Route path="/chain/testnet/faucet" component={Faucet} />
                    <Route path="/ambassadors" component={Ambassadors} />
                    <Route path="/partners" component={Partners} />
                    <Route path="/chain/validators" component={Validators} />
                    <Route path="/verify" component={Verify} />
                    <Route path="/affiliates" component={Affiliate} />
                    <Route path="/media" component={Media} />
                    <Route path="/swap">
                      <TradePage />
                    </Route>
                    <ChainRoute
                      exact
                      strict
                      path="/pool/find"
                      enabledChains={[ChainId.FANTOM, ChainId.POLYGON, ChainId.BSC]}
                    >
                      <PoolFinder />
                    </ChainRoute>
                    <ChainRoute
                      exact
                      strict
                      path="/pool"
                      enabledChains={[ChainId.FANTOM, ChainId.POLYGON, ChainId.BSC]}
                    >
                      <Pool />
                    </ChainRoute>
                    <ChainRoute path="/pool/add" enabledChains={[ChainId.FANTOM, ChainId.POLYGON, ChainId.BSC]}>
                      <AddLiquidity />
                    </ChainRoute>
                    <ChainRoute path="/pool/remove" enabledChains={[ChainId.FANTOM, ChainId.POLYGON, ChainId.BSC]}>
                      <RemoveLiquidity history={undefined} />
                    </ChainRoute>
                    <ChainRoute
                      path="/garden"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                      ]}
                    >
                      <Farms />
                    </ChainRoute>
                    <ChainRoute
                      path="/staking"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                      ]}
                    >
                      <Staking />
                    </ChainRoute>
                    <ChainRoute path="/terrace" enabledChains={[ChainId.FANTOM, ChainId.BSC, ChainId.POLYGON]}>
                      <Lending />
                    </ChainRoute>
                    <ChainRoute
                      path="/portfolio"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                        ChainId.BASE,
                      ]}
                    >
                      <Portfolio />
                    </ChainRoute>
                    <Route path="/bridge" enabledChains={[ChainId.FANTOM]}>
                      <Bridge />
                    </Route>
                    <ChainRoute path="/pit" enabledChains={[ChainId.FANTOM]}>
                      <Pit />
                    </ChainRoute>
                    <ChainRoute
                      path="/nursery"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                      ]}
                    >
                      <Genesis />
                    </ChainRoute>
                    <ChainRoute path="/faq" enabledChains={[ChainId.FANTOM]}>
                      <Faq />
                    </ChainRoute>
                    <ChainRoute
                      path="/fountain"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                        ChainId.AMOY_TESTNET,
                      ]}
                    >
                      <Fountain />
                    </ChainRoute>
                    <ChainRoute
                      exact
                      path="/tokens"
                      strict
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                        ChainId.BASE,
                      ]}
                    >
                      <Tokens />
                    </ChainRoute>
                    <ChainRoute
                      path="/tokens/token"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.BSC,
                        ChainId.ARBITRUM,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                        ChainId.BASE,
                      ]}
                    >
                      <Token />
                    </ChainRoute>
                    <ChainRoute
                      path="/buy-crypto"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.BSC,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.OPTIMISM,
                        ChainId.ARBITRUM,
                        ChainId.BASE,
                      ]}
                    >
                      <BuyCrypto />
                    </ChainRoute>
                    <ChainRoute
                      path="/add"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.LIF3CHAIN_TESTNET,
                        ChainId.BSC,
                      ]}
                    >
                      <AddLiquidityV3 />
                    </ChainRoute>
                    <ChainRoute
                      path="/pool"
                      exact
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.LIF3CHAIN_TESTNET,
                        ChainId.BSC,
                      ]}
                    >
                      <V3Pool />
                    </ChainRoute>
                    <ChainRoute
                      path="/pools"
                      exact
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.LIF3CHAIN_TESTNET,
                        ChainId.BSC,
                      ]}
                    >
                      <V3Pool />
                    </ChainRoute>
                    <ChainRoute
                      path="/pool/:tokenId"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.LIF3CHAIN_TESTNET,
                        ChainId.BSC,
                      ]}
                    >
                      <PositionPage />
                    </ChainRoute>
                    <ChainRoute
                      path="/pools/:tokenId"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.LIF3CHAIN_TESTNET,
                        ChainId.BSC,
                      ]}
                    >
                      <PositionPage />
                    </ChainRoute>
                    <ChainRoute
                      path="/remove/:tokenId/:poolId"
                      enabledChains={[
                        ChainId.FANTOM,
                        ChainId.POLYGON,
                        ChainId.ETHEREUM,
                        ChainId.LIF3CHAIN_TESTNET,
                        ChainId.BSC,
                      ]}
                    >
                      <RemoveLiquidityV3 />
                    </ChainRoute>
                    <ChainRoute path="/convert" enabledChains={[ChainId.FANTOM, ChainId.BSC, ChainId.POLYGON]}>
                      {/* <Convert /> */}
                      <Redirect to="/" />
                    </ChainRoute>
                  </Switch>
                </Suspense>
              </>
            </BodyWrapper>
            <FooterWrapper>
              <Footer />
            </FooterWrapper>
            <MobileNavigation />
          </AppWrapper>
        </ApiContext.Provider>
      </Web3ReactManager>
    </SkeletonTheme>
  )
}
