import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { StaticJsonRpcProvider, JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import ROUTER_ABI from 'constants/abis/router-abi.json'
import { ChainId, JSBI, Percent, CurrencyAmount, ROUTER_ADDRESS } from 'sdk'
import { ethers } from 'ethers'
import { SUPPORTED_NETWORKS } from 'sdk/constants'
import { SUPPORTED_NETWORKS_IDS } from 'connectors'
import { AppConfig, SwapTypeEnum } from 'config'

export const formatBalance = (value: ethers.BigNumberish, decimals = 18, maxFraction = 0) => {
  const formatted = ethers.utils.formatUnits(value, decimals)
  if (maxFraction > 0) {
    const split = formatted.split('.')
    if (split.length > 1) {
      return split[0] + '.' + split[1].substr(0, maxFraction)
    }
  }
  return formatted
}

export const parseBalance = (value: string, decimals = 18) => {
  return ethers.utils.parseUnits(value || '0', decimals)
}

export const isEmptyValue = (text: string) =>
  ethers.BigNumber.isBigNumber(text)
    ? ethers.BigNumber.from(text).isZero()
    : text === '' || text.replace(/0/g, '').replace(/\./, '') === ''

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const builders = {
  fantom: (data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = 'https://ftmscan.com'
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },
  default: (data: string, type: 'transaction' | 'token' | 'address' | 'block', chainId) => {
    const prefix = SUPPORTED_NETWORKS[chainId].blockExplorerUrls[0]

    switch (type) {
      case 'transaction':
        return `${prefix}tx/${data}`
      default:
        return `${prefix}${type}/${data}`
    }
  },
}

export function getExplorerLink(
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
  chainId: number
): string {
  return builders.default(data, type, chainId)
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function shortenHash(hash: string, chars = 4): string {
  return `${hash.substring(0, chars + 2)}...${hash.substring(42 - chars)}`
}

// add 50%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(5000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider | StaticJsonRpcProvider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function getRouterAddress(chainId: number) {
  return ROUTER_ADDRESS[chainId]
}

// account is optional
export function getRouterContract(library: Web3Provider, chainId: number, account?: string): Contract {
  return getContract(getRouterAddress(chainId), ROUTER_ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const addIntZero = (string: string): string => {
  if (string.startsWith('.')) {
    return `0${string}`
  }

  return string
}

export const isNetworkSupported = (chainId: ChainId | null | undefined) => {
  if (!chainId) {
    return false
  }

  return SUPPORTED_NETWORKS_IDS.includes(chainId)
}

export const isV2 = (chainId: ChainId) => AppConfig.marketSwapType[chainId] === SwapTypeEnum.V2

export const countDecimals = (val: number) => {
  if (Math.floor(val.valueOf()) === val.valueOf()) return 0

  const str = val.toString()

  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return Number(str.split('-')[1]) || 0
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0
  }

  return Number(str.split('-')[1]) || 0
}

export const getDecimals = (price: number) => {
  if (price >= 1) {
    return 2
  } else if (price < 1 && price > 0.01) {
    return 3
  } else {
    return countDecimals(price)
  }
}
