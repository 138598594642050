import React from 'react'

export const FarmIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 16" fill="none">
      <path
        d="M15.5 4.23077L8.5 1L1.5 4.23077M2.5002 6.38472V11.7693M14.5002 11.7693V6.38472M1.5 14.9999H15.5M6.5001 6.38472V11.7693M10.5001 11.7693V6.38472"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  )
}
