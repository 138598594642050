import React, { useMemo } from 'react'
import {
  MoreDropdownBox,
  MoreDropdownCol,
  MoreDropdownTitle,
  MoreDropdownList,
  MoreDropdownListItem,
  MoreDropdownListItemCol,
  MoreDropdownListItemTitle,
  MoreDropdownListItemText,
  ArrowRightStyledIcon,
  MoreDropdownRow,
  MoreDropdownSocialsBox,
} from './styles'
import { ReactComponent as Lif3LogoShortSmallIcon } from 'assets/svg/lif3-logo-short-small.svg'
import { Link } from 'react-router-dom'
import { useChainId } from 'hooks'
import { IconWrapper } from 'theme'
import { CommunityIcon } from 'icons/CommunityIcon'
import { CloseCircleIcon } from 'icons/CloseCircleIcon'
import { SocialsList } from '../SocialsList'
import { AnalyticsIcon } from 'icons/AnalyticsIcon'

export const MoreDropdown = (props) => {
  const chainId = useChainId()
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])

  return (
    <MoreDropdownBox>
      <MoreDropdownCol>
        <MoreDropdownRow>
          <div style={{ width: '22px' }}></div>
          <MoreDropdownTitle>More</MoreDropdownTitle>
          <IconWrapper size="22px" stroke="currentColor">
            <CloseCircleIcon />
          </IconWrapper>
        </MoreDropdownRow>
        <MoreDropdownList>
          <MoreDropdownListItem onClick={() => props.toggleComm(!props.commState)}>
            <IconWrapper size="20px" stroke="transparent">
              <CommunityIcon />
            </IconWrapper>
            <MoreDropdownListItemCol>
              <MoreDropdownListItemTitle>Community</MoreDropdownListItemTitle>
              <MoreDropdownListItemText>Learn more about the Lif3 Community.</MoreDropdownListItemText>
            </MoreDropdownListItemCol>
            <ArrowRightStyledIcon />
          </MoreDropdownListItem>
          <MoreDropdownListItem onClick={() => props.toggleEcos(!props.ecosState)}>
            <IconWrapper size="20px" stroke="transparent">
              <Lif3LogoShortSmallIcon />
            </IconWrapper>
            <MoreDropdownListItemCol>
              <MoreDropdownListItemTitle>Ecosystem</MoreDropdownListItemTitle>
              <MoreDropdownListItemText>Learn more about the Lif3 Ecosystem.</MoreDropdownListItemText>
            </MoreDropdownListItemCol>
            <ArrowRightStyledIcon />
          </MoreDropdownListItem>
          <MoreDropdownListItem onClick={() => props.toggleStats(!props.statsState)}>
            <IconWrapper size="20px" stroke="transparent">
              <AnalyticsIcon />
            </IconWrapper>
            <MoreDropdownListItemCol>
              <MoreDropdownListItemTitle>Stats</MoreDropdownListItemTitle>
              <MoreDropdownListItemText>View trade and token stats.</MoreDropdownListItemText>
            </MoreDropdownListItemCol>
            <ArrowRightStyledIcon />
          </MoreDropdownListItem>
        </MoreDropdownList>

        <MoreDropdownSocialsBox>
          <SocialsList />
        </MoreDropdownSocialsBox>
      </MoreDropdownCol>
    </MoreDropdownBox>
  )
}
