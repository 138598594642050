import React, { useEffect } from 'react'

type Params = Partial<{
  target: React.RefObject<HTMLElement>
  onIntersect: () => void
  root: React.RefObject<HTMLElement | undefined | null> | HTMLElement
  threshold: number
  rootMargin: string
  enabled: boolean | undefined
}>

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: Params) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          entry.isIntersecting && enabled && onIntersect && onIntersect()
        }),
      {
        // @ts-ignore
        root: (root && root.current) ?? root,
        rootMargin,
        threshold,
      }
    )

    const el = target && target.current

    if (!el) {
      return
    }

    observer.observe(el)

    return () => {
      observer.unobserve(el)
    }
  }, [target?.current, enabled])
}
