import { Currency, currencyEquals, Token, NATIVE_CURRENCY, ChainId } from 'sdk'
import { MutableRefObject, useCallback, memo, useEffect, useRef, useState } from 'react'
import { FixedSizeList, areEqual } from 'react-window'
import memoize from 'memoize-one'

import { TokenRow } from './TokenRow'
import { useBalances } from 'state/wallet/hooks'
import { useActiveWeb3React, useChainId } from 'hooks'
import { useSelectedCurrencies } from 'state/swap/hooks'

function currencyKey(currency: Currency, chainId: number): string {
  return currency instanceof Token
    ? currency.address
    : currency === NATIVE_CURRENCY[chainId]
    ? NATIVE_CURRENCY[chainId].symbol
    : ''
}

const createItems = memoize(
  ({
    showFTM,
    currencies,
    selectedCurrency,
    otherCurrency,
    onCurrencySelect,
    currencyEquals,
    account,
    currencyBalances,
    chainId,
    loadMore,
  }) => ({
    currencies: showFTM ? [NATIVE_CURRENCY[chainId], ...currencies] : currencies,
    selectedCurrency,
    otherCurrency,
    onCurrencySelect,
    currencyEquals,
    account,
    currencyBalances,
    chainId,
    loadMore,
  })
)

// eslint-disable-next-line react/display-name
const Row = memo(({ data, index, style }: any) => {
  const { selectedCurrency, otherCurrency, onCurrencySelect, account, currencyBalances, chainId, loadMore } = data
  const currency: Token = data.currencies[index]
  const lastIndex = data.currencies.length - 1
  const isSelected = currency?.symbol === selectedCurrency?.symbol
  const otherSelected = currency?.symbol === otherCurrency?.symbol
  const observer = useRef<HTMLDivElement | null>(null)

  const handleSelect = () => {
    onCurrencySelect(currency)
  }

  useEffect(() => {
    if (observer.current && index === lastIndex) loadMore(index)
  }, [observer.current?.offsetTop])

  const balanceData = currencyBalances.data
    ? currencyBalances.data[
        currency.symbol === NATIVE_CURRENCY[chainId].symbol ? NATIVE_CURRENCY[chainId].symbol : currency.address
      ]
    : 0

  useEffect(() => {
    if (observer.current && index === lastIndex) loadMore(index)
  }, [observer.current?.offsetTop])

  return (
    <div ref={observer}>
      <TokenRow
        style={style}
        currency={currency}
        isSelected={isSelected}
        onSelect={handleSelect}
        otherSelected={otherSelected}
        balance={balanceData}
        account={account}
      />
    </div>
  )
}, areEqual)

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showFTM,
}: {
  height: number
  currencies: Token[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showFTM: boolean
  setImportToken: (token: Token) => void
}) {
  const [increasingCurrencies, setIncreasingCurrencies] = useState<Token[]>([])
  const { inputCurrency, outputCurrency } = useSelectedCurrencies()
  const { account } = useActiveWeb3React()
  // const isExpertMode = useIsExpertMode()
  const isExpertMode = false
  const chainId = useChainId()

  const itemKey = useCallback((index: number, data: any) => currencyKey(data.currencies[index], chainId), [])

  const currencyBalances = useBalances()

  const getCurrencies = () => {
    if (isExpertMode) {
      return currencies
    }

    if (inputCurrency === Currency.USD_CARD && selectedCurrency === outputCurrency) {
      return currencies.filter((currency) => currency === NATIVE_CURRENCY[chainId])
    }

    if (chainId === ChainId.FANTOM) {
      return [
        // Currency.USD_CARD,
        ...currencies,
      ]
    }

    return currencies
  }

  useEffect(() => {
    const limit = 15
    const startIndex = 0
    setIncreasingCurrencies(getCurrencies().slice(startIndex, limit))
  }, [currencies])

  const loadMore = (index: number) => {
    if (index === getCurrencies().length - 1) return

    const limit = 10
    setIncreasingCurrencies(getCurrencies().slice(0, index + limit))
  }

  const items = createItems({
    showFTM,
    currencies: increasingCurrencies,
    selectedCurrency,
    otherCurrency,
    onCurrencySelect,
    currencyEquals,
    loadMore,
    account,
    currencyBalances,
    chainId,
  })

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={items}
      itemCount={items.currencies.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
