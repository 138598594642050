import styled from 'styled-components'

export const ChainToggleBtn = styled.a<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  min-width: 152px;
  min-height: 44px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  font: inherit;
  color: ${({ theme }) => theme.primaryText1};
  cursor: pointer;

  @media screen and (max-width: 1510px) {
    min-width: 72px;
  }
`

export const ChainToggleItem = styled(ChainToggleBtn)<{ bg?: string; selected?: boolean }>`
  padding: 10px 20px;
  background: transparent;
  box-shadow: none;
  min-width: auto;
  justify-content: flex-start;
  transition: opacity 0.3s, background-color 0.3s;
  text-decoration: none;
  min-height: auto;
  background: ${({ selected, theme }) => selected && theme.appBg};

  &:hover {
    background: ${({ bg, theme }) => (bg ? bg : theme.appBg)};
  }
`
