import React from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import styled from 'styled-components'
import { Screen } from '../'
import { ChartsIcon } from './icons/ChartsIcon'
import { OrdersIcon } from './icons/OrdersIcon'
import { TradesIcon } from './icons/TradesIcon'
import { InfoIcon } from './icons/InfoIcon'
import { Text } from 'rebass'

const MobileMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const MobileMenuInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.componentBg1};
  border-radius: 10px;
  height: 50px;
  width: 100%;
`

const IconWrapper = styled(Link)<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, active }) => (active ? theme.accent2 : theme.primaryText1)};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  border: none;
  background: ${({ theme, active }) => (active ? theme.activeTabBg : 'transparent')};
  flex-direction: column;
  text-decoration: none;
  font-size: 14px;
  border-radius: 30px;
  padding: 0 20px;
  height: 30px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`

type Props = {
  activeScreen: Screen
}

export const MobileMenu: React.FC<Props> = (props) => {
  const queryObject = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  return (
    <MobileMenuWrapper>
      <MobileMenuInner>
        {/* <IconWrapper
          to={{ search: qs.stringify({ ...queryObject, screen: Screen.ORDERS }) }}
          active={props.activeScreen === Screen.ORDERS}
        >
          Transactions
        </IconWrapper> */}

        <IconWrapper
          to={{ search: qs.stringify({ ...queryObject, screen: Screen.TRADES }) }}
          active={props.activeScreen === Screen.TRADES}
        >
          Swap
        </IconWrapper>

        <IconWrapper
          to={{ search: qs.stringify({ ...queryObject, screen: Screen.CHART }) }}
          active={props.activeScreen === Screen.CHART}
        >
          Chart
        </IconWrapper>
      </MobileMenuInner>
    </MobileMenuWrapper>
  )
}
