import { Token, TokenAmount, CurrencyAmount } from 'sdk'
import { useChainId } from 'hooks'
import { useQuery } from 'react-query'

import { getContract } from 'utils'
import { ERC20_ABI } from 'constants/abis/erc20'
import { PROVIDERS_BY_CHAIN } from 'connectors'

export function useTokenAllowanceQuery(
  token?: Token,
  owner?: string,
  spender?: string,
  fetchedAllowance?: CurrencyAmount
) {
  const chainId = useChainId()

  const contract = token?.address ? getContract(token?.address, ERC20_ABI, PROVIDERS_BY_CHAIN[chainId]) : undefined

  const allowanceQuery = useQuery(
    ['token-allowance', token?.address, spender, fetchedAllowance, chainId],
    async () => {
      if (fetchedAllowance?.greaterThan('0')) {
        //fetchedAllowance loaded, all good
        return fetchedAllowance
      } else if (fetchedAllowance?.toExact() == '666999') {
        //fetchedAllowance has not been loaded yet and triggered race condition, waiting for it to resolve
        return 0
      }

      const allowanceResponse = await contract?.allowance(owner, spender)

      if (token && allowanceResponse) {
        return new TokenAmount(token, allowanceResponse.toString())
      }

      return undefined
    },
    { enabled: Boolean(token) && Boolean(owner) && Boolean(spender) }
  )

  return allowanceQuery
}
