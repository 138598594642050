import { ChainId } from 'sdk'

export enum SwapTypeEnum {
  V2,
  V3,
}

export const AppConfig = {
  marketSwapType: {
    [ChainId.FANTOM]: SwapTypeEnum.V3,
    [ChainId.TOMB]: SwapTypeEnum.V3,
    [ChainId.BSC]: SwapTypeEnum.V3,
    [ChainId.POLYGON]: SwapTypeEnum.V2,
    [ChainId.AMOY_TESTNET]: SwapTypeEnum.V3,
    [ChainId.ETHEREUM]: SwapTypeEnum.V3,
    [ChainId.OPTIMISM]: SwapTypeEnum.V3,
    [ChainId.ARBITRUM]: SwapTypeEnum.V3,
    [ChainId.BASE]: SwapTypeEnum.V3,
    [ChainId.LIF3CHAIN_TESTNET]: SwapTypeEnum.V3,
  },
}
