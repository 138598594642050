import styled from 'styled-components'

export const PositionsListBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 100%;
  background: ${({ theme }) => theme.componentBg4};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 900px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: transparent;
    min-width: auto;
  `};
`

export const PositionsListGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1.5fr 1.5fr 3fr 3fr;
  gap: 20px;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    gap: 5px;
  `};
`

export const PositionsListHeader = styled(PositionsListGrid)`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 20px;

  background: ${({ theme }) => theme.componentBg5};
  border-radius: 20px 20px 0 0;

  & > *:nth-child(4),
  & > *:nth-child(5),
  & > *:nth-child(6),
  & > *:nth-child(7) {
    text-align: center;
    justify-content: center;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 20px 10px;

    & > *:nth-child(4),
    & > *:nth-child(5),
    & > *:nth-child(6),
    & > *:nth-child(7) {
      text-align: left;
      justify-content: flex-start;
    }
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const PositionsListHeaderText = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  gap: 5px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
    gap: 3px;
  `};
`

export const PositionsListTable = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border-radius: 0 0 20px 20px;
  width: 100%;
  min-height: 100px;
  list-style: none;
  overflow: hidden;

  & > li {
    @media (max-width: 720px) {
      border-bottom: 0px;
    }
    border-bottom: 1px solid ${({ theme }) => theme.border1};

    transition: background-color 0.3s;
  }

  & > li:last-child {
    border-bottom-color: transparent;
    border-radius: 0 0 20px 20px;
  }

  & > li:hover {
    background: ${({ theme }) => theme.componentBg5};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 900px;
    padding: 0;
    overflow: initial;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: auto;
    padding: 0;
    gap: 10px;
  `};
`

export const PositionsListTableItem = styled.li`
  @media (max-width: 720px) {
    padding: 0;
  }
  padding: 0 20px;
  margin: 0;
`

export const PositionsScrollBox = styled.div`
  width: 100%;
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    overflow-x: auto;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow-x: initial;
  `};
`

export const ArrowImage = styled.img`
  transition: all 0.1s linear;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 8px;
    height: 4px;
  `};
`

export const ToggleLabel = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.primaryText1};
  font-size: 14px;
`
