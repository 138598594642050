import { useStakingIncentivesQuery } from 'hooks/queries/v3/useStakingIncentivesQuery'

export type UseCurrentPoolIncentiveTimeRemainingInputProps = {
  poolId: string
}

export const useCurrentPoolIncentiveTimeRemaining = ({ poolId }: UseCurrentPoolIncentiveTimeRemainingInputProps) => {
  const now = new Date().getTime()
  const { data: incentivesData } = useStakingIncentivesQuery('active')
  const incentivesByPoolId =
    incentivesData?.incentives.filter((incentive) => incentive.pool === poolId?.toLowerCase()) ?? []
  const actualIncentivesByPoolId =
    incentivesByPoolId?.filter((incentive) => Number(incentive.endTime) * 1000 > now) ?? []
  const longestActualIncentive = actualIncentivesByPoolId[0]

  const endTimeInMs = Number(longestActualIncentive?.endTime) * 1000
  const timeRemainingInMs = endTimeInMs - now

  if (timeRemainingInMs > 0) {
    return {
      timeRemaining: Math.round(timeRemainingInMs / 1000 / 60 / 60 / 24),
    }
  }

  return {
    timeRemaining: 0,
  }
}
