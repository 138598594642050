import styled from 'styled-components'

export const SelectViewToggleBox = styled.div<{ disabled?: boolean }>`
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${({ disabled }) => (disabled ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.5)')};
`

export const SelectViewToggleBtn = styled.button<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  margin: ${({ isOpen }) => (isOpen ? '0 0 13px' : '0')};

  border-bottom: 1px solid ${({ isOpen }) => (isOpen ? 'rgba(255, 255, 255, 0.5)' : 'transparent')};

  font-size: 13px;
  font-weight: 400;
  line-height: 170%;
  color: ${({ theme }) => theme.accentText};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
