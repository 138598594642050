import { BigNumber } from '@ethersproject/bignumber'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import JSBI from 'jsbi'
// import { NEVER_RELOAD, useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

// import { useV3NFTPositionManagerContract } from './useContract'

type TokenId = number | JSBI | BigNumber

export function usePositionTokenURI(tokenId: TokenId | undefined) {
  const contract = useV3NFTPositionManagerContract()

  const inputs = useMemo(
    () => [tokenId instanceof BigNumber ? tokenId.toHexString() : tokenId?.toString(16)],
    [tokenId]
  )

  return useQuery(['v3', 'tokenURI', tokenId], async () => {
    const response = await contract?.tokenURI(...inputs)

    const json = atob(response.substring(29))
    const result = JSON.parse(json)

    return result as { description: string; image: string; name: string }
  })
}
