import styled from 'styled-components'

export const BottomGroupingBox = styled.div`
  width: 100%;
  padding: 0 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
`

export const BottomGroupingToggle = styled.button<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 13px 0;
  margin: ${({ isOpen }) => (isOpen ? '0 0 13px' : '0')};

  border-bottom: 1px solid ${({ isOpen }) => (isOpen ? 'rgba(255, 255, 255, 0.5)' : 'transparent')};

  font-size: 13px;
  font-weight: 400;
  line-height: 170%;
  color: ${({ theme }) => theme.accentText};
  cursor: pointer;
`

export const BottomGroupingFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  margin-top: 15px;
  gap: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 1;
  flex-shrink: 0;
  border: 0 0 20px 20px;
`

export const SwapCurrenciesIconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  height: 24px;
  cursor: pointer;
  z-index: 10000;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`
