import { ChainId } from 'sdk'

const UNISWAP_V2_FACTORY_ADDRESSES: {
  [chainId: number]: string
} = {
  [ChainId.METIS]: '0x2CdFB20205701FF01689461610C9F321D1d00F80',
  [ChainId.TOMBCHAINTESTNET]: '0x82a401cBf933405d7E8B195da0A067512a0D5e10',
  [ChainId.TOMB]: '',
  [ChainId.FANTOM]: '0xE236f6890F1824fa0a7ffc39b1597A5A6077Cfe9',
  [ChainId.BSC]: '0x3FB1E7D5d9C974141A5B6E5fa4edab0a7Aa15C6A',
  [ChainId.POLYGON]: '0x3FB1E7D5d9C974141A5B6E5fa4edab0a7Aa15C6A',
}

export { UNISWAP_V2_FACTORY_ADDRESSES }
