import dayjs from 'dayjs'
import Numeral from 'numeral'

import { timeframeOptions } from 'constants/chart'

export const toK = (num: string) => {
  return Numeral(num).format('0.[00]a')
}

export const toNiceDate = (date: any) => {
  const x = dayjs.utc(dayjs.unix(date)).format('MMM DD')
  return x
}

export const toNiceDateYear = (date: any) => dayjs.utc(dayjs.unix(date)).format('MMMM DD, YYYY')

export function getTimeframe(timeWindow: string) {
  const utcEndTime = dayjs.utc()
  // based on window, get starttime
  let utcStartTime

  switch (timeWindow) {
    case timeframeOptions.WEEK:
      utcStartTime = utcEndTime.subtract(1, 'week').endOf('day').unix() - 1
      break
    case timeframeOptions.MONTH:
      utcStartTime = utcEndTime.subtract(1, 'month').endOf('day').unix() - 1
      break
    case timeframeOptions.ALL_TIME:
      utcStartTime = utcEndTime.subtract(1, 'year').endOf('day').unix() - 1
      break
    default:
      utcStartTime = utcEndTime.subtract(1, 'year').startOf('year').unix() - 1
      break
  }
  return utcStartTime
}

// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num: number, digits: number = 2) => {
  if (num === 0) {
    return '0.00'
  }

  if (num < 0.01) {
    return '>0.01'
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
  const res = formatter.format(num)
  return res.length > 56 ? res.slice(0, 53) + '...' : res
}

export const formattedNum = (number: any, usd = false, acceptNegatives = true) => {
  if (isNaN(number) || number === '' || number === undefined) {
    return usd ? '$0' : 0
  }
  const num = parseFloat(number)

  if (acceptNegatives && num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0))
  }

  if (num === 0) {
    if (usd) {
      return '$0'
    }
    return 0
  }

  if (num < 0.0001 && num > 0) {
    return usd ? '< $0.0001' : '< 0.0001'
  }

  if (num > 1000) {
    return usd ? formatDollarAmount(num, 0) : Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.1) {
      return formatDollarAmount(num, 4)
    } else {
      return formatDollarAmount(num, 2)
    }
  }

  return Number(parseFloat(String(num)).toFixed(5)).toLocaleString()
}

export const formatAmount = (num: number, digits: number = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })

  return formatter.format(num)
}
