import * as React from 'react'

import { SubgraphContext } from '../contexts/SubgraphProvider'
import Subgraph from '../subgraph'

export const SUBGRAPH_QUERY = 'subgraph'

export default function useSubgraph(): Subgraph {
  const context = React.useContext(SubgraphContext)
  if (context === undefined) {
    throw new Error('useSubgraph must be used within a SubgraphProvider!')
  }

  return context.subgraph
}
