import { useApi } from 'shared'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId } from 'hooks'
import { useCurrentChainDebankid } from '../useCurrentChainDebankId'

export type DebankTokenBalance = {
  amount: number
  decimals: number
  id: string
  logo_url: string
  name: string
  optimized_symbol: string
  price: number
  symbol: string
}

export type DebankBalancesResponse = DebankTokenBalance[]

export const useGetDebankBalancesQuery = () => {
  const { account } = useWeb3React()
  const api = useApi()
  const chainId = useChainId()
  const currentChainDebankId = useCurrentChainDebankid()

  return useQuery(
    ['debank-user-balances', account, currentChainDebankId],
    async () => {
      const debankUserBalances = await api?.deBankApi.get<DebankBalancesResponse>(
        `user/token_list?id=${account}&chain_id=${currentChainDebankId}&is_all=false`
      )

      return debankUserBalances?.data
    },
    { refetchInterval: 5000 }
  )
}
