import React from 'react'

export const LendIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7" fill="currentColor" fillOpacity="0.4" />
      <circle cx="8" cy="8" r="5" fill="currentColor" />
      <path
        d="M10.5715 8C10.5715 8.33768 10.505 8.67206 10.3758 8.98404C10.2466 9.29602 10.0572 9.5795 9.81838 9.81827C9.5796 10.0571 9.29613 10.2465 8.98415 10.3757C8.67217 10.5049 8.33779 10.5714 8.0001 10.5714"
        stroke="#888E9A"
        strokeLinecap="round"
      />
    </svg>
  )
}
