import { Box } from 'shared'
import React, { useContext, useRef, useState } from 'react'
import { Settings, X } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import {
  useExpertModeManager,
  useUserTransactionTTL,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
} from '../../state/user/hooks'
import { TYPE, useMediaWith } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'
import { SettingsIcon } from './SettingsIcon'

const SettingsIconWrapper = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.accent : theme.secondaryText1)};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  transition: all 0.3s;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  border-radius: ${({ theme }) => theme.borderRadius};

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`
const ExpertModeIndicator = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.accent};
  border-radius: 100px;
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  width: 300px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: ${({ theme }) => theme.menuBoxShadow};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.primary2};
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
`

export default function SettingsTab() {
  // const menuRef = useRef<HTMLDivElement | null>(null)
  // const isModalOpen = useModalOpen(ApplicationModal.SETTINGS)
  // const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)
  const { upToSmall } = useMediaWith()

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  // useOnClickOutside(menuRef, isModalOpen ? toggle : undefined)

  return (
    <StyledMenu>
      <Modal isOpen={showConfirmation} onRequestClose={() => setShowConfirmation(false)}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={18}>
                Are you sure?
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={14}>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
              </Text>
              <Text fontWeight={500} fontSize={14}>
                ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={13}>Turn On Expert Mode</Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      {/* <Box display="flex" alignItems="center">
        <Text fontSize="13px" color={theme.primaryText1}>
          Adv. mode
        </Text>
        <Box marginLeft="10px" display="inline-flex" alignItems="center">
          <Toggle
            isActive={expertMode}
            toggle={() => {
              toggleExpertMode()
            }}
          />
        </Box>
      </Box> */}
    </StyledMenu>
  )
}
