import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { NavLink } from 'react-router-dom'

export const AppLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  min-width: 202px;

  background: #ffffff;
  color: ${({ theme }) => theme.text1};

  font-size: 15px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  border-radius: 40px;
  outline: none;
  border: 0;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${() => darken(0.05, '#FFFFFF')};
  }

  &:focus {
    box-shadow: 0 0 0 1pt ${() => darken(0.05, '#FFFFFF')};
    background-color: ${() => darken(0.05, '#FFFFFF')};
  }

  &:active {
    box-shadow: 0 0 0 1pt ${() => darken(0.1, '#FFFFFF')};
    background-color: ${() => darken(0.1, '#FFFFFF')};
  }
`
