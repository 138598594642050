import React from 'react'

export const FaucetIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9313 9.4C14.9313 12.4928 11.7973 15 7.93127 15C4.06528 15 0.931274 12.4928 0.931274 9.4C0.931274 8.65737 1.11197 7.9485 1.4401 7.3C2.47853 5.24772 7.93127 1 7.93127 1C7.93127 1 13.384 5.24772 14.4224 7.3C14.7506 7.9485 14.9313 8.65737 14.9313 9.4Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
