import useTheme from 'hooks/useTheme'
import React from 'react'

export const SwapCurrenciesIcon: React.FC = () => {
  const theme = useTheme()

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 17L7 21L11 17M21 7L17 3L13 7" stroke={theme.primary1} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M17 21V3M7 3V21V3Z"
        stroke={theme.primary1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
