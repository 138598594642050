import { rgba } from 'polished'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as RightUpArrow } from 'assets/svg/right-up-arrow.svg'
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right-icon.svg'

export const CommunityDropdownBox = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  left: -427px; // half of the CommunityDropdownBox
  transform-origin: calc(50% - 90px) 0px; // same place as for the ::after
  transform: translateX(0) scale(0.8);
  max-width: 854px;

  display: flex;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  padding: 20px;
  width: max-content;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;
  border-radius: 12px;
  visibility: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    top: -17px;
    left: 0;
  }

  // &::after {
  //   content: '';
  //   display: block;
  //   width: 24px;
  //   height: 24px;
  //   border-radius: 5px;
  //   transform: rotate(45deg);
  //   position: absolute;
  //   top: -9px;
  //   right: calc(50% - 90px); // half of the CommunityDropdownBox - approx. 1/2 of navbar button
  //   z-index: -1;
  //   background: linear-gradient(
  //     rgba(255, 255, 255, 0.08) 0%,
  //     rgba(255, 255, 255, 0.08) 49.48%,
  //     rgba(211, 211, 211, 0.08) 69.79%,
  //     rgba(255, 255, 255, 0.08) 100%
  //   );
  //   -webkit-backdrop-filter: blur(60px);
  //   backdrop-filter: blur(60px);
  // }

  @media screen and (max-width: 1510px) {
    left: -427px; // half of the CommunityDropdownBox
    transform: translateX(0) scale(0.8);
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    transform: translateX(10%) scale(0.8);
  `};
`

export const CommunityDropdownContent = styled.div`
  display: flex;
  gap: 40px;
`

export const CommunityDropdownCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const CommunityDropdownTitle = styled.div`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.dropdownTitle};
`

export const CommunityDropdownSocialList = styled.div`
  display: flex;
  flex-direction: column;
`

export const ArrowRightUpStyledIcon = styled(RightUpArrow)`
  width: 8px;
  height: 8px;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
  margin-left: auto;
`

export const ArrowRightStyledIcon = styled(ArrowRight)`
  width: 6px;
  height: 10px;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
  margin-left: auto;
`

export const CommunityDropdownSocial = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 16px;
  text-decoration: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.primaryText1};

  & > ${ArrowRightUpStyledIcon} {
    width: 8px;
    height: 8px;
  }

  &:hover {
    background: #ffffff0d;
  }

  & > svg {
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover > svg {
    color: ${({ theme }) => theme.secondaryText1};
  }

  &:hover > ${ArrowRightUpStyledIcon} {
    opacity: 1;
  }
`

export const CommunityDropdownDocsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const CommunityDropdownDoc = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 16px;
  text-decoration: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.primaryText1};

  & > ${ArrowRightStyledIcon} {
    width: 8px;
    height: 8px;
  }

  &:hover {
    background: #ffffff0d;
  }

  & > svg {
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    color: ${({ theme }) => theme.primaryText1};
  }

  &:hover > svg {
    color: ${({ theme }) => theme.secondaryText1};
  }

  &:hover > ${ArrowRightStyledIcon} {
    opacity: 1;
  }
`

export const CommunityDropdownDocCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const CommunityDropdownDocTitle = styled.span`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`

export const CommunityDropdownDocText = styled.span`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};
`

export const CommunityDropdownRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
`

export const CommunityDropdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const CommunityDropdownImage = styled.img`
  display: block;
  max-width: 224px;
  object-fit: cover;
  border-radius: 8px;
`

export const CommunityDropdownUpdate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.secondaryText1};

  & > svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.primaryText1};
  }
`

export const CommunityDropdownUpdateLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-decoration: none;
`

export const CommunityDropdownUpdateLinkText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-decoration: none;
`
