import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LegacyDropdownBox,
  EarnDropdownCol,
  EarnDropdownTitle,
  EarnDropdownList,
  EarnDropdownListItem,
  EarnDropdownListItemCol,
  EarnDropdownListItemTitle,
  ArrowRightStyledIcon,
  EarnDropdownRow,
  EarnDropdownListItemText,
  PoolsStyledIcon,
  Lif3StyledIcon,
} from './styles'
import { useChainId } from 'hooks'
import { IconWrapper } from 'theme'
import { ReactComponent as Lif3LogoShortSmallIcon } from 'assets/svg/lif3-logo-short-small.svg'
import { CloseCircleIcon } from 'icons/CloseCircleIcon'

export const EarnDropdownMobile = (props) => {
  const chainId = useChainId()
  const chainIdOnlySearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId])
  const withoutDropdown = chainId === 1811 || chainId === 1
  const isEthereum = chainId === 1

  return (
    <LegacyDropdownBox>
      <EarnDropdownCol>
        <EarnDropdownRow>
          <div style={{ width: '22px' }}></div>
          <EarnDropdownTitle>Earn</EarnDropdownTitle>
          <IconWrapper size="22px" stroke="currentColor">
            <CloseCircleIcon />
          </IconWrapper>
        </EarnDropdownRow>
        <EarnDropdownList>
          {!withoutDropdown && (
            <EarnDropdownListItem onClick={() => props.toggleEarn(!props.earnState)}>
              <IconWrapper size="20px" stroke="transparent">
                <Lif3LogoShortSmallIcon />
              </IconWrapper>
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>Legacy (Ended)</EarnDropdownListItemTitle>
              </EarnDropdownListItemCol>
              <ArrowRightStyledIcon />
            </EarnDropdownListItem>
          )}
          <EarnDropdownListItem
            as={Link}
            to={{
              pathname: '/pools',
              search: chainIdOnlySearchParams.toString(),
            }}
          >
            <PoolsStyledIcon />
            <EarnDropdownListItemCol>
              <EarnDropdownListItemTitle>Pools</EarnDropdownListItemTitle>
              <EarnDropdownListItemText>
                Provide liquidity and earn swap fees & $LSHARE incentives.
              </EarnDropdownListItemText>
            </EarnDropdownListItemCol>
          </EarnDropdownListItem>
          {isEthereum && (
            <EarnDropdownListItem
              as={Link}
              to={{
                pathname: '/nursery',
                search: chainIdOnlySearchParams.toString(),
              }}
            >
              <Lif3StyledIcon />
              <EarnDropdownListItemCol>
                <EarnDropdownListItemTitle>{'Nursery (Genesis)'}</EarnDropdownListItemTitle>
                <EarnDropdownListItemText>{'Deposit $ETH, $USDT or $BTC and earn $LIF3!'}</EarnDropdownListItemText>
              </EarnDropdownListItemCol>
            </EarnDropdownListItem>
          )}
        </EarnDropdownList>
      </EarnDropdownCol>
    </LegacyDropdownBox>
  )
}
