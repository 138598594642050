import { LendingPoolData } from 'pages/Lending/types/interfaces'
import getLendingPool from '../../services/get-lending-pool'

const INITIAL_LENDING_POOL_FETCHER = 'initial-lending-pool-fetcher'

// TODO: should type properly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const initialLendingPoolFetcher = async ({ queryKey }: any, chainId): Promise<LendingPoolData> => {
  const [_key, lowerCasedUniswapV2PairAddress] = queryKey

  if (_key !== INITIAL_LENDING_POOL_FETCHER) {
    throw new Error('Invalid key!')
  }

  return await getLendingPool(lowerCasedUniswapV2PairAddress, chainId)
}

export { INITIAL_LENDING_POOL_FETCHER }

// export type { LendingPoolData }

export default initialLendingPoolFetcher
