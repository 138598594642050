import { ChainId } from 'sdk'

const IMPERMAX_SUBGRAPH_URLS: {
  [chainId: number]: string
} = {
  [ChainId.TOMBCHAINTESTNET]: 'http://168.119.232.67:8000/subgraphs/name/lifelend',
  [ChainId.TOMB]: 'https://graph-node.lif3.com/subgraphs/name/lifelend',
  // [ChainId.FANTOM]: 'https://graph-node.tomb.com/subgraphs/name/mortuary',
  [ChainId.FANTOM]: 'https://api.studio.thegraph.com/query/72851/lif3-fantom-terrace/0.0.1',
  [ChainId.BSC]: 'https://api.studio.thegraph.com/query/72851/terrace-bsc/v0.0.1',
  [ChainId.POLYGON]: 'https://api.studio.thegraph.com/query/72851/terrace-polygon/v0.0.1',
}

const BLOCKLYTICS_SUBGRAPH_URL = {
  [ChainId.TOMBCHAINTESTNET]: 'http://168.119.232.67:8000/subgraphs/name/tombchainblocks',
  [ChainId.TOMB]: 'https://graph-node.lif3.com/subgraphs/name/tombchainblocks',
  [ChainId.FANTOM]: 'https://api.thegraph.com/subgraphs/name/matthewlilley/fantom-blocks',
  [ChainId.BSC]: 'https://thegraph.com/hosted-service/subgraph/matthewlilley/bsc-blocks',
  [ChainId.POLYGON]: 'https://thegraph.com/hosted-service/subgraph/matthewlilley/polygon-blocks',
}

export { IMPERMAX_SUBGRAPH_URLS, BLOCKLYTICS_SUBGRAPH_URL }
