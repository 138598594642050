import React from 'react'
import { Price } from 'sdk'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'
import { useActiveWeb3React } from '../../hooks'
import Row from 'components/Row'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)
  const getFormattedPrice = () => {
    if (showInverted) {
      return price?.toSignificant(6)
    }

    return price?.toSignificant(6) !== '0' ? price?.invert()?.toSignificant(6) : '0'
  }

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.getSymbol()} per ${price?.baseCurrency?.getSymbol()}`
    : `${price?.baseCurrency?.getSymbol()} per ${price?.quoteCurrency?.getSymbol()}`

  return (
    <Row justify="flex-end" gap="4px">
      <Text
        fontSize={13}
        color={theme.primaryText1}
        flexGrow={1}
        textAlign={'right'}
        style={{
          maxWidth: '70%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {show ? (
          <>
            {getFormattedPrice() ?? '-'}
            {/* <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini> */}
          </>
        ) : (
          '-'
        )}
      </Text>
      <Text textAlign="right" fontSize={13} color={theme.primaryText1}>
        {label}
      </Text>
    </Row>
  )
}
