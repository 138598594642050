import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 20px;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.text1};
`
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.accent2};
  border: 1px solid ${({ theme }) => theme.accent2};
  border-radius: 0.5rem;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 535;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.border1};
  :hover {
    border: 1px solid ${({ theme }) => theme.border1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.border1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
`

export const LoadingRows = styled.div`
  padding-top: 36px;
  min-width: 75%;
  max-width: 960px;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`

export const PoolsTab = styled.button<{ isActive: boolean }>`
  padding: 5px 10px;
  border-radius: 10px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 23px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 300)};
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.dropdownTitle)};
  background: ${({ theme, isActive }) => (isActive ? theme.white : 'transparent')};
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`
