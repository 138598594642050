import TombLogo from 'assets/images/logos/tombchainmini.svg'
import FantomLogo from 'assets/images/logos/fantom.svg'
import BscLogo from 'assets/images/logos/bscchain.svg'
import PolygonLogo from 'assets/images/logos/polygonchain.svg'
import AvaLogo from 'assets/images/logos/avachain.svg'
import EthLogo from 'assets/images/logos/ethchain.svg'
import baseLogo from 'assets/images/logos/base-logo.svg'
import Lif3Logo from 'assets/images/logos/lif3-logo-simple.svg'

import ArbitrumLogo from 'assets/images/logos/arbitrum.svg'
import OpLogo from 'assets/images/logos/optimism.svg'
import { ChainId } from 'sdk/constants'

export type Chain = {
  logo: string
  name: string
  color: string
  url?: string
  isInnerLink?: boolean
  chainId: ChainId
  new?: boolean
  isTestnet?: boolean
}

export type Chains = {
  [key in ChainId]?: Chain
}

export const chains: Chains = {
  [ChainId.TOMB]: {
    logo: TombLogo,
    name: 'Tomb Chain',
    color: '#6B00FA',
    url: 'https://tombchain.lif3.com/',
    isInnerLink: true,
    chainId: ChainId.TOMB,
  },
  [ChainId.FANTOM]: {
    logo: FantomLogo,
    name: 'Fantom',
    color: '#1969FF',
    chainId: ChainId.FANTOM,
  },
  [ChainId.BSC]: {
    logo: BscLogo,
    name: 'BNB Chain',
    color: '#F0B90B',
    chainId: ChainId.BSC,
  },
  [ChainId.POLYGON]: {
    logo: PolygonLogo,
    name: 'Polygon',
    color: '#8247E5',
    chainId: ChainId.POLYGON,
  },
  [ChainId.AMOY_TESTNET]: {
    logo: PolygonLogo,
    name: 'Polygon Amoy',
    color: '#8247E5',
    chainId: ChainId.AMOY_TESTNET,
    isTestnet: true,
  },
  [ChainId.ETHEREUM]: {
    logo: EthLogo,
    name: 'Ethereum',
    color: '#959595',
    chainId: ChainId.ETHEREUM,
  },
  // [ChainId.AVALANCHE]: {
  //   logo: AvaLogo,
  //   name: 'Avalanche',
  //   color: '#E84142',
  // },
  // [ChainId.OPTIMISM]: {
  //   logo: OpLogo,
  //   name: 'Optimism',
  //   color: '#FF0420',
  //   chainId: ChainId.OPTIMISM,
  // },
  // [ChainId.ARBITRUM]: {
  //   logo: ArbitrumLogo,
  //   name: 'Arbitrum',
  //   color: '#2C374B',
  //   chainId: ChainId.ARBITRUM,
  // },
  // [ChainId.BASE]: {
  //   logo: baseLogo,
  //   name: 'Base',
  //   color: '#0052FF',
  //   chainId: ChainId.BASE,
  // },
  // [ChainId.LIF3CHAIN]: {
  //   logo: Lif3Logo,
  //   name: 'Lif3 Chain',
  //   color: '#3C5D86',
  // },
  // [ChainId.LIF3CHAIN_TESTNET]: {
  //   logo: Lif3Logo,
  //   name: 'Lif3 Chain',
  //   color: '#3C5D86',
  //   chainId: ChainId.LIF3CHAIN_TESTNET,
  //   isTestnet: true,
  //   new: true,
  // },
}
