import React from 'react'

export const GlobalSettingsIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.0936 19.6187C29.3049 18.9969 28.9242 18.0043 28.9242 17C28.9242 16.0004 29.2947 15.0014 30.0827 14.3863L32.2825 12.6687C32.591 12.4281 32.6766 11.9641 32.4881 11.6203L29.1984 5.91406C28.9928 5.53594 28.5644 5.41562 28.1874 5.53594L25.5788 6.58703C24.6572 6.95836 23.6254 6.79607 22.7692 6.29196C21.9055 5.78344 21.2304 4.95999 21.0901 3.96757L20.6998 1.20469C20.6312 0.792187 20.2885 0.5 19.8773 0.5H13.2977C12.8865 0.5 12.561 0.792187 12.4924 1.20469L12.1024 3.96486C11.962 4.95896 11.2783 5.78912 10.414 6.29992C9.55986 6.80471 8.53656 6.95898 7.6163 6.58819L5.00474 5.53594C4.62779 5.39844 4.19943 5.53594 3.99382 5.91406L0.721172 11.6203C0.515561 11.9812 0.584098 12.4281 0.926784 12.6687L3.12438 14.3845C3.9137 15.0008 4.25084 15.9986 4.25084 17C4.25084 17.9996 3.88032 18.9985 3.09242 19.6137L0.892515 21.3313C0.584098 21.5719 0.498427 22.0359 0.686904 22.3797L3.97669 28.0859C4.1823 28.4641 4.61066 28.5844 4.98761 28.4641L7.59629 27.413C8.5179 27.0416 9.54962 27.2039 10.4058 27.708C11.2695 28.2166 11.9447 29.04 12.0849 30.0324L12.4753 32.7953C12.561 33.2078 12.8865 33.5 13.2977 33.5H19.8773C20.2885 33.5 20.6312 33.2078 20.6826 32.7953L21.0742 30.0237C21.2137 29.0367 21.8903 28.216 22.7507 27.7126C23.612 27.2087 24.6447 27.0435 25.5704 27.4165L28.1703 28.4641C28.5473 28.6016 28.9756 28.4641 29.1812 28.0859L32.471 22.3797C32.6766 22.0016 32.591 21.5719 32.2654 21.3313L30.0936 19.6187ZM16.5875 23.1875C13.1949 23.1875 10.4192 20.4031 10.4192 17C10.4192 13.5969 13.1949 10.8125 16.5875 10.8125C19.9801 10.8125 22.7559 13.5969 22.7559 17C22.7559 20.4031 19.9801 23.1875 16.5875 23.1875Z"
        fill="#C7C7C7"
      />
    </svg>
  )
}
