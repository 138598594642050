import { ChainId, WNATIVE, NATIVE_CURRENCY } from 'sdk'

export const sliderTokens = {
  [ChainId.BSC]: {
    LIF3: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    LSHARE: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    [NATIVE_CURRENCY[ChainId.BSC]?.symbol ?? '']: WNATIVE[ChainId.BSC]?.address,
    BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  [ChainId.FANTOM]: {
    LIF3: '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
    LSHARE: '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
    [NATIVE_CURRENCY[ChainId.FANTOM]?.symbol ?? '']: WNATIVE[ChainId.FANTOM]?.address,
    BTC: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    ETH: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  },
  [ChainId.POLYGON]: {
    LIF3: '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
    LSHARE: '0xFB40b1eFe90D4b786D2D9d9dc799B18BDe92923b',
    [NATIVE_CURRENCY[ChainId.POLYGON]?.symbol ?? '']: WNATIVE[ChainId.POLYGON]?.address,
    BTC: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    ETH: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  // actual eth tokens
  // [ChainId.ETHEREUM]: {
  //   LIF3: '0xB66A4f641A7a2216aF82894556Aa7b825a70D075',
  //   LSHARE: '0x7aF3f80c5788a9f83800d308Be671bdd3c0Db796',
  //   [NATIVE_CURRENCY[ChainId.ETHEREUM]?.symbol ?? '']: WNATIVE[ChainId.ETHEREUM]?.address,
  //   BTC: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  // },
  // TODO: replace with actial eth tokens from above when token info subgraph is ready
  [ChainId.ETHEREUM]: {
    LIF3: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    LSHARE: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  // actual OP tokens
  // [ChainId.OPTIMISM]: {
  //   BTC: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
  //   ETH: '0x4200000000000000000000000000000000000006',
  // },
  // actual ARB tokens
  // [ChainId.ARBITRUM]: {
  //   BTC: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
  //   ETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  // },
  [ChainId.OPTIMISM]: {
    LIF3: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    LSHARE: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  [ChainId.ARBITRUM]: {
    LIF3: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    LSHARE: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  [ChainId.BASE]: {
    LIF3: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    LSHARE: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  [ChainId.LIF3CHAIN_TESTNET]: {
    WLIF3: '0x6883d461FE9FB40D3Ff3393fDE5147A6617b3E30',
    LSHARE: '0x6aAaa67227D8D29D26115a433130D882A876507d',
  },
}

export const CHAIND_IDS_WITHOUT_SUITE = [ChainId.ETHEREUM, ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE] // no farms etc
export const CHAIN_IDS_FOR_POOLS = [
  ChainId.ETHEREUM,
  ChainId.FANTOM,
  ChainId.POLYGON,
  ChainId.LIF3CHAIN_TESTNET,
  ChainId.BSC,
] // pools are only on these chains
export const SUPPORTED_CHAIN_IDS_FOR_DEFI_FEATURES = [ChainId.FANTOM, ChainId.POLYGON, ChainId.BSC] // The Garden, Liquidity, Greenhouse, Terrace
export const CHAIN_IDS_FOR_NURSERY = [ChainId.ETHEREUM, ChainId.FANTOM, ChainId.POLYGON, ChainId.BSC] // The Nursery
export const CHAIN_IDS_WITHOUT_EARN = [ChainId.OPTIMISM, ChainId.ARBITRUM, ChainId.BASE] // no earn
