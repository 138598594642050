import deLangIcon from './flags/de.svg'
import enLangIcon from './flags/en.svg'
import cnLangIcon from './flags/cn.svg'
import esLangIcon from './flags/es.svg'
import krLangIcon from './flags/kr.svg'
import nlLangIcon from './flags/nl.svg'
import ptLangIcon from './flags/pt.svg'
import arLangIcon from './flags/ar.svg'
import ruLangIcon from './flags/ru.svg'

export const languages = [
  {
    logo: arLangIcon,
    name: 'ar',
    label: 'Arabic',
  },
  {
    logo: cnLangIcon,
    name: 'cn',
    label: 'Chinese',
  },
  {
    logo: nlLangIcon,
    name: 'nl',
    label: 'Dutch',
  },
  {
    logo: enLangIcon,
    name: 'en',
    label: 'English',
  },
  {
    logo: deLangIcon,
    name: 'de',
    label: 'German',
  },
  {
    logo: krLangIcon,
    name: 'ko',
    label: 'Korean',
  },
  {
    logo: ptLangIcon,
    name: 'pt',
    label: 'Portuguese',
  },
  {
    logo: ruLangIcon,
    name: 'ru',
    label: 'Russian',
  },
  {
    logo: esLangIcon,
    name: 'es',
    label: 'Spanish',
  },
]
