import { NATIVE_CURRENCY } from 'sdk'
import { Trade, TradeType } from 'sdk'
import { Box } from 'shared'
import Big from 'big.js'
import { CurrencyDirection } from 'enums/common'
import React, { useContext } from 'react'
import { useLPorCurrencyBalanceQuery } from 'state/wallet/hooks'
import styled, { ThemeContext } from 'styled-components'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE, ExternalLink, LinkStyledButton } from '../../theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import Row, { RowBetween, RowFixed, AutoRow } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'
import SlippageTabs from 'components/TransactionSettings'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import { useChainId } from 'hooks'

const InfoLink = styled(ExternalLink)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primary2};
  padding: 6px 6px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
`

const SlippageBox = styled.div`
  margin-top: 16px;
`

const RouteBox = styled.div`
  margin: 16px -20px 0;
  padding: 16px 20px 0;
  border-top: 1px solid ${({ theme }) => theme.border2};
`

function TradeSummary({ trade, allowedSlippage }: { trade: Trade | undefined; allowedSlippage: number }) {
  const chainId = useChainId()
  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade, chainId)
  const isExactIn = trade?.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage, chainId)

  return (
    <>
      <AutoColumn gap="4px">
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              {isExactIn ? 'Min received' : 'Max sold'}
            </TYPE.black>
          </RowFixed>
          <RowFixed>
            <TYPE.black color={theme.primaryText1} fontSize={13}>
              {trade
                ? isExactIn
                  ? `${slippageAdjustedAmounts[CurrencyDirection.OUTPUT]?.toSignificant(4)} ${
                      trade?.outputAmount.currency.symbol
                    }` ?? '-'
                  : `${slippageAdjustedAmounts[CurrencyDirection.INPUT]?.toSignificant(4)} ${
                      trade?.inputAmount.currency.symbol
                    }` ?? '-'
                : ''}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              Price Impact
            </TYPE.black>
          </RowFixed>
          <RowFixed>
            {priceImpactWithoutFee ? <FormattedPriceImpact priceImpact={priceImpactWithoutFee} /> : ''}
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              Fee
            </TYPE.black>
          </RowFixed>
          <Row width="100%" maxWidth={'90%'} gap="4px" justify="flex-end">
            <TYPE.black
              fontSize={13}
              color={theme.primaryText1}
              style={{ maxWidth: '70%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
            >
              {realizedLPFee ? (realizedLPFee ? `${realizedLPFee.toSignificant(4)}` : '-') : ''}
            </TYPE.black>
            <TYPE.black fontSize={13} color={theme.primaryText1}>
              {trade?.inputAmount.currency.getSymbol()}
            </TYPE.black>
          </Row>

          <QuestionHelper
            text={`A portion of each trade (${0.5}%) goes to liquidity providers (LP) as a protocol incentive.`}
          />
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade | undefined
}

export function AdvancedSwapDetailsFixed({ trade }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)
  const chainId = useChainId()

  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  const metisBalanceQuery = useLPorCurrencyBalanceQuery(NATIVE_CURRENCY[chainId])
  const { currencies } = useDerivedSwapInfo()
  return (
    <AutoColumn gap="0px">
      <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />

      {metisBalanceQuery.data && new Big(metisBalanceQuery.data.toExact()).eq(0) ? (
        <Box mt="4px">
          <a href="https://docs.lif3.com" target="_blank" rel="noreferrer">
            <LinkStyledButton style={{ color: theme.accent2, padding: 0, fontSize: '13px', fontWeight: 400 }}>
              Need gas?
            </LinkStyledButton>
          </a>
        </Box>
      ) : null}

      <SlippageBox>
        <SlippageTabs
          currencies={{ tokenA: currencies[CurrencyDirection.INPUT], tokenB: currencies[CurrencyDirection.OUTPUT] }}
        />
      </SlippageBox>

      {trade && (
        <>
          {/* <TradeSummary trade={trade} allowedSlippage={allowedSlippage} /> */}
          {showRoute && (
            <RouteBox>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <TYPE.black fontSize={13} fontWeight={400} color={theme.primaryText1}>
                    Route:
                  </TYPE.black>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </span>
                <Box display="flex" justifyContent="center" mt="8px">
                  <SwapRoute trade={trade} />
                </Box>
              </Box>
            </RouteBox>
          )}
        </>
      )}
    </AutoColumn>
  )
}
