import { BigNumber } from '@ethersproject/bignumber'
import { Percent, Price, Token } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import RangeBadge from 'components/Badge/RangeBadge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import HoverInlineText from 'components/HoverInlineText'
import Row from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import useIsTickAtLimit from 'hooks/v3/useIsTickAtLimit'
import { usePool } from 'hooks/v3/usePools'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Bound } from 'state/mint/v3/actions'
import Loader from 'components/Loader'
import { formatTickPrice } from 'utils/formatters'
import { NATIVE_CURRENCY, WNATIVE } from 'sdk'
import { USDC } from '../../../../constants'
import { unwrappedToken } from 'sdk/v3/utils'
import { useChainId } from 'hooks'
import { Ether } from 'sdk/v3'
import { Text } from 'rebass'
import {
  RangeLineItem,
  DoubleArrow,
  RangeText,
  FeeTierText,
  ExtentsText,
  PrimaryPositionIdData,
  PositionsListItemBox,
  PositionRewardTextPrimary,
  PositionRewardTextSecondary,
} from './styles'
import { AutoColumn } from 'components/Column'
import { useUserRewardInfo } from 'hooks/v3/useUserRewardInfo'
import { useCurrentIncentiveApr } from 'hooks/v3/useCurrentIncentiveApr'
import { useIncentivizedPools } from 'hooks/v3/useIncentivizedPools'
import { useCurrentPoolIncentiveTimeRemaining } from 'hooks/v3/useCurrentPoolIncentiveTimeRemaining'

interface PositionListItemProps {
  token0: string
  token1: string
  tokenId: BigNumber
  fee: number
  liquidity: BigNumber
  tickLower: number
  tickUpper: number
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>
  priceUpper?: Price<Token, Token>
  quote?: Token
  base?: Token
} {
  if (!position) {
    return {}
  }

  const token0 = position.amount0.currency
  const token1 = position.amount1.currency

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [...Object.values(USDC)]
  if (stables.some((stable) => stable.symbol === token0.symbol)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WNATIVE)]
  if (bases.some((base) => base && base.symbol === token1.symbol)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    }
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  }
}

export default function PositionListItem({
  token0: token0Address,
  token1: token1Address,
  tokenId,
  fee: feeAmount,
  liquidity,
  tickLower,
  tickUpper,
}: PositionListItemProps) {
  const chainId = useChainId()
  const currency0v2 = useCurrency(token0Address, 'V3')
  const currency1v2 = useCurrency(token1Address, 'V3')

  const currency0 =
    currency0v2?.symbol === NATIVE_CURRENCY[chainId].symbol
      ? Ether.onChain(chainId)
      : currency0v2 && new Token(chainId, token0Address, currency0v2.decimals, currency0v2.symbol, currency0v2.name)

  const currency1 =
    currency1v2?.symbol === NATIVE_CURRENCY[chainId].symbol
      ? Ether.onChain(chainId)
      : currency1v2 && new Token(chainId, token1Address, currency1v2.decimals, currency1v2.symbol, currency1v2.name)

  // construct Position from details returned
  const [, pool, poolId] = usePool(currency0 ?? undefined, currency1 ?? undefined, feeAmount)
  const searchParams = useMemo(
    () =>
      new URLSearchParams({
        chainId: chainId.toString(),
        pool: poolId,
      }),
    [poolId, chainId]
  )

  const position = useMemo(() => {
    if (pool) {
      return new Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper })
    }
    return undefined
  }, [liquidity, pool, tickLower, tickUpper])

  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper)

  // prices
  const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position)

  const currencyQuote = quote
  const currencyBase = base

  // check if price is within range
  const outOfRange: boolean = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false

  const positionSummaryLink = '/pools/' + tokenId

  const removed = liquidity?.eq(0)

  const { userReward, userRewardUSD, rewardTokenInfo } = useUserRewardInfo({
    tokenId: tokenId.toNumber(),
    poolId,
  })
  const pools = useIncentivizedPools()
  const currentPool = pools?.find((pool) => pool.id === poolId?.toLowerCase())

  const { apr } = useCurrentIncentiveApr({ poolId, tvl: currentPool?.tvlUsd ?? 0 })
  const { timeRemaining } = useCurrentPoolIncentiveTimeRemaining({
    poolId,
  })

  return (
    <PositionsListItemBox as={Link} to={{ pathname: positionSummaryLink, search: searchParams.toString() }}>
      <Row align="center" gap="8px">
        <AutoColumn>
          <PrimaryPositionIdData>
            <DoubleCurrencyLogo currency0={currencyBase} currency1={currencyQuote} size={30} />
            <Text fontSize="17px" fontWeight={500}>
              &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}
            </Text>
            <FeeTierText>{new Percent(feeAmount.toString(), 1_000_000).toSignificant()}%</FeeTierText>
          </PrimaryPositionIdData>
          {priceLower && priceUpper ? (
            <RangeLineItem>
              <RangeText>
                <ExtentsText>Min:</ExtentsText>
                <span>
                  {formatTickPrice({
                    price: priceLower,
                    atLimit: tickAtLimit,
                    direction: Bound.LOWER,
                  })}{' '}
                </span>
                <HoverInlineText text={currencyQuote?.symbol} /> per{' '}
                <HoverInlineText text={currencyBase?.symbol ?? ''} />
              </RangeText>{' '}
              <DoubleArrow>↔</DoubleArrow>{' '}
              <RangeText>
                <ExtentsText>Max:</ExtentsText>
                <span>
                  {formatTickPrice({
                    price: priceUpper,
                    atLimit: tickAtLimit,
                    direction: Bound.UPPER,
                  })}{' '}
                </span>
                <HoverInlineText text={currencyQuote?.symbol} /> per{' '}
                <HoverInlineText maxCharacters={10} text={currencyBase?.symbol} />
              </RangeText>
            </RangeLineItem>
          ) : (
            <Loader />
          )}
        </AutoColumn>
      </Row>
      <Row align="center" gap="8px">
        {/* <AutoColumn>
          <PositionRewardTextPrimary>{`${userReward ?? '-'} ${
            rewardTokenInfo?.symbol ?? ''
          }`}</PositionRewardTextPrimary>
          <PositionRewardTextSecondary>{`~${userRewardUSD ?? '0.00'}$`}</PositionRewardTextSecondary>
        </AutoColumn> */}
      </Row>
      <Row align="center" gap="8px">
        {/* <PositionRewardTextPrimary>{`${apr.toFixed(2)}%`}</PositionRewardTextPrimary> */}
      </Row>
      <Row align="center" gap="8px">
        <PositionRewardTextPrimary>{String(tokenId)}</PositionRewardTextPrimary>
      </Row>
      <RangeBadge removed={removed} inRange={!outOfRange} />
    </PositionsListItemBox>
  )
}
