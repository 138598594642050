import React from 'react'
import { Text } from 'rebass'
import { Currency, currencyEquals, NATIVE_CURRENCY, Token, WNATIVE } from 'sdk'
import styled from 'styled-components'

import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { useChainId } from 'hooks'
import { DEFAULT_TOKENS } from '../../constants'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.primary2)};
  border-radius: 10px;
  display: flex;
  padding: 6px;
  transition: all 0.3s;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  background-color: ${({ theme, disable }) => disable && theme.primary2};
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  onSelect,
  selectedCurrency,
}: {
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const chainId = useChainId()

  const featuresTokens = [
    // DEFAULT_TOKENS[chainId]?.LIF3,
    // DEFAULT_TOKENS[chainId]?.LSHARE,
    // DEFAULT_TOKENS[chainId]?.L3USD,
  ]

  const areThereFeaturedTokens = featuresTokens.find((token) => token !== undefined)

  return areThereFeaturedTokens ? (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontWeight={500} fontSize={14}>
          Featured tokens
        </Text>
      </AutoRow>
      <AutoRow gap="4px">
        {featuresTokens.map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency?.address === token?.address

          if (!token) return null

          return (
            <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
              <Text fontWeight={500} fontSize={16}>
                {token?.getSymbol()}
              </Text>
            </BaseWrapper>
          )
        })}
        {/* <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, NATIVE_CURRENCY[chainId])) {
              onSelect(NATIVE_CURRENCY[chainId])
            }
          }}
          disable={selectedCurrency === NATIVE_CURRENCY[chainId]}
        >
          <CurrencyLogo currency={NATIVE_CURRENCY[chainId]} style={{ marginRight: 8 }} />
          <Text fontWeight={500} fontSize={16}>
            {Currency.getNativeCurrencySymbol()}
          </Text>
        </BaseWrapper> */}
      </AutoRow>
    </AutoColumn>
  ) : null
}
