import axios from 'axios'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'

import { useChainId } from 'hooks'
import { useSelectedCurrencies } from 'state/swap/hooks'

export const timeframeOptions = {
  '1H': '1H',
  '4H': '4H',
  '1D': '1D',
  '1W': '1W',
  '1M': '1M',
} as const

export type TimeframeOption = keyof typeof timeframeOptions

const from = {
  [timeframeOptions['1H']]: dayjs().subtract(1, 'hour').unix(),
  [timeframeOptions['4H']]: dayjs().subtract(4, 'hour').unix(),
  [timeframeOptions['1D']]: dayjs().subtract(1, 'day').unix(),
  [timeframeOptions['1W']]: dayjs().subtract(1, 'week').unix(),
  [timeframeOptions['1M']]: dayjs().subtract(1, 'month').unix(),
}

export const useCGPrices = (selectedTimeframe: TimeframeOption, coinId: string | undefined) => {
  const chainId = useChainId()
  const { wrappedInputCurrency, wrappedOutputCurrency } = useSelectedCurrencies()

  return useQuery(
    ['use-coingecko-chart-data', selectedTimeframe, coinId, chainId],
    async () => {
      try {
        const response = await axios.get<{ prices: [number, number][] }>(
          `https://pro-api.coingecko.com/api/v3/coins/${coinId}/market_chart/range?vs_currency=usd&from=${
            from[selectedTimeframe]
          }&to=${dayjs().unix()}`,
          { headers: { 'x-cg-pro-api-key': 'CG-BQ8mb7aut1hmNytgwmHofZoU' } }
        )

        const result = response.data.prices.map(([date, price]) => {
          return { date: date, price }
        })

        return result
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: Boolean(chainId) && Boolean(coinId),
      refetchOnWindowFocus: false,
      refetchInterval: 10_000,
    }
  )
}
