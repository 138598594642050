import { useMemo } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React, useChainId } from '../../hooks'
import { useAddPopup, useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'
import { finalizeTransaction } from './actions'
import { TransactionDetails } from './reducer'
import { PROVIDERS_BY_CHAIN } from 'connectors'

export function shouldCheck(tx: TransactionDetails): boolean {
  if (tx.receipt) return false

  return true
}

export default function Updater(): null {
  const chainId = useChainId()

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactions']>((state) => state.transactions)

  const transactions = useMemo(() => {
    return chainId ? state[chainId] : undefined
  }, [chainId, state])

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !PROVIDERS_BY_CHAIN[chainId] || !transactions) return

    Object.keys(transactions)
      .filter((hash) => shouldCheck(transactions[hash]))
      .forEach((hash) => {
        PROVIDERS_BY_CHAIN[chainId]
          .waitForTransaction(hash)
          .then((receipt) => {
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                })
              )

              addPopup(
                {
                  txn: {
                    hash,
                    success: receipt.status === 1,
                    summary: transactions[hash]?.summary,
                  },
                },
                hash
              )
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [chainId, PROVIDERS_BY_CHAIN[chainId], dispatch, addPopup])

  return null
}
