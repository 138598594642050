import React from 'react'

export const YoutubeIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 1 25 21.5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2751 4.83431C23.6474 5.20907 23.9148 5.67507 24.0505 6.18565C24.5523 8.0702 24.5523 12.0002 24.5523 12.0002C24.5523 12.0002 24.5523 15.9302 24.0505 17.8147C23.9148 18.3253 23.6474 18.7913 23.2751 19.1661C22.9027 19.5409 22.4384 19.8112 21.9287 19.9502C20.0523 20.4547 12.5523 20.4547 12.5523 20.4547C12.5523 20.4547 5.05234 20.4547 3.17597 19.9502C2.66627 19.8112 2.202 19.5409 1.82963 19.1661C1.45725 18.7913 1.18984 18.3253 1.05416 17.8147C0.552338 15.9302 0.552338 12.0002 0.552338 12.0002C0.552338 12.0002 0.552338 8.0702 1.05416 6.18565C1.18984 5.67507 1.45725 5.20907 1.82963 4.83431C2.202 4.45955 2.66627 4.18916 3.17597 4.0502C5.05234 3.54565 12.5523 3.54565 12.5523 3.54565C12.5523 3.54565 20.0523 3.54565 21.9287 4.0502C22.4384 4.18916 22.9027 4.45955 23.2751 4.83431ZM16.3705 12.0002L10.0978 8.43158V15.5689L16.3705 12.0002Z"
        fill="currentColor"
      />
    </svg>
  )
}
