import React from 'react'

export const AcceptIcon: React.FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
      <path d="M5.625 10.625L8.75 13.75L15 7.5" stroke="currentColor" />
    </svg>
  )
}
