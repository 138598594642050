import React from 'react'

export const BriefCaseIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 11.0462C15.1839 11.9837 12.6523 12.4999 10 12.4999C7.34775 12.4999 4.81608 11.9837 2.5 11.0462M13.3333 4.99996V3.33329C13.3333 2.41282 12.5871 1.66663 11.6667 1.66663H8.33333C7.41286 1.66663 6.66667 2.41282 6.66667 3.33329V4.99996M10 9.99996H10.0083M4.16667 16.6666H15.8333C16.7538 16.6666 17.5 15.9204 17.5 15V6.66663C17.5 5.74615 16.7538 4.99996 15.8333 4.99996H4.16667C3.24619 4.99996 2.5 5.74615 2.5 6.66663V15C2.5 15.9204 3.24619 16.6666 4.16667 16.6666Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
