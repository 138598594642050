import { ChainId } from 'sdk'

export const farmTokens = {
  TOMB: {
    symbol: 'TOMB',
    name: 'TOMB',
    decimals: 18,
    address: {
      [ChainId.TOMBCHAINTESTNET]: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
      [ChainId.FANTOM]: '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
      [ChainId.BSC]: '0x8f01d597d2022656494E30fb76eccf1eEA2E092e',
      [ChainId.POLYGON]: '0x0e98C977B943f06075b2D795794238fBfB9b9a34',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/TOMB.svg',
  },
  LIF3: {
    symbol: 'LIF3',
    name: 'LIF3',
    decimals: 18,
    address: {
      [ChainId.TOMBCHAINTESTNET]: '0x0E1E4Ee9B9443D179f5C78eFF2Da3402C9819b6f',
      [ChainId.FANTOM]: '0xbf60e7414ef09026733c1e7de72e7393888c64da',
      [ChainId.BSC]: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
      [ChainId.POLYGON]: '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  },
  newLIF3: {
    symbol: 'LIF3',
    name: 'LIF3',
    decimals: 18,
    address: {
      [ChainId.POLYGON]: '0x110B25D2B21eE73eB401F3aE7833f7072912A0Bf',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
  },
  USDC: {
    symbol: 'USDC',
    name: 'USDC',
    decimals: 6,
    address: {
      [ChainId.TOMBCHAINTESTNET]: '0x4200000000000000000000000000000000000100',
      [ChainId.FANTOM]: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      [ChainId.BSC]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      [ChainId.POLYGON]: '',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/USDC.svg',
  },
  TSHARE: {
    symbol: 'TSHARE',
    name: 'TSHARE',
    decimals: 18,
    address: {
      [ChainId.TOMBCHAINTESTNET]: '0x4200000000000000000000000000000000000101',
      [ChainId.TOMB]: '0x4200000000000000000000000000000000000101',
      [ChainId.FANTOM]: '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37',
      [ChainId.BSC]: '0x5248f6c6196EB9DF6fa4870c66f97638F33D13B0',
      [ChainId.POLYGON]: '0x2D1c6a7362E75d88B4Cf3b66b83052775846E2b8',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/TSHARE.svg',
  },
  BTC: {
    symbol: 'BTC',
    name: 'BTC',
    decimals: 8,
    address: {
      [ChainId.FANTOM]: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      [ChainId.BSC]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      [ChainId.POLYGON]: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
  },
  LSHARE: {
    symbol: 'LSHARE',
    name: 'LSHARE',
    decimals: 18,
    address: {
      [ChainId.TOMBCHAINTESTNET]: '0x4200000000000000000000000000000000000109',
      [ChainId.TOMB]: '0x4200000000000000000000000000000000000109',
      [ChainId.FANTOM]: '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45',
      [ChainId.BSC]: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
      [ChainId.POLYGON]: '0xFB40b1eFe90D4b786D2D9d9dc799B18BDe92923b',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
  },
  newLSHARE: {
    symbol: 'LSHARE',
    name: 'LSHARE',
    decimals: 18,
    address: {
      [ChainId.POLYGON]: '0x5eAB32Fe1D104cE0C5436feDC3433B646096e47C',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
  },
  TREEB: {
    symbol: 'TREEB',
    name: 'TREEB',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0xc60d7067dfbc6f2caf30523a064f416a5af52963',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/TREEB.svg',
  },
  ZOO: {
    symbol: 'ZOO',
    name: 'ZOO',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/ZOO.svg',
  },
  ETH: {
    symbol: 'ETH',
    name: 'ETH',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/ETH.svg',
  },
  FUSDT: {
    symbol: 'FUSDT',
    name: 'FUSDT',
    decimals: 6,
    address: {
      [ChainId.FANTOM]: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/FUSDT.svg',
  },
  MIM: {
    symbol: 'MIM',
    name: 'MIM',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x82f0b8b456c1a451378467398982d4834b6829c1',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/MIM.svg',
  },
  FUSD: {
    symbol: 'FUSD',
    name: 'FUSD',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0xad84341756bf337f5a0164515b1f6f993d194e1f',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/FUSD.svg',
  },
  FTM: {
    symbol: 'FTM',
    name: 'FTM',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/WFTM.svg',
  },
  MATIC: {
    symbol: 'MATIC',
    name: 'MATIC',
    decimals: 18,
    address: {
      [ChainId.POLYGON]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/MATIC.svg',
  },
  DAI: {
    symbol: 'DAI',
    name: 'DAI',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/DAI.svg',
  },
  BNB: {
    symbol: 'BNB',
    name: 'BNB',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454',
      [ChainId.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/BNB.svg',
  },
  AVAX: {
    symbol: 'AVAX',
    name: 'AVAX',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/AVAX.svg',
  },
  LINK: {
    symbol: 'LINK',
    name: 'LINK',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/LINK.svg',
  },
  CRV: {
    symbol: 'CRV',
    name: 'CRV',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/CRV.svg',
  },
  TBOND: {
    symbol: 'TBOND',
    name: 'TBOND',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x24248cd1747348bdc971a5395f4b3cd7fee94ea0',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/TBOND.svg',
  },
  L3USD: {
    symbol: 'L3USD',
    name: 'L3USD',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/L3USD.svg',
  },
  MAI: {
    symbol: 'MAI',
    name: 'MAI',
    decimals: 18,
    address: {
      [ChainId.FANTOM]: '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
    },
    logoURI: 'https://assets.lif3.com/swap/tokens/MAI.svg',
  },
}
