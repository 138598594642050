import React from 'react'

export const ConnectIcon: React.FC = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83337 2C2.7288 2 1.83337 2.89543 1.83337 4V6.66667H3.83337C3.09699 6.66667 2.50004 6.06971 2.50004 5.33333V4C2.50004 3.26362 3.09699 2.66667 3.83337 2.66667H11.8334C12.5698 2.66667 13.1667 3.26362 13.1667 4V5.33333C13.1667 6.06971 12.5698 6.66667 11.8334 6.66667H13.8334V4C13.8334 2.89543 12.9379 2 11.8334 2H3.83337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1667 4H1.83337V12C1.83337 13.1046 2.7288 14 3.83337 14H13.1667C14.2713 14 15.1667 13.1046 15.1667 12V6C15.1667 4.89543 14.2713 4 13.1667 4ZM11.9188 9.64898C12.5797 9.64898 13.1154 9.12827 13.1154 8.48595C13.1154 7.84362 12.5797 7.32292 11.9188 7.32292C11.258 7.32292 10.7223 7.84362 10.7223 8.48595C10.7223 9.12827 11.258 9.64898 11.9188 9.64898Z"
        fill="currentColor"
      />
    </svg>
  )
}
