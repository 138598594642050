import React from 'react'
import { useHistory } from 'react-router-dom'
import { Token } from 'sdk'
import { Text } from 'rebass'
import { formatToUSD } from 'utils/formatters'
import { slidesInView, slideHeight, timePerSlideInSec } from './constants'
import {
  TethysPriceWrapper,
  TokenLogoWrapper,
  TokenLogo,
  PricesCarousel,
  PricesCarouselTrack,
  PricesSlide,
  CSSVariables,
} from './styles'

export type TokentsCarouselType = Array<
  {
    address: string
    totalLiquidityUSD: number
    txCount: number
    USDPrice: number
    totalTradeVolumeUSD: number
    CSPrice: number
  } & Token
>

type Props = {
  tokens?: TokentsCarouselType
}

export const TokensCarousel = ({ tokens }: Props) => {
  const history = useHistory()
  if (!tokens) {
    return null
  }

  const noOfSlides = tokens.length + 1
  const iterationTime = `${Math.floor(noOfSlides * timePerSlideInSec)}s`
  const firstToken = tokens[0]
  const getDecimalPrecision = (symbol: string | undefined) => {
    return symbol === 'LIF3' ? 4 : 2
  }

  const getUrl = (tokenName) => {
    if (tokenName === 'LIF3') {
      return '/tokens/token?chainId=250&outputCurrency=0xbf60e7414EF09026733c1E7de72E7393888C64DA&inputCurrency=FTM'
    } else if (tokenName === 'LSHARE') {
      return '/tokens/token?chainId=250&outputCurrency=0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45&inputCurrency=FTM'
    } else {
      return '/tokens'
    }
  }
  const handleClick = (tokenName) => {
    const url = getUrl(tokenName)
    history.push(url)
  }

  return (
    <CSSVariables
      noOfSlides={noOfSlides}
      slidesInView={slidesInView}
      slideHeight={slideHeight}
      iterationTime={iterationTime}
    >
      <PricesCarousel>
        <PricesCarouselTrack>
          {tokens.map((token) => (
            <PricesSlide key={token?.address} onClick={() => handleClick(token.name)}>
              <TethysPriceWrapper>
                <TokenLogoWrapper>
                  <TokenLogo src={token?.logo} />
                </TokenLogoWrapper>
                <Text ml="8px" fontSize="14px" fontWeight={500}>
                  {formatToUSD(token?.CSPrice ?? 0, getDecimalPrecision(token?.symbol))}
                </Text>
              </TethysPriceWrapper>
            </PricesSlide>
          ))}

          {/* repeat first slide to fake infinite */}

          <PricesSlide>
            <TethysPriceWrapper>
              <TokenLogoWrapper>
                <TokenLogo src={firstToken?.logo} />
              </TokenLogoWrapper>
              <Text ml="8px" fontSize="14px" fontWeight={500}>
                {formatToUSD(firstToken?.CSPrice ?? 0, getDecimalPrecision(firstToken?.symbol))}
              </Text>
            </TethysPriceWrapper>
          </PricesSlide>
        </PricesCarouselTrack>
      </PricesCarousel>
    </CSSVariables>
  )
}
