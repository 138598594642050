import React from 'react'

export const MoonIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
    >
      <path
        d="M20.92 13.16a9.0006 9.0006 0 01-6.023 7.3647 8.9994 8.9994 0 01-9.2666-2.1551A8.9996 8.9996 0 013.4753 9.103 9 9 0 0110.84 3.08 7.3 7.3 0 0010 6.5a7.5 7.5 0 007.5 7.5 7.3 7.3 0 003.42-.84z"
        strokeMiterlimit="10"
        strokeLinecap="round"
      ></path>
    </svg>
  )
}
