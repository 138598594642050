// TODO: could be a hook
import { keccak256 } from '@ethersproject/keccak256'
import { pack } from '@ethersproject/solidity'
import { IMX_ADDRESSES } from 'pages/Lending/config/web3/contracts/imxes'
import { W_ETH_ADDRESSES } from 'pages/Lending/config/web3/contracts/w-eths'
import { getCreate2Address } from '@ethersproject/address'

import { Address } from '../../../types/interfaces'
import { ChainId, INIT_CODE_HASH } from 'sdk'

const getPairAddress = (
  chainId: ChainId,
  tokenA: Address,
  tokenB: Address,
  uniswapV2FactoryAddress: Address
): string => {
  const imxAddress = IMX_ADDRESSES[chainId]
  const wETHAddress = W_ETH_ADDRESSES[chainId]

  const [token0, token1] = tokenA < tokenB ? [tokenA, tokenB] : [tokenB, tokenA]
  const salt = keccak256(pack(['address', 'address'], [token0, token1]))

  // (main token - gas token) pool vault token address
  const vaultToken = {
    [ChainId.TOMBCHAINTESTNET]: '0xE83D3Db4F62dd97Bc9c058afb8e7C049EE2093d5',
    [ChainId.TOMB]: '',
    [ChainId.FANTOM]: '0x06fb168ee5ec6ff9e60e6ee242087724cf9c3ae8',
    [ChainId.BSC]: '0x845a33a1e0173ed667a53e30b1c3c2fbdc92258d',
    [ChainId.POLYGON]: '0x586604208280a513925ff6cee029e69ded71fa56',
  }

  if (token0 === imxAddress && token1 === wETHAddress) {
    return vaultToken[chainId]
  }

  return getCreate2Address(uniswapV2FactoryAddress, salt, INIT_CODE_HASH[chainId])
}

export default getPairAddress
