import { TokenAmount, Pair, Currency, FACTORY_ADDRESS, Token } from 'sdk'
import { useMemo } from 'react'
import { usePairsReservesQuery } from 'hooks/queries/usePairsReservesQuery'

import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useQuery } from 'react-query'
import { BigNumber } from 'ethers'
import FACTORY_ABI from 'constants/sushiAbis/factory.json'
import { ZERO_ADDRESS } from '../constants'
import { getContract } from 'utils'
import { UniswapV2PairAbi } from 'shared/abi/UniswapV2PairAbi'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { useChainId } from 'hooks'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export const useReservesTokensMap = (currencies: [Currency, Currency][]) => {
  const chainId = useChainId()
  const tokens = useMemo(
    () =>
      currencies
        .map(([currencyA, currencyB]) => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)])
        .reduce<Record<string, { tokenA: Token; tokenB: Token }>>((acc, [tokenA, tokenB]) => {
          const address =
            tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB, chainId) : undefined

          if (address && tokenA && tokenB) {
            acc[address] = { tokenA, tokenB }
          }

          return acc
        }, {}),
    [currencies, chainId]
  )

  return tokens
}

export function usePairs(currencies: [Currency, Currency][]): [PairState, Pair | null][] {
  const tokens = useReservesTokensMap(currencies)
  const chainId = useChainId()

  const pairsReservesQuery = usePairsReservesQuery(tokens)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const results = pairsReservesQuery.data ?? []
  if (pairsReservesQuery.isIdle) return [[PairState.NOT_EXISTS, null]]

  if (pairsReservesQuery.isLoading) return [[PairState.LOADING, null]]

  if (pairsReservesQuery.isError) {
    return [[PairState.INVALID, null]]
  }

  const allTotalSupplyByPair = results.reduce<Record<string, number>>((acc, result) => {
    if (result?.pairAddress && result?.totalSupply) {
      acc[result.pairAddress] = result.totalSupply.toString()
    }

    return acc
  }, {})

  return results.map((result, i) => {
    if (!result) return [PairState.LOADING, null]

    const resultPair = tokens[result.pairAddress]
    const tokenA = resultPair.tokenA
    const tokenB = resultPair.tokenB

    const { reserve0, reserve1 } = result

    if (pairsReservesQuery.isLoading) return [PairState.LOADING, null]
    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
    if (!reserve0 || !reserve1) return [PairState.NOT_EXISTS, null]

    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

    return [
      PairState.EXISTS,
      new Pair(
        new TokenAmount(token0, reserve0.toString()),
        new TokenAmount(token1, reserve1.toString()),
        allTotalSupplyByPair,
        chainId
      ),
    ]
  })
}

export function usePair(
  tokenA: Currency | undefined | null,
  tokenB: Currency | undefined | null
): [PairState, Pair | null] {
  const chainId = useChainId()
  const wrappedTokenA = wrappedCurrency(tokenA, chainId)
  const wrappedTokenB = wrappedCurrency(tokenB, chainId)

  const pairAddress =
    wrappedTokenA && wrappedTokenB && !wrappedTokenA.equals(wrappedTokenB)
      ? Pair.getAddress(wrappedTokenA, wrappedTokenB, chainId)
      : undefined

  const factoryContract = getContract(FACTORY_ADDRESS[chainId], FACTORY_ABI, PROVIDERS_BY_CHAIN[chainId])

  const pairReservesQuery = useQuery(
    ['pair-reserves', pairAddress, chainId],
    async () => {
      // @ts-ignore
      const pairContract = getContract(pairAddress, UniswapV2PairAbi, PROVIDERS_BY_CHAIN[chainId])

      const res = await factoryContract?.getPair(wrappedTokenA?.address, wrappedTokenB?.address)

      if (res === ZERO_ADDRESS) {
        return { reserve0: undefined, reserve1: undefined }
      }

      const reserve: { reserve1: BigNumber; reserve0: BigNumber } = await pairContract.getReserves()

      return { ...reserve }
    },
    { enabled: Boolean(pairAddress) }
  )

  if (pairReservesQuery.isLoading) return [PairState.LOADING, null]
  if (!wrappedTokenA || !wrappedTokenB || wrappedTokenA.equals(wrappedTokenB)) return [PairState.INVALID, null]
  if (!pairReservesQuery.data?.reserve0 || !pairReservesQuery.data?.reserve1) return [PairState.NOT_EXISTS, null]

  const [token0, token1] = wrappedTokenA.sortsBefore(wrappedTokenB)
    ? [wrappedTokenA, wrappedTokenB]
    : [wrappedTokenB, wrappedTokenA]

  return [
    PairState.EXISTS,
    new Pair(
      new TokenAmount(token0, pairReservesQuery.data.reserve0.toString()),
      new TokenAmount(token1, pairReservesQuery.data.reserve1.toString()),
      {},
      chainId
    ),
  ]
}

/*export const usePreloadReserves = (
  currencyA?: Currency | null | undefined,
  currencyB?: Currency | null | undefined
) => {
  const allPairCombinations = useAllPairCombinations(currencyA, currencyB)
  const tokens = useReservesTokensMap(allPairCombinations)

  usePairsReservesQuery(tokens)
}*/
