import { useAccountTotalValueLockedQuery } from 'pages/Lending/hooks/useAccountData'
import Big from 'big.js'
import { useWalletTokens } from 'pages/Portfolio/PortfolioWallet/TokenList/hooks/useWalletTokens'
import { usePortfolioTokens } from 'pages/Portfolio/PortfolioWallet/hooks/usePortfolioTokens'

export const useTotalNetworth = () => {
  const blacklist = []

  const portfolioTokens = usePortfolioTokens()
  const walletTokensInfo = useWalletTokens()
  const totalValueLockedQuery = useAccountTotalValueLockedQuery()

  const liquidityTokens = portfolioTokens.liquidityTokens
  const farmsLiquidityTokens = portfolioTokens.farmTokens
  const totalLiquidityTokensValue = liquidityTokens
    ? liquidityTokens.reduce((acc, info) => {
        return acc.plus(info.valueUSD)
      }, Big(0))
    : Big(0)
  const totalFarmsLiquidityTokensValue = farmsLiquidityTokens
    ? farmsLiquidityTokens.reduce((acc, info) => {
        const stakedUSD = Big(info.stakedUSD)
        const earnedUSD = Big(info.earnedBalance).mul(info.rewardTokenPrice)

        return acc.plus(earnedUSD.plus(stakedUSD))
      }, Big(0))
    : Big(0)

  const walletTokensInfoData = walletTokensInfo.data
    ? walletTokensInfo.data.filter((token) => !blacklist.find((item) => item === token.address))
    : []
  const totalTokensValueUSD = walletTokensInfoData.reduce((acc, info) => {
    return acc.plus(info.userUsdBalance)
  }, Big(0))

  const totalValueLockedData = Big(totalValueLockedQuery.data ?? 0)

  const totalNetworth = totalTokensValueUSD
    .plus(totalValueLockedData)
    .plus(totalLiquidityTokensValue)
    .plus(totalFarmsLiquidityTokensValue)

  return {
    totalNetworth,
    totalTokensValueUSD,
    totalValueLockedData,
    totalLiquidityTokensValue,
    totalFarmsLiquidityTokensValue,
    farmsLiquidityTokens,
    liquidityTokens,
    walletTokensInfoData,
    portfolioTokensIsLoading: portfolioTokens.isLoading,
    walletTokensInfoIsLoading: walletTokensInfo.isLoading,
  }
}
