import { ChainId } from 'sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { ExternalLink, theme } from '../../theme'
import { Text } from 'rebass'
import { CloseIcon, CustomLightSpinner } from '../../theme/components'
import { RowBetween } from '../Row'
import { AlertTriangle, ArrowUpCircle } from 'react-feather'
import { ButtonPrimary, ButtonPrimaryWhite } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'

import { getExplorerLink } from '../../utils'
import { useActiveWeb3React, useChainId } from '../../hooks'
import { Box } from 'shared'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'
import useTheme from 'hooks/useTheme'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 20px;
`

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  const theme = useTheme()

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <Box mb="30px" display="flex" justifyContent="center">
          <Box width="36px" height="36px">
            <LoadingDotsIcon size={36} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Text fontWeight={500} fontSize={14}>
            Waiting For Confirmation
          </Text>
          <Box mt="8px">
            <Text fontWeight={500} fontSize={14} textAlign="center">
              {pendingText}
            </Text>
          </Box>
          <Box mt="24px">
            <Text fontSize={12} color={theme.primaryText1} textAlign="center">
              Confirm this transaction in your wallet
            </Text>
          </Box>
        </Box>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  hash,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
}) {
  const chainId = useChainId()
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Text fontWeight={500} fontSize={20}>
            Transaction Submitted
          </Text>
          {hash && (
            <Box mt="20px">
              <ExternalLink href={getExplorerLink(hash, 'transaction', chainId)}>
                <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                  View on explorer
                </Text>
              </ExternalLink>
            </Box>
          )}
          <Box width="100%" mt="30px">
            <ButtonPrimaryWhite padding="16px 8px" onClick={onDismiss}>
              Close
            </ButtonPrimaryWhite>
          </Box>
        </Box>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
}: {
  title: string
  onDismiss: () => void
  topContent?: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent && topContent()}
      </Section>
      <BottomSection gap="12px">{bottomContent()}</BottomSection>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            Error
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <Box mt="12px" display="flex" flexDirection="column" alignItems="center">
          <Text textAlign="center" fontWeight={500} fontSize={14} color={theme.red1}>
            {message}
          </Text>

          <ButtonPrimaryWhite onClick={onDismiss}>
            <Text>Dismiss</Text>
          </ButtonPrimaryWhite>
        </Box>
      </Section>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onRequestClose={onDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
      ) : (
        content()
      )}
    </Modal>
  )
}
