import styled from 'styled-components'
import { Text } from 'rebass'
import { PositionsListGrid } from '../styles'

export const LinkRow = styled.span`
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.primaryText1};
  padding: 16px;
  text-decoration: none;
  font-weight: 535;

  & > div:not(:first-child) {
    text-align: center;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 8px;
  `};
`

export const PositionsListItemBox = styled(PositionsListGrid)<{
  showSeparator: boolean
}>`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  padding: 20px 0;

  border-bottom: ${({ theme, showSeparator }) => (showSeparator ? `1px solid ${theme.border1}` : null)};

  & > *:nth-child(4),
  & > *:nth-child(5),
  & > *:nth-child(6),
  & > *:nth-child(7) {
    text-align: center;
    justify-content: center;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    gap: 5px;

    & > *:nth-child(4),
    & > *:nth-child(5),
    & > *:nth-child(6),
    & > *:nth-child(7) {
    text-align: left;
    justify-content: flex-start;
  }
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.componentBg5};
    height: auto;
    padding: 20px;
    border-radius: 10px;
    gap: 20px;
  `}
`

export const DataLineItem = styled.div`
  font-size: 14px;
`

export const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  width: 100%;
`

export const DoubleArrow = styled.span`
  font-size: 14px;
  line-height: 16px;
  margin: 0 2px;
  color: ${({ theme }) => theme.primaryText1};
`

export const RangeText = styled(Text)`
  font-size: 14px !important;
  word-break: break-word;
  padding: 0.25rem 0.25rem;
  border-radius: 8px;
`

export const FeeTierText = styled(Text)`
  font-size: 13px !important;
  margin-left: 8px !important;
  color: ${({ theme }) => theme.accentText};
`

export const ExtentsText = styled(Text)`
  color: ${({ theme }) => theme.accentText};
  display: inline-block;
  font-size: 13px;
  margin-right: 4px !important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IncentivisedTab = styled.span`
  margin-left: 10px;
  padding: 3px 8px;
  border-radius: 10px;
  background-color: #0d929580;
  font-size: 13px;
  line-height: 17px;
  color: #fff;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding: 0px 8px;
  `};
`

export const PositionRewardTextPrimary = styled.span`
  font-feature-settings: 'clig' off, 'liga';
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const PositionRewardTextSecondary = styled.span`
  font-feature-settings: 'clig' off, 'liga';
  font-size: 16px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
`
