import { createReducer } from '@reduxjs/toolkit'
import { CurrencyDirection } from 'enums/common'
import {
  replaceSwapState,
  selectCurrency,
  setRecipient,
  setSelectedSwapType,
  switchCurrencies,
  typeInput,
  typeInputLimit,
} from './actions'

export type SwapType = 'Market' | 'Limit' | 'Aggregator' | 'CrossChain' | 'V3' | null

export interface SwapState {
  readonly independentField: CurrencyDirection
  readonly typedValue: string
  readonly [CurrencyDirection.INPUT]: {
    readonly currencyId: string | undefined
  }
  readonly [CurrencyDirection.OUTPUT]: {
    readonly currencyId: string | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null
  readonly limitValue: string | null
  readonly selectedSwapType: SwapType
}

const initialState: SwapState = {
  independentField: CurrencyDirection.INPUT,
  typedValue: '',
  [CurrencyDirection.INPUT]: {
    currencyId: '',
  },
  [CurrencyDirection.OUTPUT]: {
    currencyId: '',
  },
  recipient: null,
  limitValue: null,
  selectedSwapType: 'Market',
}

export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(
      replaceSwapState,
      (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
        return {
          ...state,
          [CurrencyDirection.INPUT]: {
            currencyId: inputCurrencyId,
          },
          [CurrencyDirection.OUTPUT]: {
            currencyId: outputCurrencyId,
          },
          independentField: field,
          typedValue: typedValue,
          recipient,
        }
      }
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === CurrencyDirection.INPUT ? CurrencyDirection.OUTPUT : CurrencyDirection.INPUT
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField:
            state.independentField === CurrencyDirection.INPUT ? CurrencyDirection.OUTPUT : CurrencyDirection.INPUT,
          [field]: { currencyId: currencyId },
          [otherField]: { currencyId: state[field].currencyId },
        }
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId },
        }
      }
    })
    .addCase(switchCurrencies, (state) => {
      return {
        ...state,
        independentField:
          state.independentField === CurrencyDirection.INPUT ? CurrencyDirection.OUTPUT : CurrencyDirection.INPUT,
        [CurrencyDirection.INPUT]: { currencyId: state[CurrencyDirection.OUTPUT].currencyId },
        [CurrencyDirection.OUTPUT]: { currencyId: state[CurrencyDirection.INPUT].currencyId },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
    .addCase(typeInputLimit, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        limitValue: typedValue,
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
    .addCase(setSelectedSwapType, (state, { payload: { type } }) => {
      return {
        ...state,
        selectedSwapType: type,
      }
    })
)
