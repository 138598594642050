import { useChainId } from 'hooks'
import { LendingPoolData } from 'pages/Lending/types/interfaces'
import { useQuery } from 'react-query'

import initialLendingPoolFetcher, {
  INITIAL_LENDING_POOL_FETCHER,
} from '../../services/fetchers/initial-lending-pool-fetcher'
import uniswapAPYsFetcher, { UNISWAP_APYS_FETCHER } from '../../services/fetchers/uniswap-apys-fetcher'

const useLendingPool = (
  uniswapV2PairAddress: string
): {
  isLoading: boolean
  data: LendingPoolData | undefined
  error: Error | null
} => {
  const chainId = useChainId()
  const lowerCasedUniswapV2PairAddress = uniswapV2PairAddress.toLowerCase()
  const {
    isLoading: initialLendingPoolLoading,
    data: initialLendingPool,
    error: initialLendingPoolError,
  } = useQuery<LendingPoolData, Error>(
    [INITIAL_LENDING_POOL_FETCHER, lowerCasedUniswapV2PairAddress, chainId],
    async (data) => await initialLendingPoolFetcher(data, chainId),
    {
      enabled: !!lowerCasedUniswapV2PairAddress,
    }
  )

  let lendingPool: LendingPoolData | undefined

  if (initialLendingPool) {
    lendingPool = {
      ...initialLendingPool,
      pair: {
        ...initialLendingPool.pair,
      },
    }
  } else {
    lendingPool = undefined
  }

  return {
    isLoading: initialLendingPoolLoading,
    data: lendingPool,
    error: initialLendingPoolError,
  }
}

export default useLendingPool
