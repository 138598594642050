import { createAction } from '@reduxjs/toolkit'
import { CurrencyDirection } from 'enums/common'
import { SwapType } from './reducer'

export const selectCurrency = createAction<{ field: CurrencyDirection; currencyId: string }>('swap/selectCurrency')
export const switchCurrencies = createAction<void>('swap/switchCurrencies')
export const typeInput = createAction<{ field: CurrencyDirection; typedValue: string }>('swap/typeInput')
export const typeInputLimit = createAction<{ typedValue: string }>('swap/typeInputLimit')
export const replaceSwapState = createAction<{
  field: CurrencyDirection
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('swap/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient')
export const setSelectedSwapType = createAction<{ type: SwapType | null }>('swap/setSelectedSwapType')
